// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/services';

@Injectable()
export class MenuConfigService {
  onConfigUpdated$: Subject<any>;
  private menuConfig: any;

  /**
   * Service Constructor
   */
  constructor(private authService: AuthService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    const user = this.authService.currentUserValue;
    if (user) {
      const filteredItems = this.menuConfig.aside.items.filter((x: any) =>
        x.roles.includes(user?.role?.toLowerCase())
      );

      filteredItems.forEach((element) => {
        if (element.submenu) {
          element.submenu = element.submenu.filter((x: any) => {
            return x.roles ? x.roles.includes(user?.role?.toLowerCase()) : true;
          });
        }
      });

      this.menuConfig.aside.items = filteredItems;
    } else {
      this.menuConfig = null;
    }

    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.menuConfig = config;
    this.onConfigUpdated$.next(this.menuConfig);
    this.getMenus();
  }
}
