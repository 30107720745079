// tslint:disable: no-output-on-prefix
import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  ContentChildren,
  QueryList,
  TemplateRef,
  NgZone,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from '@angular/core';

import { NisloTemplateDirective } from '../directives/template.directive';
import { GridService } from './grid.service';
import { SortMeta } from './sort-meta';
import { FilterMetadata } from './filtermetadata';

@Component({
  selector: 'nr-grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.scss'],
  providers: [GridService],
})
export class NisloGridComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  private internalSortField!: string;

  private internalSortOrder = 1;

  private internalSelection: any;

  private internalTotalRecords = 0;

  // editMode: string;

  public innerWidth: any;
  public innerHeight: any;

  @Input() style: any;

  @Input()
  styleClass!: string;

  @Input() tableStyle: any;

  @Input()
  tableStyleClass!: string;

  @Input()
  paginator!: boolean;

  @Input() pageLinks = 5;

  @Input() rowsPerPageOptions: any[] = [15, 30, 50, 100];

  @Input() alwaysShowPaginator = true;

  @Input() paginatorDropdownAppendTo: any;

  @Input()
  showCurrentPageReport!: boolean;

  @Input() defaultSortOrder = 1;

  @Input() resetPageOnSort = true;

  @Input()
  selectionMode!: string;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @Input()
  dataKey!: string;

  @Input()
  metaKeySelection!: boolean;

  @Input()
  rowTrackBy!: (index: number, item: any) => any;

  @Input() lazy = false;

  @Input() lazyLoadOnInit = true;

  @Input() csvSeparator = ',';

  @Input() exportFilename = 'download';

  @Input() filters: { [s: string]: FilterMetadata } = {};

  @Input() filterDelay = 300;

  @Input()
  scrollable!: boolean;

  @Input()
  scrollHeight!: string;

  @Input()
  virtualScroll!: boolean;

  @Input() virtualScrollDelay = 150;

  @Input() virtualRowHeight = 28;

  @Input()
  responsive!: boolean;

  @Input() columnResizeMode = 'fit';

  @Input()
  sortableColumns!: boolean;

  @Input()
  loading!: boolean;

  @Input() loadingIcon = 'fa fa-spinner';

  @Input() showLoader = true;

  @Input()
  rowHover!: boolean;

  @Input()
  customSort!: boolean;

  @Input()
  autoLayout!: boolean;

  @Input() exportFunction: any;

  @Output() onRowSelect: EventEmitter<any> = new EventEmitter();

  @Output() onRowAuxClick: EventEmitter<any> = new EventEmitter();

  @Output() onRowUnselect: EventEmitter<any> = new EventEmitter();

  @Output() onPage: EventEmitter<any> = new EventEmitter();

  @Output() onSort: EventEmitter<any> = new EventEmitter();

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

  @Output() onHeaderCheckboxToggle: EventEmitter<any> = new EventEmitter();

  @Output() sortFunction: EventEmitter<any> = new EventEmitter();

  @Output() firstChange: EventEmitter<number> = new EventEmitter();

  @Output() rowsChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('container', { static: true })
  containerViewChild!: ElementRef;

  @ViewChild('table', { static: true })
  tableViewChild!: ElementRef;

  @ContentChildren(NisloTemplateDirective)
  templates!: QueryList<NisloTemplateDirective>;

  @Input() value: any[] = [];

  @Input()
  columns!: any[];

  @Input() first = 0;

  @Input()
  rows!: number;

  filteredValue: any[] | null;

  headerTemplate!: TemplateRef<any>;

  bodyTemplate!: TemplateRef<any>;

  loadingBodyTemplate!: TemplateRef<any>;

  captionTemplate!: TemplateRef<any>;

  footerTemplate!: TemplateRef<any>;

  summaryTemplate!: TemplateRef<any>;

  colGroupTemplate!: TemplateRef<any>;

  emptyMessageTemplate!: TemplateRef<any>;

  paginatorLeftTemplate!: TemplateRef<any>;

  paginatorRightTemplate!: TemplateRef<any>;

  selectionKeys: any = {};

  reorderIconWidth!: number;

  reorderIconHeight!: number;

  documentEditListener: any;

  virtualScrollTimer: any;

  virtualScrollCallback!: () => void;

  preventSelectionSetterPropagation!: boolean;

  // tslint:disable-next-line: variable-name
  _selection: any;

  anchorRowIndex: number | null;

  rangeRowIndex!: number;

  filterTimeout: any;

  initialized!: boolean;

  rowTouched!: boolean;

  restoringSort!: boolean;

  restoringFilter!: boolean;

  columnOrderStateRestored!: boolean;

  columnWidthsState!: string;

  tableWidthState!: string;

  tableHeight: any;

  @Input() get selection(): any {
    return this._selection;
  }

  set selection(val: any) {
    this._selection = val;

    if (!this.preventSelectionSetterPropagation) {
      this.updateSelectionKeys();
      this.gridService.onSelectionChange();
    }

    this.preventSelectionSetterPropagation = false;
  }

  @Input()
  get sortField(): string {
    return this.internalSortField;
  }
  set sortField(val: string) {
    this.internalSortField = val;

    if (!this.lazy || this.initialized) {
      this.sortSingle();
    }
  }

  @Input()
  get sortOrder(): number {
    return this.internalSortOrder;
  }
  set sortOrder(val: number) {
    this.internalSortOrder = val;

    if (!this.lazy || this.initialized) {
      this.sortSingle();
    }
  }

  @Input()
  get totalRecords(): number {
    return this.internalTotalRecords;
  }
  set totalRecords(val: number) {
    this.internalTotalRecords = val;
    this.gridService.onTotalRecordsChange(this.internalTotalRecords);
  }

  constructor(
    public el: ElementRef,
    public zone: NgZone,
    public gridService: GridService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initialized = true;

    /*
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.tableHeight = this.innerHeight - 380;
*/
  }
  /*
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    this.tableHeight = this.innerHeight - 380;
    //console.log(this.innerWidth +' | '+ this.innerHeight)
  }
*/

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'caption':
          this.captionTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'body':
          this.bodyTemplate = item.template;
          break;

        case 'loadingbody':
          this.loadingBodyTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        case 'summary':
          this.summaryTemplate = item.template;
          break;

        case 'colgroup':
          this.colGroupTemplate = item.template;
          break;

        case 'emptymessage':
          this.emptyMessageTemplate = item.template;
          break;

        case 'paginatorleft':
          this.paginatorLeftTemplate = item.template;
          break;

        case 'paginatorright':
          this.paginatorRightTemplate = item.template;
          break;
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.initialized = false;
  }

  createLazyLoadMetadata(): any {
    return {
      first: this.first,
      rows: this.virtualScroll ? this.rows * 2 : this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
    };
  }

  isEmpty() {
    const data = this.filteredValue || this.value;
    return data == null || data.length === 0;
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;

    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    }

    this.onPage.emit({
      first: this.first,
      rows: this.rows,
    });

    this.firstChange.emit(this.first);
    this.rowsChange.emit(this.rows);
    this.gridService.onValueChange(this.value);

    // this.anchorRowIndex = null;
  }

  sort(event: any) {
    this.internalSortOrder =
      this.sortField === event.field
        ? this.sortOrder * -1
        : this.defaultSortOrder;
    this.internalSortField = event.field;
    this.sortSingle();

    this.anchorRowIndex = null;
  }

  sortSingle() {
    if (this.sortField && this.sortOrder) {
      if (this.restoringSort) {
        this.restoringSort = false;
      } else if (this.resetPageOnSort) {
        this.first = 0;
        this.firstChange.emit(this.first);
      }

      if (this.lazy) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      } else if (this.value) {
        if (this.customSort) {
          this.sortFunction.emit({
            data: this.value,
            field: this.sortField,
            order: this.sortOrder,
          });
        } else {
          console.warn('Only server-side pagination is allowed');
        }

        if (this.hasFilter()) {
          this._filter();
        }
      }

      const sortMeta: SortMeta = {
        field: this.sortField,
        order: this.sortOrder,
      };

      this.onSort.emit(sortMeta);
      this.gridService.onSort(sortMeta);
    }
  }

  isSorted(field: string): boolean {
    return !!this.sortField && this.sortField === field;
  }

  updateSelectionKeys() {
    if (this.dataKey && this._selection) {
      this.selectionKeys = {};
      if (Array.isArray(this._selection)) {
        for (const data of this._selection) {
          this.selectionKeys[
            String(this.resolveFieldData(data, this.dataKey))
          ] = 1;
        }
      } else {
        this.selectionKeys[
          String(this.resolveFieldData(this._selection, this.dataKey))
        ] = 1;
      }
    }
  }

  handleRowClick(event: any) {
    const target = event.originalEvent.target as HTMLElement;
    const targetNode = target.nodeName;
    const parentNode = target.parentElement && target.parentElement.nodeName;
    if (
      targetNode === 'INPUT' ||
      targetNode === 'BUTTON' ||
      targetNode === 'A' ||
      parentNode === 'INPUT' ||
      parentNode === 'BUTTON' ||
      parentNode === 'A' ||
      this.hasClass(event.originalEvent.target, 'clickable')
    ) {
      return;
    }

    if (this.selectionMode) {
      this.preventSelectionSetterPropagation = true;

      const rowData = event.rowData;
      const selected = this.isSelected(rowData);
      const metaSelection = this.rowTouched ? false : this.metaKeySelection;
      const dataKeyValue = this.dataKey
        ? String(this.resolveFieldData(rowData, this.dataKey))
        : null;
      this.anchorRowIndex = event.rowIndex;
      this.rangeRowIndex = event.rowIndex;

      if (metaSelection) {
        const metaKey =
          event.originalEvent.metaKey || event.originalEvent.ctrlKey;

        if (selected && metaKey) {
          this._selection = null;
          this.selectionKeys = {};
          this.selectionChange.emit(null);

          this.onRowUnselect.emit({
            originalEvent: event.originalEvent,
            data: rowData,
            type: 'row',
          });
        } else {
          this._selection = rowData;
          this.selectionChange.emit(rowData);
          if (dataKeyValue) {
            this.selectionKeys = {};
            this.selectionKeys[dataKeyValue] = 1;
          }

          this.onRowSelect.emit({
            originalEvent: event.originalEvent,
            data: rowData,
            type: 'row',
            index: event.rowIndex,
          });
        }
      } else {
        if (selected) {
          this._selection = null;
          this.selectionKeys = {};
          this.selectionChange.emit(this.selection);

          this.onRowUnselect.emit({
            originalEvent: event.originalEvent,
            data: rowData,
            type: 'row',
          });
        } else {
          this._selection = rowData;
          this.selectionChange.emit(this.selection);

          this.onRowSelect.emit({
            originalEvent: event.originalEvent,
            data: rowData,
            type: 'row',
            index: event.rowIndex,
          });

          if (dataKeyValue) {
            this.selectionKeys = {};
            this.selectionKeys[dataKeyValue] = 1;
          }
        }
      }

      this.gridService.onSelectionChange();
    }

    this.rowTouched = false;
  }

  handleRowAuxClick(event: any) {
    if (
      (event.originalEvent.ctrlKey && event.originalEvent.which === 1) ||
      event.originalEvent.which === 2
    ) {
      const target = event.originalEvent.target as HTMLElement;
      const targetNode = target.nodeName;
      const parentNode = target.parentElement && target.parentElement.nodeName;
      if (
        targetNode === 'INPUT' ||
        targetNode === 'BUTTON' ||
        targetNode === 'A' ||
        parentNode === 'INPUT' ||
        parentNode === 'BUTTON' ||
        parentNode === 'A' ||
        this.hasClass(event.originalEvent.target, 'clickable')
      ) {
        return;
      }

      this.preventSelectionSetterPropagation = true;

      const rowData = event.rowData;

      this.anchorRowIndex = event.rowIndex;
      this.rangeRowIndex = event.rowIndex;

      this.onRowAuxClick.emit({
        originalEvent: event.originalEvent,
        data: rowData,
        type: 'row',
        index: event.rowIndex,
      });

      this.rowTouched = false;
    }
  }

  isSelected(rowData: any) {
    if (rowData && this.selection) {
      if (this.dataKey) {
        return (
          this.selectionKeys[this.resolveFieldData(rowData, this.dataKey)] !==
          undefined
        );
      } else {
        if (this.selection instanceof Array) {
          return this.findIndexInSelection(rowData) > -1;
        } else {
          return this.equals(rowData, this.selection);
        }
      }
    }

    return false;
  }

  findIndexInSelection(rowData: any) {
    let index = -1;
    if (this.selection && this.selection.length) {
      for (let i = 0; i < this.selection.length; i++) {
        if (this.equals(rowData, this.selection[i])) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  _filter() {
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      if (!this.value) {
        return;
      }

      if (!this.hasFilter()) {
        this.filteredValue = null;
        if (this.paginator) {
          this.totalRecords = this.value ? this.value.length : 0;
        }
      } else {
        this.filteredValue = [];

        if (this.filteredValue.length === this.value.length) {
          this.filteredValue = null;
        }

        if (this.paginator) {
          this.totalRecords = this.filteredValue
            ? this.filteredValue.length
            : this.value
            ? this.value.length
            : 0;
        }
      }
    }

    this.onFilter.emit({
      filters: this.filters,
      filteredValue: this.filteredValue || this.value,
    });

    this.gridService.onValueChange(this.value);

    this.cd.detectChanges();
  }

  hasFilter() {
    let empty = true;
    for (const prop in this.filters) {
      if (this.filters.hasOwnProperty(prop)) {
        empty = false;
        break;
      }
    }

    return !empty;
  }

  private resolveFieldData(data: any, field: any): any {
    if (data && field) {
      if (this.isFunction(field)) {
        return field(data);
      } else if (field.indexOf('.') === -1) {
        return data[field];
      } else {
        const fields: string[] = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value == null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }

  private isFunction(obj: any) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  }

  private hasClass(element: any, className: string): boolean {
    if (element.classList) {
      return element.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(
        element.className
      );
    }
  }

  private equals(obj1: any, obj2: any, field?: string): boolean {
    if (field) {
      return (
        this.resolveFieldData(obj1, field) ===
        this.resolveFieldData(obj2, field)
      );
    } else {
      return this.equalsByValue(obj1, obj2);
    }
  }

  private equalsByValue(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
      const arrA = Array.isArray(obj1);
      const arrB = Array.isArray(obj2);
      let i: number;
      let length: number;
      let key: string;

      if (arrA && arrB) {
        length = obj1.length;
        if (length !== obj2.length) {
          return false;
        }
        for (i = length; i-- !== 0; ) {
          if (!this.equalsByValue(obj1[i], obj2[i])) {
            return false;
          }
        }
        return true;
      }

      if (arrA !== arrB) {
        return false;
      }

      const dateA = obj1 instanceof Date;
      const dateB = obj2 instanceof Date;
      if (dateA !== dateB) {
        return false;
      }
      if (dateA && dateB) {
        return obj1.getTime() === obj2.getTime();
      }

      const regexpA = obj1 instanceof RegExp;
      const regexpB = obj2 instanceof RegExp;
      if (regexpA !== regexpB) {
        return false;
      }
      if (regexpA && regexpB) {
        return obj1.toString() === obj2.toString();
      }

      const keys = Object.keys(obj1);
      length = keys.length;

      if (length !== Object.keys(obj2).length) {
        return false;
      }

      for (i = length; i-- !== 0; ) {
        if (!Object.prototype.hasOwnProperty.call(obj2, keys[i])) {
          return false;
        }
      }

      for (i = length; i-- !== 0; ) {
        key = keys[i];
        if (!this.equalsByValue(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    }

    return obj1 !== obj1 && obj2 !== obj2;
  }
}
