import {
  ElementRef,
  Directive,
  Input,
  TemplateRef,
  EventEmitter,
  Renderer2,
  Injector,
  ComponentFactoryResolver,
  ViewContainerRef,
  NgZone,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  ApplicationRef,
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[stickyPopover]',
  exportAs: 'stickyPopover',
})
export class StickyPopoverDirective
  extends NgbPopover
  implements OnInit, OnDestroy
{
  @Input() stickyPopover: TemplateRef<any>;

  override popoverTitle: string;

  override placement:
    | 'auto'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'left-top'
    | 'left-bottom'
    | 'right-top'
    | 'right-bottom'
    | (
        | 'auto'
        | 'top'
        | 'bottom'
        | 'left'
        | 'right'
        | 'top-left'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-right'
        | 'left-top'
        | 'left-bottom'
        | 'right-top'
        | 'right-bottom'
      )[];

  override triggers: string;
  override container: string;
  ngpPopover: TemplateRef<any>;
  canClosePopover: boolean;

  override toggle(): void {
    super.toggle();
  }

  override isOpen(): boolean {
    return super.isOpen();
  }

  constructor(
    // tslint:disable: variable-name
    private _elRef: ElementRef,
    private _render: Renderer2,
    injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    config: NgbPopoverConfig,
    ngZone: NgZone,
    private changeRef: ChangeDetectorRef,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) _document: any
    // tslint:enable: variable-name
  ) {
    super(
      _elRef,
      _render,
      injector,
      // componentFactoryResolve
      viewContainerRef,
      config,
      ngZone,
      _document,
      changeRef,
      applicationRef
    );
    this.triggers = 'manual';
    this.popoverTitle = '';
    this.container = 'body';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.ngbPopover = this.stickyPopover;

    this._render.listen(this._elRef.nativeElement, 'mouseenter', () => {
      this.canClosePopover = true;
      this.open();
    });

    this._render.listen(
      this._elRef.nativeElement,
      'mouseleave',
      (event: Event) => {
        setTimeout(() => {
          if (this.canClosePopover) {
            this.close();
          }
        }, 100);
      }
    );

    this._render.listen(this._elRef.nativeElement, 'click', () => {
      this.close();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  override open() {
    super.open();
    setTimeout(() => {
      const popover = window.document.querySelector('.popover');
      if (popover) {
        this._render.listen(popover, 'mouseover', () => {
          this.canClosePopover = false;
        });

        this._render.listen(popover, 'mouseout', () => {
          this.canClosePopover = true;
          setTimeout(() => {
            if (this.canClosePopover) {
              this.close();
            }
          }, 0);
        });
      }
    }, 0);
  }

  override close() {
    super.close();
  }
}
