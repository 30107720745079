<div *ngIf="!editMode" (click)="editMode = true" class="cell">
  {{ localDataShow }}
</div>

<input
  *ngIf="editMode"
  (focusout)="onFocusOut()"
  (keydown.enter)="blurEvent($event)"
  [(ngModel)]="localData"
  class="cellInput"
  appAutofocus
  type="{{ inputType }}"
  (keydown.enter)="blurEvent($event)"
  (focusout)="editMode = false"
/>
