<div
  [ngClass]="'clearfix'"
  [class]="styleClass"
  [ngStyle]="style"
  *ngIf="alwaysShow ? true : pageLinks && pageLinks.length > 1"
>
  <div class="float-left">
      <ng-select
        *ngIf="rowsPerPageOptions"
        class="pageSizer"
        [searchable]="false"
        [clearable]="false"
        [items]="pageSizer"
        [(ngModel)]="rows"
        (change)="onPageSizeChange($event)">
      </ng-select>
  </div>


  <div class="float-right">
    <ul class="pagination nislo-pagination">
      <li class="page-item fastMove">
        <a
          [attr.tabindex]="isFirstPage() ? null : '0'"
          [attr.disabled]="isFirstPage() ? 'disabled' : null"
          [tabindex]="isFirstPage() ? -1 : null"
          aria-label="First"
          class="page-link"
          (click)="changePageToFirst($event)"
          (keydown.enter)="changePageToFirst($event)"
        >
          <i class="flaticon2-fast-back"></i>

        </a>
      </li>

      <li class="page-item fastMove">
        <a
          tabindex="0"
          [attr.tabindex]="isFirstPage() ? null : '0'"
          [attr.disabled]="isFirstPage() ? 'disabled' : null"
          [tabindex]="isFirstPage() ? -1 : null"
          aria-label="Previous"
          class="page-link"
          (click)="changePageToPrev($event)"
          (keydown.enter)="changePageToPrev($event)"
          ><i class="flaticon2-back"></i>
      </a>
      </li>

      <li *ngFor="let pageLink of pageLinks" class="page-item">
        <a
          tabindex="0"
          class="page-link"
          (click)="onPageLinkClick($event, pageLink - 1)"
          (keydown.enter)="onPageLinkClick($event, pageLink - 1)"
          [ngClass]="{ active: pageLink - 1 === getPage() }"
          >{{ pageLink }}</a
        >
      </li>

      <li class="page-item fastMove">
        <a
          [attr.tabindex]="isLastPage() ? null : '0'"
          [attr.disabled]="isLastPage() ? 'disabled' : null"
          [tabindex]="isLastPage() ? -1 : null"
          aria-label="Next"
          class="page-link"
          (click)="changePageToNext($event)"
          (keydown.enter)="changePageToNext($event)"
        >
          <i class="flaticon2-next"></i>
        </a>
      </li>

      <li class="page-item fastMove">
        <a
          [attr.tabindex]="isLastPage() ? null : '0'"
          [attr.disabled]="isLastPage() ? 'disabled' : null"
          [tabindex]="isLastPage() ? -1 : null"
          aria-label="Next"
          class="page-link"
          (click)="changePageToLast($event)"
          (keydown.enter)="changePageToLast($event)"
          ><i class="flaticon2-fast-next"></i
        ></a>
      </li>
    </ul>
  </div>

  <div class="float-left" *ngIf="templateLeft">
    <ng-container
      *ngTemplateOutlet="templateLeft; context: { $implicit: paginatorState }"
    ></ng-container>
  </div>
</div>
