<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="user$ | async as _user"
  class="kt-header__topbar-item kt-header__topbar-item--user"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
    <span class="kt-header__topbar-username kt-hidden-mobile">
      {{ _user.fullname }}
    </span>
    <img [hidden]="true" alt="Pic" [attr.src]="_user.pic" />
    <span
      class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
      >{{ _user.fullname }}</span
    >
  </div>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
  >
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x"
    >
      <div class="kt-user-card__avatar">
        <img alt="Pic" [attr.src]="_user.pic" />
        <span
          [hidden]="true"
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
        >
          {{ _user.fullname }}
        </span>
      </div>
      <div class="kt-user-card__name">
        {{ _user.fullname }}
      </div>
      <div class="kt-user-card__badge">
        <span class="btn btn-label-primary btn-sm btn-bold btn-font-md"
          >23 messages</span
        >
      </div>
    </div>
    <!--end: Head -->

    <!--begin: Navigation -->
    <div class="kt-notification">
      <a routerLink="profile" href="javascript:;" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Profile</div>
          <div class="kt-notification__item-time">
            Account settings and more
          </div>
        </div>
      </a>
      <a routerLink="profile" href="javascript:;" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-mail kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Messages
          </div>
          <div class="kt-notification__item-time">Inbox and tasks</div>
        </div>
      </a>
      <a routerLink="profile" href="javascript:;" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-rocket-1 kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Activities
          </div>
          <div class="kt-notification__item-time">Logs and notifications</div>
        </div>
      </a>
      <a routerLink="profile" href="javascript:;" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-hourglass kt-font-brand"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Tasks</div>
          <div class="kt-notification__item-time">
            latest tasks and projects
          </div>
        </div>
      </a>
      <div class="kt-notification__custom">
        <a
          href="javascript:;"
          (click)="logout()"
          class="btn btn-outline-brand btn-upper btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div>
    <!--end: Navigation -->

    <!--begin: Navigation(alternative) -->
    <ul class="kt-nav kt-margin-b-10 kt-hidden">
      <li class="kt-nav__item">
        <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-calendar-3"></i
          ></span>
          <span class="kt-nav__link-text">My Profile</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-browser-2"></i
          ></span>
          <span class="kt-nav__link-text">My Tasks</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
          <span class="kt-nav__link-text">Messages</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
          <span class="kt-nav__link-text">Settings</span>
        </a>
      </li>
      <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
        <a
          (click)="logout()"
          class="btn btn-outline-brand btn-upper btn-sm btn-bold"
          >Sign Out</a
        >
      </li>
    </ul>
    <!--end: Navigation(alternative) -->
  </div>
</div>
