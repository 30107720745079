import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService, WritersService } from 'src/app/core/services';
import { ChangePasswordComponent } from 'src/app/modules/auth/change-password/change-password.component';
import { User } from 'src/app/core/models';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, AfterViewInit {
  @Input() icon = 'flaticon2-gear';
  @Input() useSVG!: boolean;
  @Input() bgImage!: string;
  @Input() skin: 'light' | 'dark' = 'light';
  loginUserValue!: User;
  userFullname!: string;

  /**
   * Component constructor
   */
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private readonly writersService: WritersService
  ) {}

  /**
   * After view init
   */
  ngAfterViewInit(): void {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginUserValue = this.authService.currentUserValue;
  }

  onSVGInserted(svg: any): void {
    svg.classList.add('kt-svg-icon', 'kt-svg-icon--success', 'kt-svg-icon--lg');
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      data: {
        userId: 1,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  convertUserRole(role: string) {
    let userRoleText = role;
    if (role === 'CSA') {
      userRoleText = 'Customer Service Agent';
    } else if (role === 'HR') {
      userRoleText = 'HR Manager';
    }
    return userRoleText;
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
    this.writersService.selectedWriters = [];
  }
}
