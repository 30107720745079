export class User {
  constructor(
    public id: number,
    public username: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public isActive: boolean,
    public role?: string,
    public ManagedMainAreaIds?: string[],
    public PermissionKeys?: string[],
    public token?: string,
    public refreshToken?: string,
    public scope?: string[]
  ) {}

  get displayName(): string {
    if (!this.firstName) {
      this.firstName = '';
    }
    if (!this.lastName) {
      this.lastName = '';
    }

    return `${this.firstName} ${this.lastName
      .substr(0, 1)
      .toLocaleUpperCase()}`;
  }

  get fullname(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get pic(): string {
    return '';
  }
}

export interface CreateUserRequest {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  password: string;
  managedMainAreaIds: number[];
}

export interface UpdateUserRequest {
  id: number;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  managedMainAreaIds: number[];
}

export interface ResetPasswordRequest {
  userId: number;
  newPassword: string;
}
