import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
  HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NisloGridComponent } from './grid.component';
import { GridService } from './grid.service';

@Directive({
  selector: '[nrSelectableRow]'
})
export class SelectableRowDirective implements OnInit, OnDestroy {
  @HostBinding('class.selectable-row') get isSelectableClass() {
    return this.isEnabled();
  }
  @HostBinding('class.highlight') get isSelected() {
    return this.selected;
  }
  @HostBinding('attr.tabindex') get tabIndex() {
    return this.isEnabled() ? 0 : undefined;
  }

  @Input('nrSelectableRow') data: any;

  // tslint:disable-next-line: no-input-rename
  @Input('nrSelectableRowIndex') index: number;

  @Input() pSelectableRowDisabled: boolean;

  selected: boolean;

  subscription: Subscription;

  constructor(public dt: NisloGridComponent, public gridService: GridService) {
    if (this.isEnabled()) {
      this.subscription = this.dt.gridService.selectionSource$.subscribe(() => {
        this.selected = this.dt.isSelected(this.data);
      });
    }
  }

  ngOnInit() {
    if (this.isEnabled()) {
      this.selected = this.dt.isSelected(this.data);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.isEnabled()) {
      if ((event as KeyboardEvent).ctrlKey) {
        this.dt.handleRowAuxClick({
          originalEvent: event,
          rowData: this.data,
          rowIndex: this.index
        });
      } else {
        this.dt.handleRowClick({
          originalEvent: event,
          rowData: this.data,
          rowIndex: this.index
        });
      }
    }
  }

  @HostListener('auxclick', ['$event'])
  onAuxClick(event: Event) {
    if (this.isEnabled()) {
      this.dt.handleRowAuxClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
    }
  }

  // @HostListener('touchend', ['$event'])
  // onTouchEnd(event: Event) {
  //   if (this.isEnabled()) {
  //     this.dt.handleRowTouchEnd(event);
  //   }
  // }

  isEnabled() {
    return this.pSelectableRowDisabled !== true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
