import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import {
  SearchResult,
  Payment,
  PaymentDetail,
  PlanPaymentRequest,
  UpdatePaymentRequest,
} from '../../models';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { retryWhen, delay, take } from 'rxjs/operators';

const API: any = {
  payments: 'payments',
  unplannedPayments: 'reports/unplanned-payments',
  planPayment: 'payments/plan',
  cancel: 'cancel',
  confirm: 'confirm',
  markAsPaid: 'mark-as-paid',
  downloadQuote: 'payments/downloadQuote',
  paymentLine: 'line',
  paymentMethods: 'payment-methods',
  history: 'history',
  collections: 'collections',
  collectionPlans: 'collection-plans'
};

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private readonly http: HttpClient) {}

  getPayments(query: QueryParamsModel): Observable<SearchResult<Payment>> {
    return this.http
      .get<any>(env.apiUrl + API.payments + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getCollections(query: QueryParamsModel): Observable<SearchResult<Payment>> {
    return this.http
      .get<any>(env.apiUrl + API.collections + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getPayment(paymentId: number): Observable<UpdatePaymentRequest> {
    return this.http
      .get<any>(`${env.apiUrl}${API.payments}/${paymentId}`)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getUnplannedPayments(customerId: number): Observable<PaymentDetail[]> {
    return this.http
      .get<any>(`${env.apiUrl}${API.unplannedPayments}/${customerId}`)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  planPayment(request: PlanPaymentRequest): Observable<Payment> {
    return this.http
      .post<any>(`${env.apiUrl}${API.payments}`, request)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  updatePayment(paymentId: number, request: any): Observable<Payment> {
    return this.http.put<any>(
      `${env.apiUrl}${API.payments}/${paymentId}`,
      request
    );
  }

  cancelPayment(paymentId: number): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.payments}/${paymentId}/${API.cancel}`,
      null
    );
  }

  confirmPayment(paymentId: number): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.payments}/${paymentId}/${API.confirm}`,
      null
    );
  }

  markAsPaid(paymentId: number): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.payments}/${paymentId}/${API.markAsPaid}`,
      null
    );
  }

  downloadQuoteInvoice(lineIds: number[]): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.http
      .get(`${env.apiUrl}${API.downloadQuote}/${lineIds.join()}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getPaymentMethod(writerId: number) {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.paymentMethods}/${API.active}`
    );
  }

  getPaymentStatusHistory(paymentId: number) {
    return this.http
      .get<any>(`${env.apiUrl}${API.payments}/${paymentId}/${API.history}`)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getCollectionPlans(query) {
    return this.http.get<any>(`${env.apiUrl}${API.collectionPlans}${query.toUrlParams()}`)
  }
}
