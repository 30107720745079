import { Component, OnDestroy, OnInit } from '@angular/core';
import { shuffle } from 'lodash';
import { SparklineChartOptions } from 'src/app/shared/base/directives';
import { Widget4Data } from 'src/app/shared/content/widgets/widget4/widget4.component';
import { LayoutConfigService } from 'src/app/shared/base/services';
import { PaymentService, ProjectService } from 'src/app/core/services';
import { Subscription } from 'rxjs';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { tap } from 'rxjs/operators';
import { Payment, Project, SearchResult } from 'src/app/core/models';

@Component({
  selector: 'app-kt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  chartOptions1: SparklineChartOptions;
  chartOptions2: SparklineChartOptions;
  chartOptions3: SparklineChartOptions;
  chartOptions4: SparklineChartOptions;
  knowledgeBase: Widget4Data[];
  widget2: Widget4Data;
  learningMaterials: Widget4Data[];
  widget4: Widget4Data[];

  private subscriptions: Subscription[] = [];
  paymentsLoading = true;
  projectsLoading = true;

  payments: Payment[];
  projects: Project[];
  query: QueryParamsModel = new QueryParamsModel([]);

  totalEarnings = 0;
  totalCompleted = 0;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private readonly projectService: ProjectService,
    private readonly paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.query.limit = 500;
    this.loadPayments(this.query);
    this.loadProjects(this.query);

    this.chartOptions1 = {
      data: [0, 0, 0, 0, 0, 0, 225, 0, 0, 0],
      color: this.layoutConfigService.getConfig('colors.state.brand'),
      border: 3,
    };
    this.chartOptions2 = {
      data: [0, 0, 0, 1, 0, 1, 0, 0, 0, 0],
      color: this.layoutConfigService.getConfig('colors.state.danger'),
      border: 3,
    };
    this.chartOptions3 = {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      color: this.layoutConfigService.getConfig('colors.state.success'),
      border: 3,
    };
    this.chartOptions4 = {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      color: this.layoutConfigService.getConfig('colors.state.primary'),
      border: 3,
    };

    // @ts-ignore
    this.knowledgeBase = [
      {
        pic: './assets/media/files/doc.svg',
        title: 'Introduction to Referencing Guides',
        url: 'https://nislo.spring.io/file/Ax9cjw2',
      },
      {
        pic: './assets/media/files/pdf.svg',
        title: 'Project Kick-off',
        url: 'https://keenthemes.com.my/metronic',
      },
      {
        pic: './assets/media/files/pdf.svg',
        title: 'Full Developer Manual For 4.7',
        url: 'https://keenthemes.com.my/metronic',
      },
      {
        pic: './assets/media/files/doc.svg',
        title: 'Market Research Template for EMEA and Asia',
        url: 'https://keenthemes.com.my/metronic',
      },
      {
        pic: './assets/media/files/zip.svg',
        title: 'Download PSPP version 14 fully SPSS compatible',
        url: 'https://keenthemes.com.my/metronic',
      },
      {
        pic: './assets/media/files/pdf.svg',
        title: 'How to Prepare Finance Reports 2020',
        url: 'https://keenthemes.com.my/metronic',
      },
    ];
    // @ts-ignore
    this.widget2 = shuffle([
      {
        pic: './assets/media/users/100_4.jpg',
        username: 'Anna Strong',
        desc: 'Visual Designer,Google Inc.',
        url: 'https://keenthemes.com.my/metronic',
        buttonClass: 'btn-label-brand',
      },
      {
        pic: './assets/media/users/100_14.jpg',
        username: 'Milano Esco',
        desc: 'Product Designer, Apple Inc.',
        url: 'https://keenthemes.com.my/metronic',
        buttonClass: 'btn-label-warning',
      },
      {
        pic: './assets/media/users/100_11.jpg',
        username: 'Nick Bold',
        desc: 'Web Developer, Facebook Inc.',
        url: 'https://keenthemes.com.my/metronic',
        buttonClass: 'btn-label-danger',
      },
      {
        pic: './assets/media/users/100_1.jpg',
        username: 'Wilter Delton',
        desc: 'Project Manager, Amazon Inc.',
        url: 'https://keenthemes.com.my/metronic',
        buttonClass: 'btn-label-success',
      },
      {
        pic: './assets/media/users/100_5.jpg',
        username: 'Nick Stone',
        desc: 'Visual Designer, Github Inc.',
        url: 'https://keenthemes.com.my/metronic',
        buttonClass: 'btn-label-dark',
      },
    ]);
    // @ts-ignore
    this.learningMaterials = [
      {
        icon: 'flaticon2-file kt-font-focus',
        title: 'Getting Started',
        url: 'https://keenthemes.com.my/metronic',
        valueColor: 'kt-font-focus',
      },
      {
        icon: 'flaticon2-file kt-font-focus',
        title: 'Our Quality Requirements',
        url: 'https://keenthemes.com.my/metronic',
        valueColor: 'kt-font-focus',
      },
      {
        icon: 'flaticon2-analytics kt-font-focus',
        title: 'Best Practices for Statistical Analysis',
        url: 'https://keenthemes.com.my/metronic',
        valueColor: 'kt-font-focus',
      },
    ];
    // @ts-ignore
    this.widget4 = [
      {
        icon: 'flaticon2-file kt-font-focus',
        title: 'Trump Themes',
        desc: 'Make Metronic Great Again',
        url: 'https://keenthemes.com.my/metronic',
        value: '$0',
        valueColor: 'kt-font-focus',
      },
      {
        icon: 'flaticon2-file kt-font-focus',
        title: 'StarBucks',
        desc: 'Good Coffee & Snacks',
        url: 'https://keenthemes.com.my/metronic',
        value: '$225',
        valueColor: 'kt-font-brand',
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  private loadPayments(query: QueryParamsModel): void {
    this.paymentsLoading = true;

    const payments$ = this.paymentService
      .getPayments(query)
      .pipe(tap((data) => this.parsePaymentResults(data)))
      .subscribe((_) => {
        this.paymentsLoading = false;
      });

    this.subscriptions.push(payments$);
  }

  private loadProjects(query: QueryParamsModel): void {
    this.projectsLoading = true;

    const projects$ = this.projectService
      .getMyProjects(query)
      .pipe(tap((data) => this.parseProjectResults(data)))
      .subscribe((_) => {
        this.projectsLoading = false;
      });

    this.subscriptions.push(projects$);
  }

  private parsePaymentResults(data: SearchResult<Payment>): void {
    this.payments = data.results;
  }

  private parseProjectResults(data: SearchResult<Project>): void {
    this.projects = data.results;
    this.totalCompleted = this.projects.filter(
      (x) => x.status === 'Completed'
    ).length;
  }
}
