<!--begin: Quick actions -->

<!-- ORIGINAL -->
<!--begin: Quick actions -->
<div
  ngbDropdown
  placement="bottom-right"
  autoClose="outside"
  class="kt-header__topbar-item d-none"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span class="kt-header__topbar-icon">
      <i *ngIf="!useSVG" [ngClass]="icon"></i>
      <span class="kt-svg-icon" *ngIf="useSVG" [inlineSVG]="icon"></span>
    </span>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
  >
    <form>
      <!--begin: Head -->
      <div
        class="kt-head kt-head--skin-{{ skin }}"
        [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }"
      >
        <h3 class="kt-head__title">
          User Quick Actions
          <span class="kt-space-15"></span>
          <span class="btn btn-success btn-sm btn-bold btn-font-md"
            >23 tasks pending</span
          >
        </h3>
      </div>
      <!--end: Head -->
      <!--begin: Grid Nav -->
      <div
        class="kt-grid-nav"
        [ngClass]="{
          'kt-grid-nav--skin-dark': skin === 'light',
          'kt-grid-nav--skin-light': skin === 'dark'
        }"
      >
        <div class="kt-grid-nav__row">
          <a href="javascript:;" class="kt-grid-nav__item">
            <span
              class="kt-grid-nav__icon"
              [inlineSVG]="'./assets/media/icons/svg/Shopping/Euro.svg'"
              (onSVGInserted)="onSVGInserted($event)"
            ></span>
            <span class="kt-grid-nav__title">Accounting</span>
            <span class="kt-grid-nav__desc">eCommerce</span>
          </a>
          <a href="javascript:;" class="kt-grid-nav__item">
            <span
              class="kt-grid-nav__icon"
              [inlineSVG]="
                './assets/media/icons/svg/Communication/Mail-attachment.svg'
              "
              (onSVGInserted)="onSVGInserted($event)"
            ></span>
            <span class="kt-grid-nav__title">Administration</span>
            <span class="kt-grid-nav__desc">Console</span>
          </a>
        </div>
        <div class="kt-grid-nav__row">
          <a href="javascript:;" class="kt-grid-nav__item">
            <span
              class="kt-grid-nav__icon"
              [inlineSVG]="'./assets/media/icons/svg/Shopping/Box%232.svg'"
              (onSVGInserted)="onSVGInserted($event)"
            ></span>
            <span class="kt-grid-nav__title">Projects</span>
            <span class="kt-grid-nav__desc">Pending Tasks</span>
          </a>
          <a href="javascript:;" class="kt-grid-nav__item">
            <span
              class="kt-grid-nav__icon"
              [inlineSVG]="'./assets/media/icons/svg/Communication/Group.svg'"
              (onSVGInserted)="onSVGInserted($event)"
            ></span>
            <span class="kt-grid-nav__title">Customers</span>
            <span class="kt-grid-nav__desc">Latest cases</span>
          </a>
        </div>
        <!--end: Grid Nav -->
      </div>
    </form>
  </div>
</div>
<!--end: Quick actions -->
