import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { QueryParamsModel } from "../../../../shared/base/models";
import {Customer, LookupOption, Project, ProjectStatus, StatusType} from "../../../../core/models";
import {LookupService, ProjectService, SalesService} from "../../../../core/services";
import { Router } from '@angular/router';
import { AddPaymentPlanComponent } from 'src/app/modules/customer/pages/profile/add-payment-plan/add-payment-plan.component';
import { MatDialog } from "@angular/material/dialog";
import {NgbDate, NgbDateParserFormatter, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {map} from "rxjs/operators";
import {Clipboard} from "@angular/cdk/clipboard";


@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent implements OnInit {
  StatusType = StatusType;
  currency = environment.currency;
  defaultRows: any[] = [];
  totalRecords = 0;
  getPlannedProjects: QueryParamsModel = new QueryParamsModel([]);
  plannedProjects: Project[];
  statuses: any[];
  btnClearAll = false;
  filters: any;
  loading = false;

  fromDate: NgbDate;
  toDate: NgbDate;
  dueFromDate: NgbDate;
  dueToDate: NgbDate;
  hoveredDate: NgbDate;
  selectedDeadline;
  selectedDueDate;
  deadlineField;
  dueDateField;
  arrDeadline: any[] = [];
  csas: any[] = []

  @Input() customer: Customer;
  @Input() where: string;
  @Input() header: string;

  @ViewChild('deadline') deadline: ElementRef;
  @ViewChild('dueDate') dueDate: ElementRef;
  @ViewChild('d2') d2: NgbInputDatepicker;
  @ViewChild('dueDatePicker') dueDatePicker: NgbInputDatepicker;


  constructor(
    private readonly dialog: MatDialog,
    private readonly projectService: ProjectService,
    private readonly lookupService: LookupService,
    private readonly salesService: SalesService,
    private readonly router: Router,
    private parserFormatter: NgbDateParserFormatter,
    private renderer: Renderer2,
    private clipboard: Clipboard,
  ) { }

  ngOnInit(): void {
    this.setFilters()
    this.clearAllFilters()
    this.loadLookups()
  }

  setFilters() {
    if (this.where === 'customerPage')
    {
      this.getPlannedProjects.filter[0] = 'customerId==' + this.customer.id.toString()
    }
    this.getPlannedProjects.page = '?offset=' + this.getPlannedProjects.offset.toString() + '&limit=' + this.getPlannedProjects.limit.toString()
    this.getPlannedProjects.sortDirection = 'desc'
    this.getPlannedProjects.sortField = 'id'
  }

  getCollections() {
    this.loading = true;
    this.projectService.getCollectionsSearch(this.getPlannedProjects).subscribe({
      next: (data) => {
        this.plannedProjects = data.results
        this.totalRecords = data.total
        this.loading = false;
      }
    })
  }

  load(event: any) {
    if (event.filter) {
      this.getPlannedProjects.filter = event.filter;
    }
    if (event.sortField) {
      this.getPlannedProjects.sortField = event.sortField;
      this.getPlannedProjects.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }
    this.getPlannedProjects.offset = event.first;
    this.getPlannedProjects.limit = event.rows;
    this.getCollections()
  }

  loadLookups() {
    this.lookupService.getCollectionPlanStatuses().subscribe({
      next: result => {
        this.statuses = result
      }
    })

    this.salesService.getCsas().subscribe({
      next: data => {
        this.csas = data.results
      }
    })
  }

  filteredForm() {
    if (this.where === 'customerPage')
    {
      this.getPlannedProjects.filter[0] = 'customerId==' + this.customer.id.toString()
    }
    else
    {
      this.getPlannedProjects.filter = []
    }

    if (this.filters['refNo'])
    {
      this.getPlannedProjects['filter'][0]
        ? this.getPlannedProjects['filter'][0] += `,referenceNumber@=${this.filters['refNo']}`
        : this.getPlannedProjects['filter'][0] = `referenceNumber@=${this.filters['refNo']}`
    }

    if (this.filters['amount'])
    {
      this.getPlannedProjects['filter'][0]
        ? this.getPlannedProjects['filter'][0] += `,totalAmount==${this.filters['amount']}`
        : this.getPlannedProjects['filter'][0] = `totalAmount==${this.filters['amount']}`
    }

    if (this.filters['discount'])
    {

    }

    if (this.filters['status'])
    {
      this.getPlannedProjects['filter'][0]
        ? this.getPlannedProjects['filter'][0] += `,statusId==${this.filters['status']}`
        : this.getPlannedProjects['filter'][0] = `statusId==${this.filters['status']}`
    }

    if (this.filters['csa'])
    {
      this.getPlannedProjects['filter'][0]
        ? this.getPlannedProjects['filter'][0] += `,csaId==${this.filters['csa']}`
        : this.getPlannedProjects['filter'][0] = `csaId==${this.filters['csa']}`
    }

    if (this.filters['deadline'])
    {
      this.getPlannedProjects['filter'][0]
          ? this.getPlannedProjects['filter'][0] += `,latestDeadline<>${this.filters['deadline']}`
          : this.getPlannedProjects['filter'][0] = `latestDeadline<>${this.filters['deadline']}`
    }

    if (this.filters['dueDate'])
    {
      this.getPlannedProjects['filter'][0]
          ? this.getPlannedProjects['filter'][0] += `,latestDueDate<>${this.filters['dueDate']}`
          : this.getPlannedProjects['filter'][0] = `latestDueDate<>${this.filters['dueDate']}`
    }

    this.btnClearAll = !!(this.filters['refNo'] || this.filters['amount'] ||
        this.filters['discount'] || this.filters['status'] || this.filters['csa']);

    this.getCollections()

  }

  isHovered(area: string, date: NgbDate): boolean | void {
    if (area == 'deadline') {
      return (
          this.fromDate &&
          !this.toDate &&
          this.hoveredDate &&
          date.after(this.fromDate) &&
          date.before(this.hoveredDate)
      );
    }

    if (area == 'dueDate') {
      return (
          this.dueFromDate &&
          !this.dueToDate &&
          this.hoveredDate &&
          date.after(this.dueFromDate) &&
          date.before(this.hoveredDate)
      );
    }
  }

  isInside(area: string, date: NgbDate): boolean | void {
    if (area == 'deadline') {
      return date.after(this.fromDate) && date.before(this.toDate);
    }

    // ! convert to enum or union type

    if (area == 'dueDate') {
      return date.after(this.dueFromDate) && date.before(this.dueToDate);
    }
  }

  isRange(area: string, date: NgbDate): boolean | void {
    // ! convert to enum or union type
    if (area == 'deadline') {
      return (
          date.equals(this.fromDate) ||
          date.equals(this.toDate) ||
          this.isInside(area, date) ||
          this.isHovered(area, date)
      );
    }

    if (area === 'dueDate') {
      return (
          date.equals(this.dueFromDate) ||
          date.equals(this.dueToDate) ||
          this.isInside(area, date) ||
          this.isHovered(area, date)

      )
    }
  }

   getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }

  onDateSelection(area: string, date: NgbDate) {

    
    // tslint:disable-next-line: triple-equals
    if (area === 'deadline') {
      let parsed = '';
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        // } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      } else if (this.fromDate && !this.toDate) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
      if (this.fromDate) {
        // parsed += this.parserFormatter.format(this.fromDate);
        parsed += this.fromDate.day +' ' + this.getMonthName(this.fromDate.month) +' '+ this.fromDate.year;
        this.selectedDeadline = '';
        this.renderer.setProperty(this.deadline.nativeElement, 'value', parsed);
        this.filters['deadline'] = parsed;
      }
      if (this.toDate) {
        var formatted_to_date = this.toDate.day +' ' + this.getMonthName(this.toDate.month) +' '+ this.toDate.year;
        const reqdeadline = [parsed, formatted_to_date];
        this.selectChange('deadline', reqdeadline);

        this.renderer.setProperty(this.deadline.nativeElement, 'value', parsed);
        if (parsed !== formatted_to_date) {
          parsed += ' - ' + formatted_to_date;
          this.selectedDeadline = parsed;
          this.renderer.setProperty(this.deadline.nativeElement, 'value', '-');
        }
      }
      this.filters['deadline'] = parsed;
      this.btnClearAll = true;
    }
    
    if (area === 'dueDate') {
      let parsed = '' 
      if (!this.dueFromDate && !this.dueToDate) {
        this.dueFromDate = date;
      } else if (this.dueFromDate && !this.dueToDate) {
        this.dueToDate = date;
      } else {
        this.dueToDate = null;
        this.dueFromDate = date;
      }
      
      if (this.dueFromDate) {
        parsed += this.dueFromDate.day +' ' + this.getMonthName(this.dueFromDate.month) +' '+ this.dueFromDate.year;
        this.selectedDueDate = '';
        this.renderer.setProperty(this.dueDate.nativeElement, 'value', parsed);

        this.filters['dueDate'] = parsed;
      }
      if (this.dueToDate) {
        var formatted_due_date = this.dueToDate.day +' ' + this.getMonthName(this.dueToDate.month) +' '+ this.dueToDate.year;
        const reqDueDate = [parsed, formatted_due_date];
        this.selectChange('dueDate', reqDueDate);

        this.renderer.setProperty(this.dueDate.nativeElement, 'value', parsed);
        if (parsed !== formatted_due_date) {
          parsed += ' - ' + formatted_due_date
          this.selectedDueDate = parsed;
          this.renderer.setProperty(this.dueDate.nativeElement, 'value', '-')
        }
      }
      this.filters['dueDate'] = parsed;
      this.btnClearAll = true;
    }
    
  }

  selectChange(section: string, $event?) {
    if (section === 'deadline') {
      if ($event.length > 1) {
        this.filters['deadline'] = ($event[0] + ';' + $event[1]);
      } else {
        this.filters['deadline'] = ($event[0] + ';' + $event[0]);
      }
      this.filteredForm()
      this.d2.close();
    }

    if (section === 'dueDate') {
      if ($event.length > 1) {
        this.filters['dueDate'] = ($event[0] + ';' + $event[1]);
      } else {
        this.filters['dueDate'] = ($event[0] + ';' + $event[0]);
      }
      this.filteredForm()
      this.dueDatePicker.close();
    }
  }

  dateClear(field: string) {
    if (field === 'deadlineField') {
      this.deadlineField = '';
      this.filters['deadline'] = null;
      this.selectedDeadline = '';
      this.fromDate = null;
      this.toDate = null;
    }

    if (field === 'dueDateField') {
      this.dueDateField = '';
      this.filters['dueDate'] = null;
      this.selectedDueDate = '';
      this.dueFromDate = null;
      this.dueToDate = null;
    }

    this.filteredForm()
  }

  clearAllFilters() {
    this.filters = {
      'refNo': null,
      'amount': null,
      'discount': null,
      'status': null,
      'csa': null,
      'deadline': null,
      'dueDate': null,
    }

    this.selectedDeadline = null
    this.selectedDueDate = null
    this.hoveredDate = null;
    this.btnClearAll = false;

    this.filteredForm()

  }

  openInspectPlan(plan) {
    const dialogRef = this.dialog.open(AddPaymentPlanComponent, {
      disableClose: true,
      data: {
        customer: { id: plan.customerId },
        type: 'view',
        planId: plan.id,
        quoteRef: plan.referenceNumber,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true
      setTimeout(() => {
        this.ngOnInit()
      }, 1000)
    })
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
}
