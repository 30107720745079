import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class ErrorService {
  constructor(
    private readonly toastrService: ToastrService,
  ) { }

  showGenericErrorMessage(error: any) {
    console.log(error);
    if (error.error == null) {
      this.toastrService.error(
        '[' + error.status + '] ' + error.statusText,
        'Oops! Something went wrong!'
      );
    } else {

      this.toastrService.error(
        error.error.Message ?? ( '[' + error.status + '] ' + error.error.title),
        'Oops! Something went wrong!'
      );
    }

  }
}
