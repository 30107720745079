<div
  ngbDropdown
  placement="auto"
  autoClose="outside"
  class="kt-header__topbar-item"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span
      class="kt-header__topbar-icon"
      [ngClass]="{
        'kt-pulse kt-pulse--brand':
          this.messages?.length + this.freshMessages.length > 0,
        'kt-pulse--light': pulseLight,
        'kt-header__topbar-icon--success': iconType === 'success'
      }"
    >
      <i *ngIf="!useSVG" [ngClass]="icon"></i>
      <span
        *ngIf="useSVG"
        class="kt-svg-icon"
        [ngClass]="{ 'kt-svg-icon--success': iconType === 'success' }"
        [inlineSVG]="icon"
      ></span>
      <span class="kt-pulse__ring" [hidden]="!pulse"></span>
      <span *ngIf="getTotalCount() > 0" class="badge notificationBadge">{{
        getTotalCount()
      }}</span>
    </span>
    <span
      class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand"
      [hidden]="!dot"
    ></span>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
  >
    <form>
      <!--begin: Head -->
      <div
        class="kt-head kt-head--skin-{{ skin }} kt-head--fit-x kt-head--fit-b"
        style="padding: 1px"
        [ngStyle]="{ 'background-image': backGroundStyle() }"
      >
        <ul
          ktTabClickEvent
          class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-2x nav-tabs-line-{{
            type
          }} kt-notification-item-padding-x"
          role="tablist"
        >
          <li class="nav-item">
            <!-- (click)="tab.select('tab-id-1')" -->

            <a
              class="nav-link active show"
              data-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="true"
            >
              Notifications
            </a>
          </li>
        </ul>
      </div>
      <!--end: Head -->
      <!-- <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
          <a ngbNavLink>First</a>
          <ng-template ngbNavContent>First content</ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Second</a>
          <ng-template ngbNavContent>Second content</ng-template>
        </li>
      </ul> -->
      <!-- <div [ngbNavOutlet]="nav"></div> -->
      <div>
        <div id="tab-id-1">
          <div>
            <div
              [perfectScrollbar]="{ wheelPropagation: false }"
              [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
              class="kt-notification kt-margin-t-10 kt-margin-b-10"
            >
              <div *ngFor="let item of freshMessages">
                <a [href]="item.click_action" class="kt-notification__item">
                  <div class="kt-notification__item-icon">
                    <i class="flaticon2-line-chart kt-font-success"></i>
                  </div>
                  <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title">
                      {{ item.body }}
                    </div>
                    <div class="kt-notification__item-time">
                      {{ item.createDate | timeago }}
                    </div>
                  </div>
                </a>
              </div>

              <div *ngFor="let item of messages">
                <a [href]="item.click_action" class="kt-notification__item">
                  <div class="kt-notification__item-icon">
                    <i class="flaticon2-line-chart kt-font-success"></i>
                  </div>
                  <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title">
                      {{ item.body }}
                    </div>
                    <div class="kt-notification__item-time">
                      {{ item.createDate | timeago }}
                    </div>
                  </div>
                </a>
              </div>

              <div *ngIf="getTotalCount() === 0">
                <a class="kt-notification__item">
                  <div class="kt-notification__item-icon"></div>
                  <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title">
                      No message received yet
                    </div>
                    <div class="kt-notification__item-time"></div>
                  </div>
                </a>
              </div>

              <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                  class="ps__thumb-x"
                  tabindex="0"
                  style="left: 0px; width: 0px"
                ></div>
              </div>
              <div class="ps__rail-y" style="top: 0px; right: 0px">
                <div
                  class="ps__thumb-y"
                  tabindex="0"
                  style="top: 0px; height: 0px"
                ></div>
              </div>
            </div>
            <div
              style="
                text-align: center;
                background-color: #f2f2f7;
                font-weight: 400;
                padding: 5px;
              "
            >
              <a routerLink="/notifications">Show All Notifications</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
