import { StorageType, StorageService } from './local-storage.interface';

const sessionKey = 'session';

export class LocalVariable implements StorageService {
  private dictionary: { [key: string]: string } = {};

  storageType: StorageType = StorageType.LocalVariable;

  getItem(key: string): string {
    return this.dictionary[key];
  }

  setItem(key: string, value: string): void {
    this.dictionary[key] = value;
  }

  removeItem(key: string): void {
    delete this.dictionary[key];
  }

  clear(keepSession: boolean): void {
    if (keepSession) {
      const session = this.dictionary[sessionKey];
      this.dictionary = {};
      this.setItem(sessionKey, session);
    } else {
      this.dictionary = {};
    }
  }

  isSupported(): boolean {
    return true;
  }
}
