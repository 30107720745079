import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NotificationToken } from './../../models/notification/notifications.model';
import { MessagingService } from 'src/app/core/services/messaging/messaging.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
// import * as jwt_decode from 'jwt-decode';
import jwt_decode from 'jwt-decode';

import {
  User,
  LoginRequest,
  LoginResponse,
  ChangePassword,
  ResetPassword,
  NewPassword,
} from '../../models';
import { LocalStorageService } from '../storage/local-storage.service';
import { ErrorService } from '../error/error.services';

const sessionKey = 'session';
const API: any = {
  api: 'api',
  login: 'connect/token',
  users: 'users',
  password: 'change-password',
  resetPassword: 'reset-password',
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorage: LocalStorageService,
    private readonly messagingService: MessagingService,
    private readonly toastrService: ToastrService,
    private readonly errorService: ErrorService
  ) {
    let user: User = null;
    const userValue = this.localStorage.getItem(sessionKey);
    if (userValue) {
      const parsedUser = JSON.parse(userValue);
      user = new User(
        parsedUser.id,
        parsedUser.username,
        parsedUser.email,
        parsedUser.firstName,
        parsedUser.lastName,
        true,
        parsedUser.role,
        parsedUser.ManagedMainAreaIds,
        parsedUser.PermissionKeys,
        parsedUser.token,
        parsedUser.refreshToken,
        parsedUser.scope
      );
    }
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(request: LoginRequest): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    });

    const client = 'client_id=nislo.pms.web.ui';
    const secret = 'client_secret=zv2eRrEzAlDpaKSoddu5S2D9AN3x2lal&';
    const scope =
      'scope=openid+email+profile+roles+project.api+search.api+offline_access';
    const grant = 'grant_type=password';

    return this.http
      .post<LoginResponse>(
        env.securityUrl + API.login,
        `${client}&${secret}username=${request.username}&password=${request.password}&${scope}&${grant}`,
        { headers }
      )
      .pipe(
        map((response: LoginResponse) => {
          // login successful if there's a jwt token in the response
          if (response && response.access_token) {
            // store user details and jwt token in local storage to keep user logged
            const userInfo = jwt_decode<any>(response.access_token);

            const user = new User(
              userInfo.sub,
              userInfo.preferred_username,
              userInfo.email,
              userInfo.name,
              userInfo.last_name,
              true,
              userInfo.role,
              userInfo.ManagedMainAreaIds,
              userInfo.PermissionKeys,
              response.access_token,
              response.refresh_token,
              userInfo.scope
            );
            this.localStorage.setItem(sessionKey, JSON.stringify(user));
            this.currentUserSubject.next(user);

            this.messagingService.getToken();

            const notifToken: NotificationToken = {
              platform: 'Web',
              token: this.messagingService.activeToken,
            };
            this.messagingService.registerDevice(notifToken).subscribe({
              next: (result) => {
                console.log('result:', result);
              },
              error: (error) => {
                console.log('result ERR:', error);
              }
            });

            return user;
          }
          return null;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.localStorage.clear(false);

    try {
      localStorage.clear();
    } catch {
      console.log('Warning: Browser does not support storage.');
    }

    this.messagingService.deleteToken();
    this.currentUserSubject.next(null);
  }

  refreshToken(token: string): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    });

    const client = 'client_id=nislo.pms.web.ui';
    const secret = 'client_secret=zv2eRrEzAlDpaKSoddu5S2D9AN3x2lal&';
    const grant = 'grant_type=refresh_token';

    return this.http
      .post<LoginResponse>(
        env.securityUrl + API.login,
        `${client}&${secret}refresh_token=${token}&${grant}`,
        { headers }
      )
      .pipe(
        map((response: LoginResponse) => {
          // login successful if there's a jwt token in the response
          if (response && response.access_token) {
            // store user details and jwt token in local storage to keep user logged
            const userInfo = jwt_decode<any>(response.access_token);

            const user = new User(
              userInfo.sub,
              userInfo.preferred_username,
              userInfo.email,
              userInfo.name,
              userInfo.last_name,
              true,
              userInfo.role,
              userInfo.ManagedMainAreaIds,
              userInfo.PermissionKeys,
              response.access_token,
              response.refresh_token,
              userInfo.scope
            );
            this.localStorage.setItem(sessionKey, JSON.stringify(user));
            this.currentUserSubject.next(user);

            this.messagingService.getToken();

            const notifToken: NotificationToken = {
              platform: 'Web',
              token: this.messagingService.activeToken,
            };
            this.messagingService.registerDevice(notifToken).subscribe({
              next: (result) => {
                console.log('result:', result);
              },
              error: (error) => {
                console.log('result ERR:', error);
              }
            });

            return user;
          }
          return null;
        })
      );
  }

  changePassword(userId: number, request: ChangePassword): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.users}/${userId}/${API.password}`,
      request
    );
  }

  resetPassword(request: ResetPassword) {
    return this.http.post<any>(
      `${env.securityUrl}${API.users}/${API.resetPassword}`,
      request
    );
  }

  newPassword(id: number, request: NewPassword) {
    return this.http.post<any>(
      `${env.securityUrl}${API.users}/${id}/${API.password}`,
      request
    );
  }
}
