import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { UrlSerializer } from '@angular/router';
import {
  AuthInterceptor,
  ErrorInterceptor,
  LocalStorageService,
} from './core/services';
import { NgbDatePopupParserFormatter } from './shared/custom/datepicker-popup/dateparser-popup';
import { NgbUTCStringAdapter } from './shared/custom/datepicker-popup/ngb-UTC-string-adapter';
import { CustomUrlSerializer } from './shared/helpers/custom-url-serializer';
import { AsyncPipe } from '@angular/common';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';
import { MessagingService } from './core/services/messaging/messaging.service';
import {
  LayoutConfigService,
  LayoutRefService,
  MenuConfigService,
  PageConfigService,
} from './shared/base/services';
import { TimeagoPipe } from './shared/custom/pipes/Timeago.pipe';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    DashboardModule,
    HttpClientModule,
    LayoutModule,
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: true,
      tapToDismiss: true
    }),
    AngularEditorModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: NgbDateParserFormatter, useClass: NgbDatePopupParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbUTCStringAdapter },
    AuthGuard,
    LayoutConfigService,
    LayoutRefService,
    LocalStorageService,
    MenuConfigService,
    PageConfigService,
    MessagingService,
    AsyncPipe,
    TimeagoPipe,
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
