<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
<div class="mat-table__wrapper mat-table__wrapper--dashboard">
<!-- <div class="mat-table__wrapper mat-table__wrapper--dashboard"> -->
	<!-- Checkbox Column -->
	<!-- Table with selection -->
	<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
	<table mat-table class="lmat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
		<!-- <ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
			<td mat-cell *matCellDef="let product" class="mat-column-vinCode">{{product.id}}</td>
		</ng-container> -->

		<ng-container matColumnDef="managedBy">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
			<td mat-cell *matCellDef="let product">
				<div class="kt-user-card-v2">
					<div class="kt-user-card-v2__pic">
						<div class="kt-badge kt-badge--xl kt-badge--warning">{{product.managedBy.substr(0,1)}}</div>
					</div>
					<div class="kt-user-card-v2__details">
						<span class="kt-user-card-v2__name">{{product.managedBy}}</span>
						<a class="kt-user-card-v2__email kt-link">{{product.occupation}}</a>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
			<td mat-cell *matCellDef="let product">{{product.date}}</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
			<td mat-cell *matCellDef="let product">
				<span class="btn btn-bold btn-sm btn-font-sm btn-label-{{ getItemCssClassByStatus(product.status) }}">{{product.status}}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="company">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Supervisor</th>
			<td mat-cell *matCellDef="let product">
				<div class="kt-user-card-v2">
					<div class="kt-user-card-v2__pic">
						<div class="kt-badge kt-badge--xl kt-badge--warning">{{product.company.substr(0,1)}}</div>
					</div>
					<div class="kt-user-card-v2__details">
						<span class="kt-user-card-v2__name">{{product.company}}</span>
						<a class="kt-user-card-v2__email kt-link">{{product.subject}}</a>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef width="100px" class="kt-pl-13">
				Actions
			</th>
			<td mat-cell *matCellDef="let product">
				<kt-context-menu></kt-context-menu>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="mat-table__message" *ngIf="!dataSource">No records found</div>
</div>
<!-- start: BOTTOM -->
<div class="mat-table__bottom mat-table__bottom--padding">
	<mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
	<mat-paginator [pageSize]="6" [pageSizeOptions]="[3, 6, 15]" [length]="paginatorTotal" [showFirstLastButtons]="true"></mat-paginator>
</div>
<!-- end: BOTTOM -->
