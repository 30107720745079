<kt-portlet id="paymentPlansTable">
    <kt-portlet-body>
        <div class="row" *ngIf="header != 'false'">
            <div class="col-sm">
                <div class="pageSubHeader">
                    <h3 class="page-title">Payment Plans</h3>
                    <span class="page-separator"></span>
                    <span class="page-desc">
                        {{ this.totalRecords | number }} results
                    </span>
                </div>
            </div>
        </div>
        <div _ngcontent-cyj-c371="" class="row my-4" *ngIf="header != 'false'">
            <div _ngcontent-cyj-c371="" class="col-sm-12 d-flex justify-content-end"></div>
        </div>

        <div class="row my-4" *ngIf="btnClearAll">
            <div class="col-sm-12 d-flex justify-content-start">
                <button class="clearAllFilters" ngbTooltip="ResetAll Filters" (click)="clearAllFilters()">
                    <i class="fas fa-eraser"></i>
                </button>
            </div>
        </div>
        <ng-template #filterTooltipDeadline>
            <div class="line">{{ selectedDeadline }}</div>
        </ng-template>
        <ng-template #filterTooltipDueDate>
            <div class="line">{{ selectedDueDate}}</div>
        </ng-template>
        <nr-grid [lazy]="true" [paginator]="true" [rows]="getPlannedProjects.limit" [totalRecords]="totalRecords"
            [value]="plannedProjects" (onLazyLoad)="load($event)" [loading]="loading">
            <!-- table -->
            <ng-template nrTemplate="header">
                <tr>
                    <th [nrSortableColumn]="'referenceNumber'">
                        Quote Ref
                        <nr-sort-icon field="referenceNumber"></nr-sort-icon>
                    </th>
                    <!--                    <th>Paid Amount</th>-->
                    <th class="text-end" [nrSortableColumn]="'totalAmount'">
                        Total Amount
                        <nr-sort-icon field="totalAmount"></nr-sort-icon>
                    </th>

                    <th class="text-end" [nrSortableColumn]="'discount'">
                        Discount Amount
                        <nr-sort-icon field="discount"></nr-sort-icon>
                    </th>
                    <th [nrSortableColumn]="'latestDeadline'">
                        Latest Project Deadline<nr-sort-icon field="latestDeadline"></nr-sort-icon>
                    </th>
                    <th [nrSortableColumn]="'latestDueDate'">
                        Latest Payment Due Date<nr-sort-icon field="latestDueDate"></nr-sort-icon>
                    </th>
                    <th nrSortableColumn="status">
                        Status
                        <nr-sort-icon field="status"></nr-sort-icon>
                    </th>
                    <th nrSortableColumn="'csaId'">
                        CSR
                        <nr-sort-icon field="csaId"></nr-sort-icon>
                    </th>
                </tr>
                <tr class="filter">
                    <th>
                        <input type="text" class="form-control" #refNo (keyup)="filteredForm()"
                            [(ngModel)]="filters['refNo']" />
                    </th>
                    <th>
                        <input type="text" class="form-control" #amount (keyup)="filteredForm()"
                            [(ngModel)]="filters['amount']"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                    </th>
                    <th>
                        <input type="number" min="0" class="form-control" #discount (keyup)="filteredForm()"
                            [(ngModel)]="filters['discount']" />
                    </th>

                    <th>
                        <div class="form-control position-relative datepickerMainDiv" (click)="d2.toggle()"
                            [ngbPopover]="filterTooltipDeadline" placement="top" [disablePopover]="!selectedDeadline"
                            popoverTitle="Deadline" triggers="mouseenter:mouseleave">
                            <div class="dateRangeTagView" *ngIf="selectedDeadline">Range</div>
                            <span *ngIf="filters['deadline']" class="inputClear" (click)="dateClear('deadlineField')"
                                style="top: 50%; margin-top: -14px">×</span>
                        </div>
                        <input class="form-control ico-calendar" style="margin-top: -30px" placeholder="dd.mm.yy"
                            type="text" name="filterDeadline" id="filterDeadline" ngModel ngbDatepicker
                            #d2="ngbDatepicker" autoClose="false" [placement]="'bottom-right'"
                            (dateSelect)="onDateSelection('deadline', $event)" #deadline outsideDays="hidden"
                            [dayTemplate]="t" [startDate]="fromDate" [displayMonths]="2" [readonly]="true"
                            dateFormat="dd-MMM-yyyy" />

                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange('deadline', date)"
                                [class.faded]="isHovered('deadline', date) || isInside('deadline', date)"
                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </th>

                    <th>
                        <div class="form-control position-relative datepickerMainDiv" (click)="dueDatePicker.toggle()"
                            [ngbPopover]="filterTooltipDueDate" placement="top" [disablePopover]="!selectedDueDate"
                            popoverTitle="dueDate" triggers="mouseenter:mouseleave">
                            <div class="dateRangeTagView" *ngIf="selectedDueDate">Range</div>
                            <span *ngIf="filters['dueDate']" class="inputClear" (click)="dateClear('dueDateField')"
                                style="top: 50%; margin-top: -14px">×</span>
                        </div>
                        <input class="form-control ico-calendar" style="margin-top: -30px" placeholder="dd.mm.yy"
                            type="text" name="filterDueDate" id="filterDueDate" ngModel ngbDatepicker
                            #dueDatePicker="ngbDatepicker" autoClose="false" [placement]="'bottom-right'"
                            (dateSelect)="onDateSelection('dueDate', $event)" #dueDate outsideDays="hidden"
                            [dayTemplate]="dueDateTemplate" [startDate]="fromDate" [displayMonths]="2" [readonly]="true"
                            dateFormat="dd-MMM-yyyy" />

                        <ng-template #dueDateTemplate let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange('dueDate', date)"
                                [class.faded]="isHovered('dueDate', date) || isInside('dueDate', date)"
                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </th>

                    <th>
                        <ng-select [(ngModel)]="filters['status']" [clearable]="true" (clear)="filteredForm()"
                            (change)="filteredForm()" bindValue="id" bindLabel="label">
                            <ng-option *ngFor="let status of statuses" value="{{status['id']}}">
                                {{status['label']}}
                            </ng-option>
                        </ng-select>
                    </th>
                    <th width="10%">
                        <ng-select #csa [(ngModel)]="filters['csa']" class="form-control" (change)="filteredForm()">
                            <ng-option *ngFor="let csa of csas" value="{{csa['id']}}">
                                {{csa['displayName']}}
                            </ng-option>
                        </ng-select>
                    </th>
                </tr>
            </ng-template>

            <ng-template nrTemplate="body" let-payment>
                <tr class="font-small rowHover" (click)="openInspectPlan(payment)">
                    <td>
                        <div class="copy-area"
                             (click)="$event.stopPropagation(); $event.preventDefault(); copyText(payment['referenceNumber'])"
                             ngbTooltip="Copied!" placement="top" [closeDelay]="2000" triggers="click:mouseleave"
                        >
                            {{payment['referenceNumber']}}
                        </div>
                    </td>
                    <td class="text-end">
                        {{payment['totalAmount'] | currency: currency}}
                    </td>
                    <td>
                        {{payment['totalDiscount'] | currency: currency}}
                    </td>

                    <td class="text-center">
                        {{payment['latestDeadline'] | date: "dd MMM y"}}
                    </td>
                    <td class="text-center">
                        {{payment['latestDueDate'] | date: "dd MMM y"}}
                    </td>
                    <td>
                        <span class="badge" [ngClass]="payment?.statusId | StatusBadgePipe: StatusType.CustomerPayment">
                            {{payment['status']}}
                        </span>
                    </td>

                    <td>
                        <div class="name-badge name-badge-small badge-supervisor"
                            [ngbTooltip]="payment['csaDisplayName']">
                            {{ payment['csaDisplayName'] | initials }}
                        </div>
                    </td>
                </tr>
            </ng-template>
        </nr-grid>
        <!-- /table -->
    </kt-portlet-body>
</kt-portlet>