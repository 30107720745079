// Angular
import { query } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/services/messaging/messaging.service';
import { QueryParamsModel } from 'src/app/shared/base/models';

@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  // Show dot on top of the icon
  @Input() dot: string = '';

  // Show pulse on icon
  @Input() pulse: boolean = false;

  @Input() pulseLight: boolean = false;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success' = '';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean = false;

  // Set bg image path
  @Input() bgImage: string = '';

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() type: 'brand' | 'success' = 'success';

  freshMessages: any[] = [];
  messages: any[] = [];
  isViewingNew = true;

  query: QueryParamsModel = new QueryParamsModel([]);
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  constructor(
    private sanitizer: DomSanitizer,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    this.query.limit = 10;
    this.query.offset = 0;

    this.loadNotifications();
    this.freshMessages = this.messagingService.freshMessages;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  private loadNotifications(): void {
    const notifications$ = this.messagingService
      .getNewMessages(this.query)
      .pipe(
        tap((data) => {
          this.messages = data.notifications;
        })
      )
      .subscribe((_) => {});

    this.subscriptions.push(notifications$);
  }

  getTotalCount() {
    return this.messages?.length + this.freshMessages.length;
  }

  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }

    return 'url(' + this.bgImage + ')';
  }

  receiveMsg() {
    this.messagingService.receiveMessage();
  }

  reqPerm() {
    this.messagingService.requestPermission();
  }

  getToken() {
    this.messagingService.getToken();
  }

  getFromNow(value: string) {
    return moment(value).fromNow();
  }
}
