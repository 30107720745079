import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { environment as env } from 'src/environments/environment';
import { SearchResult, WriterCalendar, WriterSearchItem } from '../../models';
import { retryWhen, delay, take, map } from 'rxjs/operators';

const API: any = {
  all: 'all',
  writer: 'writer',
  writers: 'writers',
  statistics: 'writer-statistics',
  projects: 'projects',
  writerInfo: 'writer-info',
  calendar: 'calendar',
  favoritedBy: 'favorited-by',
  reports: 'reports',
  interestedWriters: 'interested-writers',
  notes: 'notes',
};

@Injectable({ providedIn: 'root' })
export class WritersService {
  activeProjectId: number | undefined;
  selectedWriters: WriterSearchItem[] = [];
  activeProjectTitle: string;
  activeProjectNumber: string;
  activeAgentsId: number;

  constructor(private readonly http: HttpClient) { }

  createWriter(data: any) {
    return this.http.post<any>(env.apiUrl + API.writers, data);
  }

  updateWriter(data: any, writerId: number) {
    return this.http.put<any>(`${env.apiUrl}${API.writers}/${writerId}`, data);
  }

  getWriterStatistics(writerId: number, coreAreaId: any = -1) {
    const url = `${env.apiUrl}${API.reports}/${API.statistics}/${writerId}`;
    if (coreAreaId < 0) {
      url.concat(url, `?CoreAreaId===${coreAreaId}`);
    }
    return this.http.get<any>(url);
  }

  getWriterProjects(writerId: number, query: QueryParamsModel | null) {
    if (!query) {
      query = new QueryParamsModel();
    }

    query.filter.push(`writers.id==${writerId}`);
    query.filter.push('statusId==6;11');

    return this.http.get<any>(
      `${env.apiUrl}${API.projects}${query.toUrlParams()}`
    );
  }

  getWriter(writerId: number) {
    return this.http.get<any>(`${env.apiUrl}${API.writers}/${writerId}`);
  }

  getWriterCalendar(
    writerId: number,
    startDate: string,
    endDate: string
  ): Observable<SearchResult<WriterCalendar>> {
    return this.http
      .get<SearchResult<WriterCalendar>>(
        `${env.apiUrl}${API.writers}/${writerId}/${API.calendar}?startDate=${startDate}&endDate=${endDate}`
      )
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(3))));
  }

  getWriterStats(writerId: number): Observable<WriterSearchItem> {
    const query = new QueryParamsModel();
    query.filter.push(`id==${writerId}`);

    return this.http
      .get<SearchResult<WriterSearchItem>>(
        env.apiUrl + API.writers + query.toUrlParams()
      )
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))))
      .pipe(
        map((result: SearchResult<WriterSearchItem>) => {
          return result.results[0];
        })
      );
  }

  addToProjectInterestedWriters(projectId: number, writerId: number) {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedWriters}/${writerId}`,
      1
    );
  }

  deleteInterestedWriters(projectId: number, writerId: number) {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedWriters}/${writerId}`
    );
  }

  getInterestedWriters(projectId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedWriters}`
    );
  }

  getWriterEducation(writerId: number) {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/educations`
    );
  }

  addWriterEducation(data: any, writerId: number) {
    return this.http.post<any>(
      `${env.apiUrl}${API.writers}/${writerId}/educations`,
      data
    );
  }

  editWriterEducation(data: any, writerId: number) {
    return this.http.put<any>(
      `${env.apiUrl}${API.writers}/${writerId}/educations/${data.educationId}`,
      data
    );
  }

  deleteWriterEducation(educationId: number, writerId: number) {
    return this.http.delete<any>(
      `${env.apiUrl}${API.writers}/${writerId}/educations/${educationId}`
    );
  }

  getWriterSearch(
    query: QueryParamsModel
  ): Observable<SearchResult<WriterSearchItem>> {
    return this.http
      .get<any>(env.apiUrl + API.writers + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getWriters() {
    return this.http.get<any>(`${env.apiUrl}${API.writers}?${API.all}=true`);
  }

  setActiveProject(
    projectId: number,
    projectModuleTitle: string,
    projectReferenceNumber: string
  ): void {
    this.activeProjectId = projectId;
    this.activeProjectTitle = projectModuleTitle;
    this.activeProjectNumber = projectReferenceNumber;
  }

  getActiveProject(): number {
    return this.activeProjectId ?? -1;
  }
  getActiveProjectTitle(): string {
    return this.activeProjectTitle;
  }
  getActiveProjectNumber(): string {
    return this.activeProjectNumber;
  }

  addSelectedWriter(writer: WriterSearchItem): void {
    if (writer) {
      const isWriter = this.selectedWriters.find((x) => x.id === writer.id);
      if (!isWriter) {
        writer.coreAreas.sort((a, b) =>
          b.score.toString().localeCompare(a.score.toString())
        );
        writer.skills.sort((a, b) =>
          b.score.toString().localeCompare(a.score.toString())
        );

        this.selectedWriters.push(writer);
      }
    }
  }

  removeSelectedWriter(writerId: number): void {
    this.selectedWriters = this.selectedWriters.filter(
      (x) => x.id !== writerId
    );
  }

  resetActiveProject() {
    this.activeProjectId = undefined;
    this.selectedWriters = [];
  }

  getPaymentCategory(writerId: number) {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.paymentCategory}`
    );
  }

  postPaymentCategory(writerId: number, paymentCategory: string) {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.paymentCategory}`
    );
  }

  addWriterToFavorites(writerId: number) {
    return this.http.post<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.favoritedBy}`,
      1
    );
  }

  removeWriterToFavorites(writerId: number) {
    return this.http.delete<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.favoritedBy}`
    );
  }

  listWriterFavorites(writerId: number) {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.favoritedBy}`
    );
  }

  listNotes(writerId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.notes}`
    );
  }

  addNotes(writerId: number, data: any) {
    return this.http.post<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.notes}`,
      data
    );
  }

  deleteNotes(writerId: number, noteId: number) {
    return this.http.delete<any>(
      `${env.apiUrl}${API.writers}/${writerId}/${API.notes}/${noteId}`
    );
  }
}
