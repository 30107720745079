<!-- NisloModal -->
<div id="NisloModal">
    <!-- header -->
    <div class="row mb-5">
        <div class="col-sm">
            <div class="pageSubHeader d-flex justify-content-between">
                <h3 class="page-title">Status History</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancelClick()">
                    <i class="fas fa-times" style="font-size: 14px;"></i>
                </button>
            </div>
        </div>
    </div>
    <!-- /header -->

    <!-- table-->
    <div class="row nislo-form">
        <div class="col-sm-12 form-group">
            <table class="nislo-datatable w-100 fixed_header" style="min-width: 100%!important; max-width: 100%!important;" >
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Transitioned By</th>
                        <th>Transitioned Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let status of history; let i = index;">
                        <td>
                            {{status['statusDisplayName']}}
                        </td>
                        <td>
                            {{status['transitionedByDisplayName']}}
                        </td>
                        <td>
                            {{status['transitionDate'] | date: 'dd/MM/y HH:mm:ss'}}
                        </td>
                        <td>
                            <button class="btn" *ngIf="status['hasDocument']" (click)="ViewQuote(collectionId)">
                                <i class="fa fa-link p-0"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 mt-3 mb-1 text-right px-0 pb-3">
        <button type="button" class="btn btn-white mr-3" data-dismiss="modal" (click)="onCancelClick()">
            Close
        </button>
    </div>
</div>