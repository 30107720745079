import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WidgetModule } from 'src/app/shared/content/widgets/widget.module';
import { PortletModule } from 'src/app/shared/content/general/portlet/portlet.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutModule } from 'src/app/shared/layout/layout.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, NgbModule, LayoutModule, SharedModule, WidgetModule, PortletModule],
  providers: []
})
export class DashboardModule {}
