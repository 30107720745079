<div id="NisloModal">
  <form [formGroup]="resetPasswordForm" *ngIf="this.panel !== 'success'">
    <!-- header -->
    <div class="row mb-4">
      <div class="col-sm">
        <div class="pageSubHeader">
          <h3 class="page-title">Reset Password</h3>
        </div>
      </div>
    </div>
    <!-- /header -->

    <!-- row -->
    <div class="row nislo-form">
      <div class="col-sm-12 form-group">
        <p class="gray-text f12 mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec
          enim non arcu interdum laoreet non in ligula.
        </p>
        <label>E-mail</label>
        <input
          type="text"
          placeholder="Email"
          class="form-control"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
        />

        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email is required</div>
          <div *ngIf="f['email'].errors['email']">
            Email must be a valid email address
          </div>
        </div>
      </div>
    </div>
    <!-- /row -->

    <!-- row -->
    <div class="text-right">
      <button
        type="button"
        class="btn btn-white mr-3"
        data-dismiss="modal"
        (click)="onCancelClick()"
      >
        Cancel
      </button>

      <button type="button" class="btn btn-blue" (click)="onSubmit()">
        Reset
      </button>
    </div>
    <!-- /row -->
  </form>

  <!-- row -->
  <div class="row nislo-form" *ngIf="this.panel === 'success'">
    <div class="col-sm mb-5">
      <div class="pageSubHeader">
        <h3 class="page-title">Check Email Address</h3>
      </div>
    </div>

    <div class="col-sm-12 form-group">
      <p class="gray-text f12 mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec enim
        non arcu interdum laoreet non in ligula.
      </p>
    </div>

    <div class="col-sm-12 text-right">
      <button
        type="button"
        class="btn btn-blue"
        data-dismiss="modal"
        (click)="onCancelClick()"
      >
        Okay
      </button>
    </div>
  </div>
  <!-- /row -->
</div>
