export class QueryParamsModel {
  page: string;
  constructor(
    public filter: any[] = [],
    public sortDirection: 'asc' | 'desc' = 'asc',
    public sortField: string = '',
    public offset: number = 0,
    public limit: number = 15,
    public all: boolean = false,
  ) { }

  toUrlParams(): string {


    this.page = `?offset=${this.offset}&limit=${this.limit}`;

    let filters = '';
    if (this.filter) {
      filters = `&filter=${this.filter.join(',')}`;
    }

    let sorting = '';
    if (this.sortField && this.sortField.length > 0) {
      sorting = `&sortField=${this.sortField}&sortDirection=${this.sortDirection}`;
    }

    const x =  this.all ? (filters.replace('&', '?') + sorting + '&all=true') : (this.page + filters + sorting) ;

    return x;
  }
}
