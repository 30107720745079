import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { Country } from '../../models/common/country.model';
import { LookupOption, ReasonLookupOption } from '../../models/common/lookup-option';
import {
  University,
  UniversityProgram
} from '../../models/common/university.model';

const API: any = {
  countries: 'lookups/countries',
  cancellationReasons: 'lookups/cancellation-reasons',
  extensionReasons: 'lookups/extension-reasons',
  feedbackTypes: 'lookups/feedback-types',
  grades: 'lookups/grades',
  levels: 'lookups/academic-levels',
  paymentTypes: 'lookups/payment-types',
  projectTypes: 'lookups/project-types',
  rejectReasons: 'lookups/reject-reasons',
  referencingGuide: 'lookups/referencing-guides',
  paymentCategories: 'lookups/payment-categories',
  orderStatuses: 'lookups/order-statuses',
  projectStatuses: 'lookups/project-statuses',
  writerDissmisReasons: 'lookups/dismiss-writer-reasons',
  skills: 'lookups/skills',
  areasFlat: 'lookups/areas/flat',
  mainAreas: 'lookups/areas/main-areas',
  transferReasons: 'lookups/transfer-reasons',
  universities: 'lookups/universities',
  universityPrograms: 'lookups/universities/0/programs',
  universityProgram: 'programs',
  specificClientNeeds: 'lookups/specific-client-needs',
  undoReasons: 'lookups/undo-reasons',
  areas: 'lookups/areas',
  availabilityTypes: 'lookups/availability-types',
  employmentStatus: 'lookups/employment-statuses',
  writerEarningTypes: 'lookups/writer-earning-types',
  educationDegrees: 'lookups/education-degrees',
  paymentChannels: 'lookups/payment-channels',
  paymentStatus: 'lookups/payment-statuses',
  withdrawalStatuses: 'lookups/withdrawal-statuses',
  activityTypes: 'lookups/activity-types',
  referenceTypes: 'lookups/reference-types',
  customerStatuses: 'lookups/customer-statuses',
  customerLostReasons: 'lookups/lost-reasons',
  customerNotConvertedReasons: 'lookups/not-converted-reasons',
  transferCsrReason: 'lookups/transfer-csa-reasons',
  customerSources: 'lookups/customer-sources',
  additionalCostType: 'lookups/additional-cost-types',
  collectionMethods: 'lookups/collection-methods',
  collectionPlanStatuses: 'lookups/collection-plan-statuses',
  collectionStatuses: 'lookups/collection-statuses',
};

@Injectable({ providedIn: 'root' })
export class LookupService {
  private cancellationReasons: LookupOption[];
  private countries: Country[];
  private extensionReasons: LookupOption[];
  private feedbackTypes: LookupOption[];
  private grades: LookupOption[];
  private levels: LookupOption[];
  private paymentTypes: LookupOption[];
  private paymentCategories: any[];
  private projectTypes: LookupOption[];
  private projectStatuses: LookupOption[];
  private writerDissmisReasons: LookupOption[];
  private writerEarningTypes: LookupOption[];
  private orderStatuses: LookupOption[];
  private programs: UniversityProgram[];
  private rejectReasons: LookupOption[];
  private referencingGuide: any[];
  private skills: any[];
  private transferReasons: LookupOption[];
  private universities: University[] = [];
  private undoReasons: LookupOption[];
  private areas: any[];
  private mainAreas: any[];
  private areasFlat: any[];
  private specificClientNeeds: LookupOption[];
  private availabilityTypes: LookupOption[];
  private coreAreas: any[];
  private coreAreasFlat: any[];
  private employmentStatus: any[];
  private educationDegrees: any[];
  private paymentChannels: any[];
  private paymentStatus: LookupOption[];
  private withdrawalStatuses: LookupOption[];
  private activityTypes: LookupOption[];
  private referenceTypes: LookupOption[];
  private customerStatuses: LookupOption[];
  private customerLostReasons: ReasonLookupOption[];
  private customerNotConvertedReasons: ReasonLookupOption[];
  private transferCsrReason: ReasonLookupOption[];
  private customerSources: LookupOption[];
  private additionalCostType: LookupOption[];

  constructor(private readonly http: HttpClient) { }

  getFeedbackTypesLookup(): Observable<LookupOption[]> {
    if (this.feedbackTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.feedbackTypes)
        .pipe(tap((values) => (this.feedbackTypes = values)));
    } else {
      return of(this.feedbackTypes);
    }
  }

  getAreas(): Observable<any[]> {
    if (this.areas === undefined) {
      return this.http.get<any[]>(env.apiUrl + API.areas).pipe(
        tap((values) => {
          this.areas = values;
        })
      );
    } else {
      return of(this.areas);
    }
  }

  getAreasFlat(): Observable<any[]> {
    if (this.areasFlat === undefined) {
      return this.http.get<any[]>(env.apiUrl + API.areasFlat).pipe(
        tap((values) => {
          this.areasFlat = values;
        })
      );
    } else {
      return of(this.areasFlat);
    }
  }

  getMainAreas(): Observable<any[]> {
    if (this.mainAreas === undefined) {
      return this.http.get<any[]>(env.apiUrl + API.mainAreas).pipe(
        tap((values) => {
          this.mainAreas = values;
        })
      );
    } else {
      return of(this.mainAreas);
    }
  }

  getAvailabilityTypes(): Observable<LookupOption[]> {
    if (this.availabilityTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.availabilityTypes)
        .pipe(tap((values) => (this.availabilityTypes = values)));
    } else {
      return of(this.availabilityTypes);
    }
  }

  getCoreAreas(): Observable<any[]> {
    if (this.coreAreas === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.areas)
        .pipe(tap((values) => (this.coreAreas = values)));
    } else {
      return of(this.coreAreas);
    }
  }

  getEmploymentStatus(): Observable<any[]> {
    if (this.employmentStatus === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.employmentStatus)
        .pipe(tap((values) => (this.employmentStatus = values)));
    } else {
      return of(this.skills);
    }
  }

  getSkills(): Observable<LookupOption[]> {
    if (this.skills === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.skills)
        .pipe(tap((values) => (this.skills = values)));
    } else {
      return of(this.skills);
    }
  }

  getPaymentChannels(): Observable<any[]> {
    if (this.paymentChannels === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.paymentChannels)
        .pipe(tap((values) => (this.paymentChannels = values)));
    } else {
      return of(this.paymentChannels);
    }
  }

  getPaymentStatus(): Observable<LookupOption[]> {
    if (this.paymentStatus === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.paymentStatus)
        .pipe(tap((values) => (this.paymentStatus = values)));
    } else {
      return of(this.paymentStatus);
    }
  }

  getPaymentCategories(): Observable<any[]> {
    if (this.paymentCategories === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.paymentCategories)
        .pipe(tap((values) => (this.paymentCategories = values)));
    } else {
      return of(this.paymentCategories);
    }
  }

  getCancellationReasons(): Observable<LookupOption[]> {
    if (this.cancellationReasons === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.cancellationReasons)
        .pipe(tap((values) => (this.cancellationReasons = values)));
    } else {
      return of(this.cancellationReasons);
    }
  }

  getGrades(): Observable<LookupOption[]> {
    if (this.grades === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.grades)
        .pipe(tap((values) => (this.grades = values)));
    } else {
      return of(this.grades);
    }
  }

  getLevels(): Observable<LookupOption[]> {
    if (this.levels === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.levels)
        .pipe(tap((values) => (this.levels = values)));
    } else {
      return of(this.levels);
    }
  }

  getProjectTypes(): Observable<LookupOption[]> {
    if (this.projectTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.projectTypes)
        .pipe(tap((values) => (this.projectTypes = values)));
    } else {
      return of(this.projectTypes);
    }
  }

  getProjectStatuses(): Observable<LookupOption[]> {
    if (this.projectStatuses === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.projectStatuses)
        .pipe(tap((values) => (this.projectStatuses = values)));
    } else {
      return of(this.projectStatuses);
    }
  }

  getDissmisWriterReasons(): Observable<LookupOption[]> {
    if (this.writerDissmisReasons === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.writerDissmisReasons)
        .pipe(tap((values) => (this.writerDissmisReasons = values)));
    } else {
      return of(this.writerDissmisReasons);
    }
  }

  getWriterEarningTypes(): Observable<LookupOption[]> {
    if (this.writerEarningTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.writerEarningTypes)
        .pipe(tap((values) => (this.writerEarningTypes = values)));
    } else {
      return of(this.writerEarningTypes);
    }
  }

  getPaymentTypes(): Observable<LookupOption[]> {
    if (this.paymentTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.paymentTypes)
        .pipe(tap((values) => (this.paymentTypes = values)));
    } else {
      return of(this.paymentTypes);
    }
  }

  getExtensionReasons(): Observable<LookupOption[]> {
    if (this.extensionReasons === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.extensionReasons)
        .pipe(tap((values) => (this.extensionReasons = values)));
    } else {
      return of(this.extensionReasons);
    }
  }

  getRejectReasons(): Observable<LookupOption[]> {
    if (this.rejectReasons === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.rejectReasons)
        .pipe(tap((values) => (this.rejectReasons = values)));
    } else {
      return of(this.rejectReasons);
    }
  }

  getUndoReasons(): Observable<LookupOption[]> {
    if (this.undoReasons === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.undoReasons)
        .pipe(tap((values) => (this.undoReasons = values)));
    } else {
      return of(this.undoReasons);
    }
  }

  getReferencingGuide(): Observable<any[]> {
    if (this.referencingGuide === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.referencingGuide)
        .pipe(tap((values) => (this.referencingGuide = values)));
    } else {
      return of(this.referencingGuide);
    }
  }

  getUniversityPrograms(): Observable<UniversityProgram[]> {
    if (this.programs === undefined) {
      return this.http
        .get<UniversityProgram[]>(env.apiUrl + API.universityPrograms)
        .pipe(tap((values) => (this.programs = values)));
    } else {
      return of(this.programs);
    }
  }

  getUniversities(): Observable<University[]> {
    if (this.universities === undefined) {
      return this.http
        .get<University[]>(env.apiUrl + API.universities)
        .pipe(tap((values) => (this.universities = values)));
    } else {
      return of(this.universities);
    }
  }

  getCountries(): Observable<Country[]> {
    if (this.countries === undefined) {
      return this.http
        .get<Country[]>(env.apiUrl + API.countries)
        .pipe(tap((values) => (this.countries = values)));
    } else {
      return of(this.countries);
    }
  }

  getSpecificClientNeeds(): Observable<LookupOption[]> {
    if (this.specificClientNeeds === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.specificClientNeeds)
        .pipe(tap((values) => (this.specificClientNeeds = values)));
    } else {
      return of(this.specificClientNeeds);
    }
  }

  getCountryText(countryId: number) {
    return this.http.get<any>(env.apiUrl + API.countries + '/' + countryId);
  }
  getUniversityText(universityId: number): string {
    let universityText = '';
    if (!this.universities) {
      const university = this.universities.find(
        (item: any) => item.id === universityId
      );
      universityText = university ? university.name : '';
    }
    return universityText;
  }

  getUniversityProgramText(programId: number): string {
    let programText = '';
    if (!this.programs) {
      const program = this.programs.find((item: any) => item.id === programId);
      programText = !program ? program.name : '';
    }
    return programText;
  }

  getRoles(): any[] {
    return [
      {
        id: 1,
        value: '1',
        label: 'Admin',
        ordinal: 1,
        isDefault: false,
      },
      {
        id: 2,
        value: '2',
        label: 'Supervisor',
        ordinal: 2,
        isDefault: false,
      },
      {
        id: 3,
        value: '3',
        label: 'CSA',
        ordinal: 3,
        isDefault: false,
      },
      // {
      //   id: 4,
      //   value: '4',
      //   label: 'Writer',
      //   ordinal: 4,
      //   isDefault: false
      // },
      // {
      //   id: 5,
      //   value: '5',
      //   label: 'QA',
      //   ordinal: 5,
      //   isDefault: false
      // },
      {
        id: 7,
        value: '7',
        label: 'HR',
        ordinal: 7,
        isDefault: false,
      },
      {
        id: 8,
        value: '8',
        label: 'Finance',
        ordinal: 8,
        isDefault: false,
      },
      {
        id: 11,
        value: '11',
        label: 'Supervisor Allocator',
        ordinal: 11,
        isDefault: false,
      },
    ];
  }


  addUniversity(dtoUniversity: any) {
    return this.http.post<any>(env.apiUrl + API.universities, dtoUniversity);
  }

  addUniversityProgram(universityId: number, dtoProgram: any) {
    return this.http.post<any>(
      `${env.apiUrl}${API.universities}/${universityId}/${API.universityProgram}`,
      dtoProgram
    );
  }

  getEducationDegrees() {
    if (this.educationDegrees === undefined) {
      return this.http
        .get<any[]>(env.apiUrl + API.educationDegrees)
        .pipe(tap((values) => (this.educationDegrees = values)));
    } else {
      return of(this.educationDegrees);
    }
  }

  getTransferReasons(userRole: string): Observable<LookupOption[]> {
    if (this.transferReasons === undefined) {
      return this.http
        .get<LookupOption[]>(
          `${env.apiUrl}${API.transferReasons
          }?userRole=${this.convertUserRoleToId(userRole)}`
        )
        .pipe(tap((values) => (this.transferReasons = values)));
    } else {
      return of(this.transferReasons);
    }
  }

  convertUserRoleToId(userRole: string) {
    switch (userRole) {
      case 'Admin':
        return 1;
      case 'Supervisor':
        return 2;
      case 'CSA':
        return 3;
      case 'Writer':
        return 4;
      case 'QA':
        return 5;
      case 'HR':
        return 7;
      case 'SupervisorAllocator':
        return 11;
      default:
        return 0;
    }
  }

  getWithdrawalStatuses(): Observable<LookupOption[]> {
    if (this.withdrawalStatuses === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.withdrawalStatuses)
        .pipe(tap((values) => (this.withdrawalStatuses = values)));
    } else {
      return of(this.withdrawalStatuses);
    }
  }

  getActivityTypes(): Observable<LookupOption[]> {
    if (this.activityTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.activityTypes)
        .pipe(tap((values) => (this.activityTypes = values)));
    } else {
      return of(this.activityTypes);
    }
  }

  getReferenceTypes(): Observable<LookupOption[]> {
    if (this.referenceTypes === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.referenceTypes)
        .pipe(tap((values) => (this.referenceTypes = values)));
    } else {
      return of(this.referenceTypes);
    }
  }

  getCustomerStatuses(): Observable<LookupOption[]> {
    if (this.customerStatuses === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.customerStatuses)
        .pipe(tap((values) => (this.customerStatuses = values)));
    } else {
      return of(this.customerStatuses);
    }

  }

  getCustomerNotConvertedReasons(): Observable<ReasonLookupOption[]> {
    if (this.customerNotConvertedReasons === undefined) {
      return this.http
        .get<ReasonLookupOption[]>(env.apiUrl + API.customerNotConvertedReasons)
        .pipe(tap((values) => (this.customerNotConvertedReasons = values)));
    } else {
      return of(this.customerNotConvertedReasons);
    }
  }

  getCustomerLostReasons(): Observable<ReasonLookupOption[]> {
    if (this.customerLostReasons === undefined) {
      return this.http
        .get<ReasonLookupOption[]>(env.apiUrl + API.customerLostReasons)
        .pipe(tap((values) => (this.customerLostReasons = values)));
    } else {
      return of(this.customerLostReasons);
    }
  }


  getTransferCsrReason(): Observable<ReasonLookupOption[]> {
    if (this.transferCsrReason === undefined) {
      return this.http
        .get<ReasonLookupOption[]>(env.apiUrl + API.transferCsrReason)
        .pipe(tap((values) => (this.transferCsrReason = values)));
    } else {
      return of(this.transferCsrReason);
    }
  }

  getCustomerSources(): Observable<LookupOption[]> {
    if (this.customerSources === undefined) {
      return this.http
        .get<LookupOption[]>(env.apiUrl + API.customerSources)
        .pipe(tap((values) => (this.customerSources = values)));
    } else {
      return of(this.customerSources);
    }
  }

  getAdditionalCostTypes(): Observable<LookupOption[]> {
    if (this.additionalCostType === undefined) {
      return this.http
          .get<LookupOption[]>(env.apiUrl + API.additionalCostType)
          .pipe(tap((values) => (this.additionalCostType = values)));
    } else {
      return of(this.additionalCostType);
    }
  }
  getAllAdditionalTypes(): Observable<any> {
    return this.http.get<any>(
        env.apiUrl + API.additionalCostType
    );
  }

  getCollectionMethods(): Observable<any> {
    return this.http.get<any>(`${env.apiUrl}${API.collectionMethods}`);
  }

  getCollectionPlanStatuses(): Observable<any> {
    return this.http.get<any>(
        `${env.apiUrl}${API.collectionPlanStatuses}`
    );
  }

  getCollectionStatuses(): Observable<any> {
    return this.http.get<any>(
        `${env.apiUrl}${API.collectionStatuses}`
    )
  }
}
