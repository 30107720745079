<div class="row">
  <div class="col-xl-12">
    <div class="row row-full-height">
      <div class="col-lg-6 col-xl-3">
        <kt-portlet [class]="'kt-portlet--border-bottom-brand'">
          <kt-portlet-body [class]="'kt-portlet__body--fluid'">
            <kt-widget26
              value="£{{ totalEarnings }}"
              desc="Earnings"
              [options]="chartOptions1"
            ></kt-widget26>
          </kt-portlet-body>
        </kt-portlet>
      </div>

      <div class="col-lg-6 col-xl-3">
        <kt-portlet [class]="'kt-portlet--border-bottom-brand'">
          <kt-portlet-body [class]="'kt-portlet__body--fluid'">
            <kt-widget26
              [value]="totalCompleted"
              desc="Completed"
              [options]="chartOptions2"
            ></kt-widget26>
          </kt-portlet-body>
        </kt-portlet>
      </div>

      <div class="col-lg-6 col-xl-3">
        <kt-portlet [class]="'kt-portlet--border-bottom-brand'">
          <kt-portlet-body [class]="'kt-portlet__body--fluid'">
            <kt-widget26
              value="1"
              desc="Awaiting Response"
              [options]="chartOptions3"
            ></kt-widget26>
          </kt-portlet-body>
        </kt-portlet>
      </div>

      <div class="col-lg-6 col-xl-3">
        <kt-portlet [class]="'kt-portlet--border-bottom-brand'">
          <kt-portlet-body [class]="'kt-portlet__body--fluid'">
            <kt-widget26
              value="£0"
              desc="Paid Comissions"
              [options]="chartOptions4"
            ></kt-widget26>
          </kt-portlet-body>
        </kt-portlet>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-8">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-header
        title="Active Orders"
        [class]="
          'kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm'
        "
      >
      </kt-portlet-header>
      <kt-portlet-body [class]="'kt-portlet__body--fit'">
        <kt-data-table></kt-data-table>
      </kt-portlet-body>
    </kt-portlet>
  </div>

  <div class="col-xl-4">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-header title="Completed Orders"> </kt-portlet-header>
      <kt-portlet-body>
        <kt-widget4 [data]="widget4">
          <ng-template #actionTemplate let-item="item">
            <span class="kt-widget4__number" [ngClass]="item.valueColor">{{
              item.value
            }}</span>
          </ng-template>
        </kt-widget4>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>

<div class="row">
  <div class="col-xl-8">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-header title="Knowledge Base"> </kt-portlet-header>
      <kt-portlet-body>
        <kt-widget4 [data]="knowledgeBase">
          <ng-template #actionTemplate let-item="item">
            <div class="kt-widget4__tools">
              <a href="javascript:;" class="btn btn-clean btn-icon btn-sm">
                <i
                  class="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"
                ></i>
              </a>
            </div>
          </ng-template>
        </kt-widget4>
      </kt-portlet-body>
    </kt-portlet>
  </div>

  <div class="col-xl-4">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-header title="Learning Materials"> </kt-portlet-header>
      <kt-portlet-body>
        <kt-widget4 [data]="learningMaterials">
          <ng-template #actionTemplate let-item="item">
            <span class="kt-widget4__number" [ngClass]="item.valueColor">{{
              item.value
            }}</span>
          </ng-template>
        </kt-widget4>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
