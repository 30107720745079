// Angular
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import { Breadcrumb, SubheaderService } from 'src/app/shared/base/services';
// Layout

@Component({
  selector: 'kt-subheader3',
  templateUrl: './subheader3.component.html',
  styleUrls: ['./subheader3.component.scss'],
})
export class Subheader3Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  today: number = Date.now();
  title = '';
  desc: string | undefined = '';
  breadcrumbs: Breadcrumb[] = [];

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(public subheaderService: SubheaderService) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {}

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
          });
        }
      })
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
