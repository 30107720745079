<div id="NisloModal" class="modal-medium">

  <div class="row mb-5">
    <div class="col-sm-12">
      <!-- header -->
      <div class="pageSubHeader mb-5">
        <h3 class="page-title">Confirmation</h3>
      </div>
      <!-- /header -->
    </div>
    <div class="col-sm-12 border-top border-bottom py-5 content-text">
      <p class="my-0"> {{ this.data.dialogMsg }}</p>
    </div>
  </div>
  <!-- /row -->

  <!-- row -->
  <div class="d-flex justify-content-end">
    <div>
      <button type="button" class="btn btn-white mr-3" data-dismiss="modal" (click)="onCancelClick()">
        Cancel
      </button>
      <button class="btn btn-blue" [mat-dialog-close]="true" cdkFocusInitial>
        Okay
      </button>
    </div>
  </div>
  <!-- /row -->
</div>