import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services';
import { ErrorService } from 'src/app/core/services/error/error.services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;

  panel: any;
  // Subscriptions
  private subscriptions: Subscription[] = [];
  subscription: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly errorService: ErrorService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {
    this.resetPasswordForm = this._resetPasswordForm();
  }

  ngOnInit() { }

  private _resetPasswordForm() {
    return this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }
    const request = {
      email: this.resetPasswordForm.controls['email'].value,
    };

    this.authService.resetPassword(request).subscribe({
      next: (result) => {
        // this.dialogRef.close();
        this.panel = 'success';
      },
      error: (error) => {
        this.errorService.showGenericErrorMessage(error);
      }
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
