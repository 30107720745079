import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import {
  Project,
  ProjectStatus,
  StatusType,
  User
} from 'src/app/core/models';
import {
  AuthService,
  LocalStorageService,
  LookupService,
  ProjectService,
  SalesService
} from 'src/app/core/services';
import { ErrorService } from 'src/app/core/services/error/error.services';
import { QueryParamsModel } from 'src/app/shared/base/models';
import {environment} from "../../../../../../environments/environment";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  AddProjectCostComponent
} from "../../../../project/pages/project-details/price-management/add-project-cost/add-project-cost.component";
import {ToastrService} from "ngx-toastr";
import {PlanHistoryComponent} from "./plan-history/plan-history.component";

@Component({
  selector: 'app-add-payment-plan',
  templateUrl: './add-payment-plan.component.html',
  styleUrls: ['./add-payment-plan.component.scss']
})

export class AddPaymentPlanComponent implements OnInit {
  submitted = false;
  StatusType = StatusType;
  query: QueryParamsModel = new QueryParamsModel([]);
  loading = true;
  totalRecords = 0;

  loginUserValue: User;

  orders: Project[];
  selectedProjects: any[] = [];
  selectedProjectsId: any[] = [];
  projectSelected = true;
  changeTypeCount = 0;
  priceCalcCount = 0;
  public totalPrice = 0;
  public totalFormPrice = 0;
  public totalDiscount = 0;
  public paymentItemsTotal = 0;
  public difference = 0;
  public rowPrice = 0;
  public rowLen = 1;
  public defaultPrice: number[] = []

  totalPaidPrice: number;

  pageWidth: number;
  bundleNumber = 3;
  collectionStatus: string;

  customerNo: string;

  get projectStatus() {
    return ProjectStatus;
  }

  currency = environment.currency;


  constructor(
      private readonly lookupService: LookupService,
      private readonly projectService: ProjectService,
      private readonly salesService: SalesService,
      private readonly changeDetectorRef: ChangeDetectorRef,
      private readonly authService: AuthService,
      private readonly errorService: ErrorService,
      private readonly router: Router,
      private readonly localStorage: LocalStorageService,
      public formatter: NgbDateParserFormatter,
      private parserFormatter: NgbDateParserFormatter,
      private route: ActivatedRoute,
      private titleService: Title,
      public dialogRef: MatDialogRef<AddPaymentPlanComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA)
      public data: any,
      private readonly toastrService: ToastrService,
  ) {
    this.loginUserValue = this.authService.currentUserValue;

    let pageSize = 15;
    if (
        (Number(
            this.localStorage.getItem(
                this.route.snapshot.data['title'] + '_PageSize'
            )
        ) ?? 0) > 0
    ) {
      pageSize =
          Number(
              this.localStorage.getItem(
                  this.route.snapshot.data['title'] + '_PageSize'
              )
          ) ?? 0;
    }
    this.query = new QueryParamsModel([], 'desc', '', 0, pageSize);

    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.customerNo = this.data.customer.id;

  }

  ngOnInit() {
    this.pageWidth = window.innerWidth;
    if (this.pageWidth <= 1500) {
      this.bundleNumber = 2;
    } else {
      this.bundleNumber = 3;
    }

    if (!this.query.sortField) {
      this.query.sortField = 'id';
      this.query.sortDirection = 'desc';
    }
    this.getProjects(this.query)
  }

  getTotalPaidPrice(val) {
    this.totalPaidPrice = val
  }

  calcPrice() {
    this.difference = 0;
    this.totalFormPrice = 0;
    this.defaultPrice.map(x => (
        this.totalFormPrice += x
    ))
    this.difference = this.totalPrice - this.totalFormPrice;
  }

  getFormPrice(price) {
    this.totalFormPrice = price
  }

  calcViewTotalPrice(plans) {
    this.totalFormPrice = 0;
    plans.map((plan, i) =>{
      this.totalFormPrice += plan['amount']
    })
    this.calcPrice();
  }

  getProjects(query) {
    if (this.data['type'] !== 'view')
    {
      this.query['filter'][0] = `customer.id==${this.customerNo.toString()},statusId==3;4;5;6;11;12;14,collectionPlanId==0`
    }
    else if (this.data['type'] === 'view')
    {
      this.query['filter'][0] = `collectionPlanId==${this.data['planId']}`
    }

    this.query.all = true
    this.loading = true;
    let types;
    this.lookupService.getProjectTypes().subscribe((result) => {
      types = result;
    });

    if (this.data['type'] === 'edit')
    {
      this.query['filter'][0] = `customer.id==${this.customerNo.toString()},statusId==3;4;5;6;11;12;14,collectionPlanId==0`
    }

    const searchSubscription = this.projectService
        .getNewProjects(query)
        .pipe(
            tap((data) => {
              this.totalRecords = data.total;
              this.query.offset = data.page;
              this.orders = data.results;

              if (this.data['type'] !== 'edit')
              {
                this.selectedProjectsId = [];
                this.selectedProjects = this.orders
                this.selectedProjectsId.push(this.selectedProjects.map(project => (
                    project.id
                )))
              }
              this.totalPrice = this.totalDiscount = 0;
              this.selectedProjects.map(p => {
                p.givenPrice ? this.totalPrice += parseInt(p.givenPrice) : null;
                p.discount ? this.totalDiscount += parseInt(p.discount) : null;
              })
              this.paymentItemsTotal = this.totalPrice
              if (this.priceCalcCount === 0){
                this.defaultPrice[0] = this.totalPrice
                this.priceCalcCount++
              }

              this.data.type === 'create' ? this.totalFormPrice = this.totalPrice : null
              if (this.data['type'] === 'edit')
              {
                this.query['filter'][0] = `collectionPlanId==${this.data['planId']}`
                this.projectService
                    .getNewProjects(query)
                    .subscribe( {
                          next: (value) => {
                            this.totalRecords += value.total;
                            value.results.map(row  => (
                                this.orders.push(row)
                            ))
                          }
                        }
                    )
                this.changeTypeCount++
              }
              this.calcPrice();
              this.orders.map((project) => {
                if (types) {
                  project.projectTypeObj = types.filter(
                      (x: any) => x.id === project.projectTypeId
                  )[0];
                }
              });
            })
        )
        .subscribe(() => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        });
  }

  closeForm(x) {
    this.onCancelClick();
  }

  onCancelClick(): void {
    this.dialogRef.close();
    this.loading = false;
    this.submitted = false;
  }

  flexibleRowLen(len) {
    this.rowLen = len
  }

  changeType(val) {
    this.data['type'] = val
    this.getProjects(this.query)
  }

  changeDifference(val) {
    this.difference = val
  }

  paymentFormTotal(formTotalPrice) {
    this.paymentItemsTotal = formTotalPrice
    this.difference = this.totalPrice - this.paymentItemsTotal
  }

  addSelectedProject(value) {
    this.selectedProjectsId = [];
    this.difference = 0;
    this.totalPrice = 0;
    this.totalDiscount = 0;

    this.orders.find(project => (
        project.id == value && this.selectedProjects.push(project)
    ))

    this.projectSelected = true
    this.selectedProjects.map(p => {
        p.givenPrice ? this.totalPrice += parseInt(p.givenPrice) : null;
        p.discount ? this.totalDiscount += parseInt(p.discount) : null;
  })

    this.rowPrice = this.totalPrice / this.rowLen

    this.selectedProjectsId.push(this.selectedProjects.map(project => (
        project.id
    )))

    if (this.data['type'] === 'create')
    {
      for (let i = 0; i < this.rowLen; i++) {
          this.defaultPrice[i] = this.rowPrice
      }
    }
    else {
      this.defaultPrice[this.defaultPrice.length] = 0;
    }
  }

  getCollectionStatus(val) {
    this.collectionStatus = val
  }

  removeSelectedProject(value) {
    this.selectedProjectsId = [];
    this.difference = 0;
    this.totalPrice = 0;
    this.totalDiscount = 0;

    this.selectedProjects = this.selectedProjects.filter((project) => {
      return project.id != value
    })

    this.selectedProjects.map(p => {
      p.givenPrice ? this.totalPrice += parseInt(p.givenPrice) : null;
      p.discount ? this.totalDiscount += parseInt(p.discount) : null;
    })

    this.rowPrice = this.totalPrice / this.rowLen

    this.selectedProjectsId.push(this.selectedProjects.map(project => (
        project.id
    )))

    if (this.data['type'] === 'create')
    {
      for (let i = 0; i < this.rowLen; i++) {
        this.defaultPrice[i] = this.rowPrice
      }
    }
    else {
      this.defaultPrice[this.defaultPrice.length] = 0;
    }
  }

  selectAll(select) {
    if (select) {
      this.selectedProjectsId = [];
      this.selectedProjects = [];
      this.difference = 0;
      this.totalPrice = 0;
      this.totalDiscount = 0;

      this.orders.map(project => (
          this.selectedProjects.push(project)
      ))

      this.projectSelected = true
      this.selectedProjects.map(p => {
        p.givenPrice ? this.totalPrice += parseInt(p.givenPrice) : null;
        p.discount ? this.totalDiscount += parseInt(p.discount) : null;
      })

      this.rowPrice = this.totalPrice / this.rowLen

      this.selectedProjectsId.push(this.selectedProjects.map(project => (
          project.id
      )))

      if (this.data['type'] === 'create')
      {
        for (let i = 0; i < this.rowLen; i++) {
          this.defaultPrice[i] = this.rowPrice
        }
      }
      else {
        this.defaultPrice[this.defaultPrice.length] = 0;
      }
    }
    else {
      this.selectedProjectsId = [];
      this.selectedProjects = [];
      this.difference = 0;
      this.totalPrice = 0;
      this.totalDiscount = 0;

      this.selectedProjects.map(p => {
        p.givenPrice ? this.totalPrice += parseInt(p.givenPrice) : null;
        p.discount ? this.totalDiscount += parseInt(p.discount) : null;
      })

      this.rowPrice = this.totalPrice / this.rowLen

      this.selectedProjectsId.push(this.selectedProjects.map(project => (
          project.id
      )))

      if (this.data['type'] === 'create')
      {
        for (let i = 0; i < this.rowLen; i++) {
          this.defaultPrice[i] = this.rowPrice
        }
      }
      else {
        this.defaultPrice[this.defaultPrice.length] = 0;
      }
    }
  }

  openPaymentDialog(projectId, wordCount, academicLevel, projectType,projectTitle) {
    const dialogRef = this.dialog.open(AddProjectCostComponent, {
      disableClose: true,
      data: {
        projectId: projectId,
        baseWordCount: wordCount,
        academicLevelId: academicLevel,
        projectTypeId: projectType,
        projectTitle:projectTitle,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (_) => {
        this.loading = true
        setTimeout(() => {
          this.getProjects(this.query)
        }, 1000)
      },
      error: (error) => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  openPlanHistory() {
    const dialogRef = this.dialog.open(PlanHistoryComponent, {
      disableClose: true,
      data: {
        collectionPlanId: this.data.planId,
      }
    })
  }

}
