<div class="_scroll-table table-wrapper table-responsive">
    <table [ngClass]="type != 'view' ? 'table table-borderless': 'nislo-datatable nislo-table-scroll hoverable-rows'">
        <thead>
            <tr>
                <th class="text-end">#</th>
                <th>Method</th>
                <th>Due Date</th>
                <th [ngClass]="{'text-end': type === 'view', 'text-center': type !== 'view'}">Amount</th>
                <th *ngIf="type !== 'create'" colspan="2">Status</th>
                <th *ngIf="diff != 0"></th>
            </tr>
        </thead>
        <tbody *ngIf="type !== 'view'">
            <tr *ngFor="let plan of plans; let i = index;">
                <!--                Payment Type -->
                <td class="text-end tdItem" style="width: 1px">
                    {{i + 1}}
                    <!-- index starting 0 -->
                </td>
                <!-- Payment Method -->
                <td class="_w-130">
                    <div *ngIf="plan['status'] !== 'Paid'">
                        <select class="form-control form-select"
                                [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['paymentMethod'] }"
                                [(ngModel)]="plan['collectionMethodId']">
                            <option *ngFor="let method of collectionMethods" value="{{method['id']}}">
                                {{method['label']}}
                            </option>

                        </select>
                        <div *ngIf="submitted && validations[i] && !validations[i]['paymentMethod']"
                             class="invalid-feedback">
                            You can't leave this blank
                        </div>
                    </div>
                    <div *ngIf="plan['status'] === 'Paid'">
                        {{plan['methodDisplayText']}}
                    </div>
                </td>
                <!--                Payment Date -->
                <td class="_w-130">
                    <div *ngIf="plan['status'] !== 'Paid'">
                        <input class="form-control ico-calendar _readonlyMode" type="text" name="date" ngbDatepicker
                               #d="ngbDatepicker" [placement]="'left'" (click)="d.toggle()" autocomplete="off"
                               [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['paymentDate'] }"
                               placeholder="dd mm YY" [(ngModel)]="plan['jsonDate']" readonly />
                        <div *ngIf="submitted && validations[i] && !validations[i]['paymentDate']" class="invalid-feedback">
                            You can't leave this blank
                        </div>
                    </div>
                    <div *ngIf="plan['status'] === 'Paid'">
                        {{plan['dueDate'] | date: "dd MMM yy"}}

                    </div>
                </td>
                <!--                Price -->
                <td class="_w-130 text-end">
                    <div *ngIf="plan['status'] !== 'Paid'" class="input-group mb-3">
                        <input type="number" placeholder="Price" class="form-control text-end" (input)="totalPriceValue()"
                            [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['price'] }"
                            [(ngModel)]="defaultPrice[i]"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">
                                {{ 0 | currency: currency | slice: 0:1 }}
                            </span>
                        </div>
                        <div *ngIf="submitted && validations[i] && !validations[i]['price']" class="invalid-feedback">
                            You can't leave this blank
                        </div>
                    </div>
                    <div *ngIf="plan['status'] === 'Paid'">
                        {{plan['amount'] | currency: currency}}
                    </div>
                </td>
                <!--               Status -->
                <td>
                    {{plan['status']}}
                </td>
                <!--                Buttons -->
                <td *ngIf="diff != 0">
                    <div *ngIf="plan['status'] !== 'Paid'" class="text-start">
                        <button class="btn btn-outline-primary" *ngIf="diff > 0" (click)="addPrice(i)">
                            <i class="fa fa-plus p-0"></i>
                        </button>
                        <button class="btn btn-outline-primary" *ngIf="diff < 0" (click)="addPrice(i)">
                            <i class="fa fa-minus p-0"></i>
                        </button>
                    </div>
                </td>
                <td *ngIf="i > 0 && plan['status'] !== 'Paid'" class="text-end" [ngClass]="{'ps-4': diff == 0}">
                    <button class="btn btn-outline-danger" (click)="removeForm(i)">
                        <i class="fa fa-trash-alt px-0"></i>
                    </button>
                </td>
            </tr>
        </tbody>

        <tbody *ngIf="type === 'view'">
            <tr *ngFor="let plan of plans; let i = index;">
                <td class="text-end tdItem align-middle" style="width: 1px">
                    {{plan['referenceNumber'] | uppercase }}
                </td>

                <td class="align-middle">
                    {{plan['methodDisplayText']}}
                </td>

                <td class="align-middle">
                    {{plan['dueDate'] | date: "dd MMM yy"}}
                </td>

                <td class="text-end">
                    {{plan['amount'] | currency: currency}}
                </td>

                <td class="align-middle">
                    {{plan['status']}}
                    <span *ngIf="plan['status'] == 'Paid'" >
                        ({{ plan['paidAt'] | date: "dd MMM yy" }})
                    </span>
                    <button class="btn" *ngIf="plan['hasPaymentLink'] == true" (click)="copyLink(plan)">
                        <i class="fa fa-link p-0"></i>
                    </button>
                    <button class="btn ps-1" *ngIf="currentUser.role == 'Finance'" (click)="updateCollectionStatus(i)">
                        <i class="fas fa-pen p-0"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="type !== 'view'" class="text-end">
    <button class="btn btn-outline-primary" (click)="addForm()"><i class="fas fa-plus px-0"></i>&nbsp;New Payment</button>
</div>

<div class="col-12 mt-3 mb-1 text-right px-0 pb-3">
    <div *ngIf="type === 'view'" ngbDropdown class="d-inline-block action mr-3">
        <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            Actions
            <i class="fa fa-angle-down" aria-hidden="true"> </i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <ng-container *ngFor="let item of actions; let i=index">
                <button ngbDropdownItem *ngIf="item.hasAuthorization && item.inActionList" class="dropdown-item d-block" type="button"
                    (click)="doAction(item,customer)" ngbTooltip="{{item.message}}" placement="left"
                    [disableTooltip]="item.isAvailable" tooltipClass="tooltipCustomAuthMsg"
                    [ngStyle]="{'color': item.isAvailable ? 'none' : '#dfd3d3' }">
                    <i class="fas {{item.iconName}}" [ngStyle]="{'color': item.isAvailable ? 'none' : '#dfd3d3' }"></i>
                    {{item.actionLabel}}
                </button>
            </ng-container>
        </div>
    </div>
    <button type="button" class="btn btn-white mr-3" data-dismiss="modal" (click)="onCancelClick()">
        {{closeModalText}}
    </button>
    <button *ngIf="type !== 'view'" type="button" class="btn btn-blue" (click)="validation(true)" [ngClass]="{
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
      }" [disabled]="loading">
        Save
    </button>
</div>