import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  override serialize(tree: UrlTree): any {
    const path = super.serialize(tree);
    return path.replace(/%2F/g, '/');
  }
}
