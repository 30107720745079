import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InlineSVGService } from 'ng-inline-svg-2/lib_commonjs/inline-svg.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  SparklineChartDirective,
} from './directives';
import { ToggleDirective } from './directives/toggle/toggle.directive';
import {
  HtmlClassService,
  MenuAsideService,
  MenuHorizontalService,
  SplashScreenService,
  SubheaderService,
} from './services';
@NgModule({
  declarations: [
    MenuDirective,
    OffcanvasDirective,
    ScrollTopDirective,
    SparklineChartDirective,
    ToggleDirective,
  ],
  exports: [
    MenuDirective,
    OffcanvasDirective,
    ScrollTopDirective,
    SparklineChartDirective,
    ToggleDirective,
  ],
  providers: [
    HtmlClassService,
    InlineSVGService,
    MenuAsideService,
    MenuHorizontalService,
    SplashScreenService,
    SubheaderService,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    LoadingBarModule,
    NgxDaterangepickerMd,
    InlineSVGModule,
    RouterModule,
  ],
})
export class BaseModule {}
