import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
/*
 * Changes the name of any user to initials
 *   value | initials
 * Example:
 *   {{ 'Yana K' | initials }}
 *   formats to: 'YK'
 */
@Pipe({ name: 'DeadlineBadgePipe' })
export class DeadlineBadgePipe implements PipeTransform {
  transform(cond: string, deadline: Date, status?: number) {
    const today = moment(moment().format('YYYY-MM-DDTHH:mm:ss')).startOf('day');
    const deadlinedate = moment(
      moment(deadline).format('YYYY-MM-DDTHH:mm:ss')
    ).startOf('day');
    const diff = moment(deadlinedate).diff(today, 'days');

    if (cond === 'dateCheck') {
      if (status == 6 || status == 7) {
        // completed & cancelled
        return false;
      } else {
        return true;
      }
    }

    if (cond === 'badgeText') {
      let badgeText = '';
      if (diff < 0) {
        badgeText = 'Deadline Passed';
      } else if (diff === 0) {
        badgeText = 'Today';
      } else if (diff === 1) {
        badgeText = 'Tomorrow';
      } else if (diff > 1 && diff < 7) {
        badgeText = diff + ' days';
      } else if (diff === 7) {
        badgeText = '1 week';
      } else if (diff > 7 && diff <= 21) {
        badgeText = (diff / 7).toFixed(0) + ' weeks';
      } else if (diff > 21 && diff <= 30) {
        badgeText = '1 month approx';
      } else if (diff > 30 && diff <= 49) {
        badgeText = '2 months approx';
      } else if (diff > 49) {
        badgeText = (diff / 30).toFixed(0) + ' months approx';
      }
      return badgeText;
    }
    if (cond === 'badgeColor') {
      let badgeColor = '';
      if (diff <= 1) {
        badgeColor = 'badge-darkred';
      } else if (diff > 1 && diff <= 7) {
        badgeColor = 'badge-red';
      } else if (diff > 7 && diff <= 49) {
        badgeColor = 'badge-orange';
      } else if (diff > 49) {
        badgeColor = 'badge-yellow';
      }
      return badgeColor;
    }
    return '';
  }
}
