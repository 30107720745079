import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { LookupOption } from 'src/app/core/models';
import { ActionButton } from 'src/app/core/models/project/action-button.model';
import {
  AuthService,
  LookupService,
  ProjectService
} from 'src/app/core/services';
import { ErrorService } from 'src/app/core/services/error/error.services';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from "../../../confirmation/confirmation.component";
import {result} from "lodash";

@Component({
  selector: 'app-add-project-cost',
  templateUrl: './add-project-cost.component.html',
  styleUrls: ['./add-project-cost.component.scss'],
})
export class AddProjectCostComponent implements OnInit {
  paymentsList: any;
  paymentsListTotal: number;
  paymentsWordTotal: number;
  projectTitle: string;
  btnAddPayment = true;
  projectId: number;
  @Input() projectDetailData: any;
  @Input() actionButton: ActionButton;
  projectWriters: any[] = [];

  writerEarningTypes$: Observable<LookupOption[]>;
  mySubscription: any;

  currency = environment.currency;
  discountTotal: number;
  comment: string;
  customerComment: string;

  costs: any;
  apiCosts: any;
  costIndex: null;
  additionalCostTypes: any;

  isMeasurable: boolean[] = [];
  isCommentRequired: boolean[] = [];
  submitted = false;

  validations: any[] = [];
  totalPrice = 0;
  basePrice: number;


  constructor(
      private readonly projectService: ProjectService,
      private readonly errorService: ErrorService,
      private readonly toastrService: ToastrService,
      private readonly lookupService: LookupService,
      private authService: AuthService,
      public dialog: MatDialog,
      private router: Router,
      private readonly route: ActivatedRoute,
      @Inject(MAT_DIALOG_DATA)
      public data: { projectId: number; projectWriters: any; baseWordCount: number; academicLevelId: number; projectTypeId: number; projectTitle:string},
      public dialogRef: MatDialogRef<AddProjectCostComponent>,
  ) {
    this._loadLookups();
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {

    this.projectTitle = this.data.projectTitle
    this.projectId = this.data.projectId
    this.projectWriters = this.data.projectWriters
    this._loadPayments();
    this.projectService.sharedCosts.subscribe(costs => this.costs = costs);
    this.projectService.getCurrentQuotations(this.projectId).subscribe({
      next: (result) => {
        this.apiCosts = result;
        this.basePrice = result.basePrice
        this.costs = this.apiCosts.additionalCosts;
        this.projectService.nextCost(this.costs);
        this.discountTotal = this.apiCosts.discount;
        this.totalPrice = result.givenPrice;
        //this.calcTotalPrice()
        this.isValidation(false)


        this.lookupService.getAllAdditionalTypes().subscribe({
          next: (result) => {
            this.isMeasurable = []
            this.additionalCostTypes = result;
            this.costs.map((cost, i) => (
                result.filter((type, l) => (
                    cost.additionalCostTypeId == type.id ? this.isMeasurable[i] = type.measurableAsWord : null
                ))
            ))
            this.costs.map((cost, i) => (
                result.filter(type => (
                    cost.additionalCostTypeId == type.id ? this.isCommentRequired[i] = type.isCommentRequired : null
                ))
            ))


          },
          error: (error) => {
            this.errorService.showGenericErrorMessage(error);
          }
        });
      },
      error: (error) => {
        this.errorService.showGenericErrorMessage(error);
      }
    });
  }

  calcRowPrice(i) {
    if (!this.costs[i]['freeOfCharge']) {
      const row = {
        'projectTypeId': this.data['projectTypeId'],
        'additionalWordCount': this.costs[i]['wordCount'],
        'academicLevelId': this.data['academicLevelId']
      }
      this.projectService.getCalcPrice(row).subscribe({
        next: (result) => {
          this.costs[i]['price'] = result
          this.calcTotalPrice();
        },
        error: (error) => {
          this.errorService.showGenericErrorMessage(error);
        }
      })
    }
  }

  checkPositivePrice(i) {
    typeof this.costs[i]['price'] !== 'number' ? this.costs[i]['price'] = null : null
    isNaN(parseInt(parseInt(this.costs[i]['price']).toString())) ? this.costs[i]['price'] = null : null
    this.costs[i]['price'] < 0 ? this.costs[i]['price'] = null : null
    this.calcTotalPrice()
  }

  checkPositiveWordCount(i) {
    isNaN(parseInt(parseInt(this.costs[i]['wordCount']).toString())) ? this.costs[i]['wordCount'] = null : null
    this.costs[i]['wordCount'] < 0 ? this.costs[i]['wordCount'] = null : null
  }

  calcTotalPrice() {
    this.totalPrice = this.basePrice ? this.basePrice : 0;

    this.costs.map(cost => (
          this.totalPrice += cost.freeOfCharge ? 0 : cost.price
    ))
    this.totalPrice -= this.discountTotal
  }

  addRow() {
    this.submitted = false
    this.costs[this.costs.length] = {
      'additionalCostTypeId': null,
      'freeOfCharge': null,
      'price': null,
      'wordCount': null,
      'comment': null
    }
  }

  selectedCostType(val, i) {
    this.isMeasurable[i] = val.measurableAsWord
    this.isCommentRequired[i] = val.isCommentRequired
    val.measurableAsWord ? this.costs[i]['price'] = 0 : this.costs[i]['wordCount'] = null
  }

  freeChecked(val, i) {
    let isMeasurable = true
    //val.checked ? this.costs[i]['price'] = 0 : null
    this.calcTotalPrice()
    this.additionalCostTypes.find(x => (
       isMeasurable = x.id == this.costs[i]['additionalCostTypeId'] ? x.measurableAsWord : null
    ))
    if (isMeasurable && !val.checked) {
      this.calcRowPrice(i)
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  removeCost(index) {
    this.isMeasurable.splice(index, 1);

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      disableClose: true,
      data: {
        projectId: this.projectId,
        dialogMsg: 'Are you sure want to delete',
      },
    });
    dialogRef.afterClosed().subscribe((request) => {
      if (request) {
        this.costs.splice(index, 1);
        this.projectService.nextCost(this.costs);
      }
      this.calcTotalPrice()
    })
  }

  isValidation(save) {
    this.validations = []
    for (let i = 0; i < this.costs.length; i++) {
      this.validations[i] = []
      this.validations[i]['additionalCostType'] = true
      this.validations[i]['wordCount'] = false
      this.validations[i]['price'] = true
      this.validations[i]['note'] = true

      if (this.costs[i]['additionalCostTypeId']){
        if (this.isMeasurable[i]) {
          this.costs[i].wordCount ? this.validations[i]['wordCount'] = true : this.validations[i]['wordCount'] = false
        } else {
          this.validations[i]['wordCount'] = true
          if (!this.costs[i]['freeOfCharge']) {
            this.costs[i].price ? this.validations[i]['price'] = true : this.validations[i]['price'] = false
          }
        }
        this.validations[i]['note'] = !this.isCommentRequired[i]
      } else {
        this.validations[i]['additionalCostType'] = false
        this.validations[i]['wordCount'] = false
        this.validations[i]['price'] = false
        this.validations[i]['note'] = false
      }
    }
    const isRequired = this.validations.find(x => (x.price == false || x.wordCount == false || x.note == false)) ?? null
    if (save) {
      this.submitted = true
      if (!isRequired) {
        this.onSaveClick()
      }
    }
  }

  onSaveClick() {
    this.apiCosts['comment'] = this.comment;
    this.apiCosts['customerNote'] = this.customerComment;
    this.apiCosts.additionalCosts = this.costs;
    this.apiCosts.discount = this.discountTotal;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      disableClose: true,
      data: {
        projectId: this.projectId,
        dialogMsg: 'Confirm payment for this project?',
      },
    });
    dialogRef.afterClosed().subscribe((request) => {
      if (request) {
        let request = {};
        request = {
          projectId: this.projectId,
          additionalCosts: this.apiCosts.additionalCosts,
          discount: this.apiCosts.discount,
          comment: this.apiCosts.comment,
          customerNote: this.apiCosts.customerNote
        };
        this.projectService
            .postQuotations(this.projectId, request)
            .subscribe({
                  next: (_) => {
                    this.projectId;
                    this.ngOnInit();
                    this.dialogRef.close()
                  },
                  error: (error) => {
                    this.errorService.showGenericErrorMessage(error);
                  }
                }
            );
      }
    });
  }

  refreshComponent() {
    this.router.navigate([this.router.url]);
  }

  buttonActionStatus() {
    this.btnAddPayment = false;

    const currentUser = this.authService.currentUserValue;
    const loginUserId: number = Number(this.authService.currentUserValue.id);
    const projectSupervisorId: number = Number(
        this.projectDetailData.supervisorId
    );

    if (
        currentUser.role === 'Supervisor' &&
        projectSupervisorId === loginUserId
    ) {
      this.btnAddPayment = true;
    } else if (currentUser.role === 'Admin') {
      this.btnAddPayment = true;
    }
  }

  private _loadPayments() {
    this.projectService
        .getProjectDetails(this.projectId)
        .subscribe((result) => {
          this.projectDetailData = result;
          this.buttonActionStatus();
        });

    this.projectService.getProjectWriters(this.projectId).subscribe({
      next: (projectWriterIdResult) => {
        if (projectWriterIdResult.length > 0) {
          this.projectWriters = [];

          for (let i = 0, len = projectWriterIdResult.length; i < len; i++) {
            const writerId = projectWriterIdResult[i];

            this.projectService
                .getWriters(writerId)
                .subscribe((writerResult) => {
                  writerResult.writerIsAssigned = true;

                  this.projectWriters.push(writerResult);

                  this.projectService.getWriterEarnings(this.projectId).subscribe({
                    next: (result) => {
                      const resArr: any[] = [];
                      result.forEach((item) => {
                        const ix = resArr.findIndex(
                            (x) => x.writerId === item.writerId
                        );
                        if (ix <= -1) {
                          resArr.push({
                            id: item.id + 1000,
                            projectId: item.projectId,
                            writerId: item.writerId,
                            wordCount: result
                                .filter((itm) => itm.writerId === item.writerId)
                                .reduce(
                                    (sum, current) => sum + current.wordCount,
                                    0
                                ),
                            earningAmount: result
                                .filter((itm) => itm.writerId === item.writerId)
                                .reduce(
                                    (sum, current) => sum + current.earningAmount,
                                    0
                                ),
                            isHead: true,
                            data: result.filter(
                                (itm) => itm.writerId === item.writerId
                            ),
                          });
                        }
                      });

                      // resArr = resArr.filter(
                      //   (x) =>
                      //     resArr.findIndex((y) => x.writerId == y.writerId) == -1
                      // );
                      const paymentsWordTotal = result
                          .filter((itm) => !itm.isHead)
                          .map((data) => data.wordCount)
                          .reduce(
                              (item1: number, item2: number) =>
                                  Number(item1 ?? 0) + Number(item2 ?? 0),
                              0
                          );

                      const paymentsListTotal = result
                          .filter((itm) => !itm.isHead)
                          .map((data) => data.earningAmount)
                          .reduce(
                              (item1: number, item2: number) => item1 + item2,
                              0
                          );
                      result = resArr.concat(result).filter((x) => x.isHead);

                      // result = result.filter((x) => x.isHead);

                      result.forEach((element) => {
                        const foundWriter = this.projectWriters.filter(
                            (writer) => writer.id === element.writerId
                        );
                        element.writerDisplayName = foundWriter[0].displayName;
                      });

                      this.paymentsList = result.sort((a, b) =>
                          Number(a.writerId).toString().localeCompare(b.writerId)
                      );

                      this.paymentsListTotal = paymentsListTotal;

                      // const paymentsWordTotal = result
                      //   .filter((itm) => !itm.isHead)
                      //   .map((data) => data.wordCount)
                      //   .reduce(
                      //     (item1: number, item2: number) =>
                      //       Number(item1 ?? 0) + Number(item2 ?? 0),
                      //     0
                      //   );
                      this.paymentsWordTotal = paymentsWordTotal;
                    },
                    error: (error) => this.errorService.showGenericErrorMessage(error)
                  });
                });
          }
        }
      },
      error: (error) => {
        this.errorService.showGenericErrorMessage(error);
      }
    });
  }
  private _loadLookups() {
    this.writerEarningTypes$ = this.lookupService.getWriterEarningTypes();
  }

  deleteEarning(itemId: number) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      disableClose: true,
      data: {
        projectId: this.projectId,
        dialogMsg: 'Do you confirm to delete the earning?',
      },
    });
    dialogRef.afterClosed().subscribe((request) => {
      if (request) {
        this.projectService
            .postQuotations(this.projectId, itemId)
            .subscribe({
              next: (_) => {
                this.toastrService.success(
                    'Earning successfully deleted.',
                    'Earnind Deleted'
                );
                dialogRef.close(this.projectId);
                this.refreshComponent();
              },
              error: (error) => {
                this.errorService.showGenericErrorMessage(error);
              }
            });
      }
    });
  }
}
