export enum ProjectStatusId {
  None = 0,
  InfoRequired = 1,
  Pending = 2,
  ReadyToStart = 3,
  NotAllocated = 4,
  InProgress = 5,
  Completed = 6,
  Cancelled = 7,
  AwaitingManagerApproval = 8,
  ManagerApproved = 9,
  ManagerRejected = 10,
  Delivered = 11,
  AwaitingClarification = 12,
  AwaitingQuotation = 13,
  AwaitingPayment = 14
}

export enum ProjectStatus {
  None = 0,
  InfoRequired = 'InfoRequired',
  Pending = 'Pending',
  ReadyToStart = 'ReadyToStart',
  NotAllocated = 'NotAllocated',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  AwaitingManagerApproval = 'AwaitingManagerApproval',
  ManagerApproved = 'ManagerApproved',
  ManagerRejected = 'ManagerRejected',
  AwaitingClarification = 'AwaitingClarification',
  AwaitingQuotation = 'AwaitingQuotation',
  AwaitingPayment = 'AwaitingPayment'

}

export enum RejectReasons {
  None = 0,
  NotMyArea = 'NotMyArea',
  TooBusy = 'TooBusy',
  AssignedOtherSupervisor = 'AssignedOtherSupervisor',
  OnHoliday = 'OnHoliday',
  InformedCancellation = 'InformedCancellation',
  Other = 'Other',
}

export enum DraftExtensionReasons {
  None = 0,
  DisregardToTime = 'DisregardToTime',
  UnforseenPersonalReason = 'UnforseenPersonalReason',
  MultipleProject = 'MultipleProject',
  IntensiveFeedback = 'IntensiveFeedback',
  Other = 'Other',
}

export enum TransactionStatus {
  None = 'None',
  Pending = 'Pending',
  Urgent = 'Urgent',
  Done = 'Done',
}

export enum CancelReasons {
  None = 'None',
  ClientCancelled = 'ClientCancelled',
  QualityIssues = 'QualityIssues',
  VeryLateToProvideDraft = 'VeryLateToProvideDraft',
  AssignedToBetterWriter = 'AssignedToBetterWriter',
  Other = 'ClientOther',
}

export enum PaymentMethods {
  None = 'None',
  MoneyGram = 'MoneyGram',
  PayPal = 'PayPal',
  Payoneer = 'Payoneer',
  Ria = 'Ria',
  Skrill = 'Skrill',
  WesternUnion = 'WesternUnion',
  WorldRemit = 'WorldRemit',
  XEMoneyTransfer = 'XEMoneyTransfer',
  XpressMoney = 'XpressMoney',
}

export enum AvailabilityType {
  None = 'None',
  NotAvailable = 'NotAvailable',
  Available = 'Available',
  HighAvailability = 'HighAvailability',
}

export enum FeedbackType {
  None = 'None',
  Quality = 'Quality',
  Communication = 'Communication',
  FeedbackResponse = 'Feedback Responsive',
  Expertise = 'Expertise',
  Originality = 'Originality',
}

export enum PaymentType {
  None = 'None',
  InitialCost = 'Initial Cost',
  AdditionalCostExtraAppendices = 'Additional Cost - Extra Appendices',
  AdditionalCostUrgency = 'Additional Cost - Urgency',
  AdditionalCostExtraStatisticalAndFinancial = 'Additional Cost - Extra Statistical and Financial',
  AdditionalCostExtraWordsRequired = 'Additional Cost - Extra Words Required',
  AdditionalCostExtensiveClientFeedback = 'Additional Cost - Extensive Client Feedback',
}

export enum PaymentCategoryType {
  None = 'None',
  Category1 = 1,
  Category2 = 2,
  Category3 = 3,
  Category4 = 4,
}

export enum TransferReasons {
  None = 0,
  AgreedToSwitchProjects = 'Agreed to Switch Projects',
  AgreedToBalanceWorkload = 'Agreed to Balance workload',
  NeededHelp = 'Needed Help',
  HolidayBreak = 'Holiday Break',
  UnavailableDueToEmergency = 'Unavailable due to Emergency',
  Other = 'Other',
}

export enum SpecificClientNeeds {
  None = 0,
  RequireDraftForFeedback = 1,
  TopGradeEssential = 2,
  BasicLanguage = 3,
  NoExtensionCanBeGranted = 4,
  MidStandardGradeRequired = 5,
}

export enum Roles {
  Admin = 1,
  Supervisor = 2,
  CSA = 3,
  Writer = 4,
  QC = 5,
  Customer = 6,
  HR = 7,
  Finance = 8,
  SupervisorAllocator = 11
}

export enum StatusType {
  Project = 1,
  CustomerPayment = 2,
  AgentWithdrawal = 3,
  CollectionStatus = 4
}

export enum UndoOperationType {
  Undo,
  UndoCancel,
  BackToPending,
  BackToInProgress,
  BackToCompleted
}
