import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/core/services';
import { QueryParamsModel } from '../base/models';

@Injectable({ providedIn: 'root' })
export class UserPopulateService {
  constructor(private usersService: UsersService) { }

  populateUser(data: any,displayPropName: string,userIdPropNames: string[]): any {
    const userIds = [];
    data.map((key, index) => {
      userIdPropNames.forEach((item) => {
        const a = key[item];
        if (userIds.indexOf(a) === -1) {
          userIds.push(a);
        }
      });
    });

    const params = new QueryParamsModel();
    params.all = true;
    params.filter.push('id==' + userIds.join(';'));
    this.usersService.getUsers(params).subscribe((x) => {
      data.forEach((item: any) => {
        userIdPropNames.forEach((element) => {
          item[displayPropName] = x.results.find(
            (user) => user.id === item[element]
          ).displayName;
        });
      });
    });
  }
}
