import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerStatus'
})
export class CustomerStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 1: case 'NewLead': // New Lead
        return 'info-area';

      case 2: case 'Lead': // Lead
        return 'info-area';

      case 3: case 'Active': // Active
        return 'warning-area';

      case 4: case 'Inactive': // Inactive
        return 'badge-green';

      case 5: case 'Lost': // Lost Customer
        return 'badge-gray';

      case 6: case 'NotConverted': // Not Converted
        return 'info-area';
      default:
        return 'badge-clr-gray';
    }
  }

}
