<ng-container>
  <ng-template
    ngFor
    let-rowData
    let-rowIndex="index"
    [ngForOf]="
      dt.paginator && !dt.lazy
        ? (dt.filteredValue || dt.value | slice: dt.first:dt.first + dt.rows)
        : dt.filteredValue || dt.value
    "
    [ngForTrackBy]="dt.rowTrackBy"
  >
    <!-- editing: dt.editMode === 'row' && dt.isRowEditing(rowData) -->

    <ng-container
      *ngTemplateOutlet="
        template;
        context: {
          $implicit: rowData,
          rowIndex: dt.paginator ? dt.first + rowIndex : rowIndex,
          columns: columns
        }
      "
    ></ng-container>
  </ng-template>
</ng-container>
<ng-container *ngIf="dt.isEmpty()">
  <!-- <ng-container
    *ngTemplateOutlet="
      dt.emptyMessageTemplate;
      context: { $implicit: columns, frozen: frozen }
    "
  ></ng-container> -->
</ng-container>
