// tslint:disable: no-input-rename component-selector
import { Component, Input, TemplateRef } from '@angular/core';
import { ColumnComponent } from '../column.component';
import { NisloGridComponent } from '../grid.component';

@Component({
  selector: '[nrTableBody]',
  templateUrl: 'grid-body.component.html',
})
export class NisloGridBodyComponent {
  @Input('nrTableBody') columns: ColumnComponent[];

  @Input('nrTableBodyTemplate') template: TemplateRef<any>;

  constructor(public dt: NisloGridComponent) {}
}
