import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ProjectService } from "../../../../../../core/services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  data: any;
  quoteId: number;
  routerUrl: string[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly projectService: ProjectService,
    private router: Router,
  ) {
    this.routerUrl = this.router.url.split('/');
    this.quoteId = parseInt(this.routerUrl[this.routerUrl.length - 1]);
  }

  ngOnInit(): void {
    this.projectService.getQuoteDocument(this.quoteId).subscribe({
      next: result => {
        this.data = result
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    });
  }
}