// Angular
import { Component, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models';
// NGRX

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user$: Observable<User> | undefined;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor() {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * Log out
   */
  logout() {}
}
