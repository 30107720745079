import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services';
import { ErrorService } from 'src/app/core/services/error/error.services';
import { MustMatch } from '../../../shared/helpers/must-match.validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted = false;

  loginUserValue: User;
  panel: any;
  subscription: any;

  constructor(
    private formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly toastrService: ToastrService,
    private readonly errorService: ErrorService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {
    this.loginUserValue = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.changePasswordForm = this._changePasswordForm();
  }

  private _changePasswordForm() {
    return this.formBuilder.group(
      {
        currentPassword: [null, Validators.required],
        newPassword: [null, Validators.required],
        newPasswordAgain: [null, Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'newPasswordAgain'),
      }
    );
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    const request = {
      // userId: this.loginUserValue.id,
      currentPassword:
        this.changePasswordForm.controls['currentPassword'].value,
      newPassword: this.changePasswordForm.controls['newPassword'].value,
    };

    this.authService.changePassword(this.loginUserValue.id, request).subscribe({
      next: (result) => {
        this.toastrService.success(
          'Password changed successfully.',
          'Password Change'
        );
        this.panel = 'success';
      },
      error: (error) => {
        this.errorService.showGenericErrorMessage(error);
      }
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
