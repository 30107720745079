<div id="NisloModal">
    <!-- header -->
    <div class="row mb-5">
        <div class="col-sm">
            <div class="pageSubHeader d-flex justify-content-between">
                <h3 class="page-title">Update Plan Status</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancelClick()">
                    <i class="fas fa-times" style="font-size: 14px"></i>
                </button>
            </div>
        </div>
    </div>
    <table class="table table-bordered">
        <tbody>
            <tr>
                <td colspan="2">
                    Plan Status
                </td>
                <td>
                    <ng-select [clearable]="false" [(ngModel)]="planStatus" [ngClass]="{ 'is-invalid': submitted && !planStatus}">
                        <ng-option *ngFor="let status of collectionStatuses" value="{{status['id']}}">
                            {{status['label']}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    Paid Date
                </td>
                <td>
                    <input class="form-control ico-calendar datePicker" type="text" name="date" ngbDatepicker
                           #d="ngbDatepicker" [placement]="'left'" (click)="d.toggle()" autocomplete="off"
                           placeholder="dd mm YY" [(ngModel)]="jsonDate" readonly
                           [ngClass]="{ 'is-invalid': submitted && !jsonDate}"
                    />
                </td>
            </tr>
        </tbody>
    </table>
    <div class="col-12 mt-3 mb-1 text-right px-0 pb-3">
        <button type="button" class="btn btn-white mr-3" data-dismiss="modal" (click)="onCancelClick()">
            Back
        </button>
        <button type="button" class="btn btn-blue" (click)="onSaveClick()">
            Save
        </button>
    </div>
</div>