export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: 'Dashboard',
        desc: '',
        subheader: false,
      }
    },
    orders: {
      page: {
        title: 'Orders Pool',
        desc: '',
        subheader: false,
      }
    },
    'my-page': {
      // <= add page URL
      page: { title: 'My page', desc: 'My page desc' } // <= Page name and description
    },
    team: {
      list: {
        page: {
          title: 'Team Members',
          desc: 'Our team members'
        }
      },
      edit: {
        page: {
          title: 'Edit team member',
          desc: ''
        }
      }
    },
    header: {
      actions: {
        page: { title: 'Actions', desc: 'Actions example page' }
      }
    },
    profile: {
      page: { title: 'User Profile', desc: '' }
    },
    error: {
      404: {
        page: { title: '404 Not Found', desc: '', subheader: false }
      },
      403: {
        page: { title: '403 Access Forbidden', desc: '', subheader: false }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
