import { Pipe, PipeTransform } from '@angular/core';
/*
 * Changes the name of any user to initials
 *   value | initials
 * Example:
 *   {{ 'Yana K' | initials }}
 *   formats to: 'YK'
 */
@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform {
  transform(value: string): string | void {
    if (value === undefined || value === null) {
      return;
    }
    const first = value.substring(0, 1).toLocaleUpperCase();
    let last = '';
    const names = value.split(' ');
    if (names.length > 1) {
      last = names[1].substring(0, 1).toLocaleUpperCase();
    }

    return first + last;
  }
}
