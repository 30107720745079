import { I } from '@angular/cdk/keycodes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textfilter',
})
export class TextfilterPipe implements PipeTransform {
  transform(value: any[], filterText?: string): any[] {
    if (filterText == null) {
      return value;
    }
    let filterText2 = filterText ? filterText.toLocaleLowerCase() : null;
    return filterText2
      ? value.filter(
          (i: any) => i.title.toLocaleLowerCase().indexOf(filterText) !== -1
        )
      : value;
  }
}
