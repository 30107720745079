<!-- begin:: Header Topbar -->
<div class="kt-header__topbar float-right">
  <div
    *ngIf="isDevMode"
    style="
    background: red;
    color: white;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
    margin: auto;
    padding: 10px;
    width: max-content;
    white-space: nowrap;
    "
  >
    Dev/QA Environment
  </div>

  <div class="d-flex">
    <kt-notification
      [bgImage]="'./assets/media/misc/bg-1.jpg'"
      [pulse]="true"
      [skin]="'dark'"
      [icon]="'fas fa-bell'"
    ></kt-notification>
    <!--end: Notifications -->

    <!--begin: Quick panel toggler
	<div class="kt-header__topbar-item kt-header__topbar-item--quick-panel" data-placement="bottom" ngbTooltip="Quick panel">
		<span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
			<span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"></span>
		</span>
	</div>-->
    <!--end: Quick panel toggler -->
    <!--begin: User bar -->
    <!--<kt-user-profile></kt-user-profile>-->
    <!--end: User bar -->
    <app-user-info></app-user-info>
  </div>
</div>
<!-- end:: Header Topbar -->
