import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';
import { ChangePasswordComponent } from './modules/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { BaseComponent } from './shared/components';
import { PaymentPlansComponent} from "./modules/payments/pages/payment-plans/payment-plans.component";
import { QuoteComponent } from './modules/customer/pages/profile/add-payment-plan/quote/quote.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'invoice/:id',
    loadChildren: () =>
      import('src/app/modules/invoice/invoice.module').then(
        (m) => m.InvoiceModule
      ),
  },
  {
    path: 'quote/:id',
    canActivate: [AuthGuard],
    component: QuoteComponent,
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['admin', 'hr', 'finance','supervisorallocator'],
        },
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('src/app/modules/orders/orders.module').then(
            (m) => m.OrdersModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'hr', 'finance'],
          title: 'All Projects | Writeset PMS',
        },
      },
      {
        path: 'projects/:id',
        loadChildren: () =>
          import('src/app/modules/project/project.module').then(
            (m) => m.ProjectModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'hr', 'finance'],
          title: 'Project Details',
        },
      },
      {
        path: 'projects/filter/:id',
        loadChildren: () =>
          import('src/app/modules/my-project/my-project.module').then(
            (m) => m.MyProjectModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'hr'],
          title: 'Filtered Customer Projects | Writeset PMS',
        },
      },
      {
        path: 'writers',
        loadChildren: () =>
          import('src/app/modules/writer/writer.module').then(
            (m) => m.WriterModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['admin', 'supervisor', 'supervisorallocator', 'hr', 'finance'],
          title: 'Writers | Writeset PMS',
        },
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('src/app/modules/my-project/my-project.module').then(
            (m) => m.MyProjectModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor'],
          title: 'My Projects | Writeset PMS',
        },
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('src/app/modules/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'finance'],
          title: 'Customer Payments | Writeset PMS',
        },
      },
      {
        path: 'payment-planner/:id',
        loadChildren: () =>
          import('src/app/modules/payment-planner/payment-planner.module').then(
            (m) => m.PaymentPlannerModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin'],
          title: 'Payment Planner | Writeset PMS',
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('src/app/modules/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['admin', 'supervisor'],
          title: 'Users | Writeset PMS',
        },
      },
      {
        path: 'agents',
        loadChildren: () =>
          import('src/app/modules/agents/agents.module').then((m) => m.AgentsModule),
        data: {
          title: 'Agents | Unihelper PMS',
        },
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('src/app/modules/notification/notification.module').then(
            (m) => m.NotificationModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'hr', 'finance'],
          title: 'Notifications | Writeset PMS',
        },
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('src/app/modules/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'supervisor', 'supervisorallocator', 'finance'],
          title: 'Customers | Writeset PMS',
        },
      },
      {
        path: 'payment-plans',
        component: PaymentPlansComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['csa', 'admin', 'finance'],
          title: 'Payment Plans | Unihelper PMS',
        },
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      // { path: '',   redirectTo: '/orders', pathMatch: 'full' },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

const options: ExtraOptions = {
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
