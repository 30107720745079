// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// Crud

@Component({
  selector: 'kt-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  // Public properties
  dataSource = [
    {
      id: 3,
      managedBy: 'Financial Insights and Business Intelligence',
      occupation: 'Presentation',
      status: 'In Progress',
      date: '31/10/2020',
      company: 'Supervisor 5',
      subject: 'Law, Security Studies',
      actions: ['View']
    },
  ];
  loading = false;
  paginatorTotal = 1;
  displayedColumns = [
    'managedBy',
    'date',
    'status',
    'company'
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selection = new SelectionModel<any>(true, []);

  constructor() {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    /* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadItems();
        })
      )
      .subscribe();

    // First load
    this.loadItems(true);
  }

  /**
   * Load items
   *
   * @param firstLoad: boolean
   */
  loadItems(firstLoad: boolean = false) {
  }

  /* UI */

  /**
   * Returns item status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: string): string {
    switch (status) {
      case 'In Progress':
        return 'info';
      case 'Done':
        return 'danger';
      default:
        return status.toLowerCase();
    }
  }
}
