<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <title>Quote v2</title>
    <style>
        main {
            border-radius: 10px;
            border: 1px solid #e8e9f1;
            background: #FFFFFF;
            margin-left: auto;
            margin-right: auto;
            width: 900px;
            flex-direction: column;
            display: flex;
            font-family: sans-serif;
        }

        tr {
            border-collapse: collapse;
            border-bottom: 1px solid black;
        }

        tr:nth-child(even) {
            background: #fafcfc;
        }

        td {
            padding: 5px;
        }


        .bordered thead tr {
            background: #fafcfc;
            padding: 10px;
        }

        .bordered thead tr th {
            padding: 5px;
            text-align: right;
            font-size: 15px;
        }

        .bordered td,
        .bordered th {
            border: 1px solid #e8e9f1;
        }

        .justify-content-between {
            align-items: baseline;
            justify-content: space-between;
            display: flex;
        }

        .d-flex {
            display: flex !important;
            flex: 1 1 auto;
        }

        .gap {
            gap: 20px;
        }
    </style>
</head>

<body>
    <main>
        <div style="margin:0 20px">
         
            <div class="d-flex gap">
                <div  class="d-flex" style="margin-right: auto; padding-top:40px;width:40%;align-items:top;justify-content:center;">
                    <!-- <img src="https://qa.pms.unihelper.com/assets/media/logos/logo-unihelper-256.png" height="30px" width="180px" alt=""> --> 
                      <p  style="text-align:left;">
                        Education Teknoloji Anonim Sirketi <br/> 
                        Trump Tower Blok No: 12 /18, Kuştepe Mah. Mecidiyeköy Yolu Cad. Sisli/ Istanbul <br/>
                        VKN: 3241089319
                    </p>
                </div>

                <div style="margin-right: auto; padding-top:40px;width:40%">
                  <p>

                  </p>
                </div>


                <div style="margin-right: auto; padding-top: 1.5rem;width:30%">
                    <table class="bordered"
                        style="border-collapse: collapse; margin-bottom: 3em;  margin-left: auto; margin-right: auto; margin-top: 1.5rem">
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        QUOTE
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date: {{data?.documentCreatedAt | date: 'dd/MM/yyyy'}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Number: {{data?.referenceNumber}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Customer ref: {{data?.customerAccountCode | uppercase }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Customer Name: {{data?.customerFirstName}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div>
                <table class="bordered"
                    style="border-collapse: collapse; border-bottom: 1px solid black; width:100%; margin: 1.5rem auto 3em;">
                    <thead>
                        <tr>
                            <th class="text-left">Project Ref</th>
                            <th style="text-align:left;">Description</th>
                            <th>Deadline</th>
                            <th>Price</th>
                            <th>VAT</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let project of data?.projects; let i = index;">
                        <tr>
                            <td class="">
                                {{project.referenceNumber}}
                            </td>
                            <td>
                               <b>{{project.moduleTitle}} </b>{{project.projectType}} ({{project.length}} {{project.unit}}) <p>Comment: {{project.additionalNote}}</p>
                            </td>
                            <td class="text-right">
                               {{project.deadline |  date: 'dd.MM.yyyy'}}
                            </td>
                            <td class="text-right">
                                £{{project.givenPrice | number: '1.2-2'}}
                            </td>
                            <td class="text-right">
                                %{{project.vat ?  project.vat : 0 }}
                            </td>
                            <td class="text-right">
                                £{{project.givenPrice | number: '1.2-2'}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="flex-end" style="margin: 1.5rem auto 3em;">
                <table class="bordered" align="right" style="border-collapse: collapse; width:350px;margin-bottom:20px;">
                    <thead>
                        <tr>
                            <th colspan="2">Summary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="width: 60%;">
                                Sub Total
                            </td>
                            <td class="text-end">
                                £{{data?.totalAmount + data?.totalDiscount | number: '1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 60%;">
                                Total Discount
                            </td>
                            <td class="text-end">
                                £{{data?.totalDiscount | number: '1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 60%;">
                                Total
                            </td>
                            <td class="text-end">
                                <b>£{{data?.totalAmount | number: '1.2-2'}}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table class="bordered" style="border-collapse: collapse; border-bottom: 1px solid black; width:100%; margin: 1.5rem auto 3em;">
                    <thead>
                        <tr>
                            <th class="text-start">Payment Ref</th>
                            <th class="text-start">Method</th>
                            <th class="text-start">Due Date</th>
                            <th  class="text-start">Amount</th>
                            <th class="text-start">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let collection of data?.collections; let i = index">
                            <td class="text-left">
                                {{collection.referenceNumber | uppercase }}
                            </td>
                            <td>
                                {{collection.collectionMethod}}
                            </td>
                            <td class="text-right">
                                {{collection.dueDate | date: "dd.MM.yyyy"}}
                            </td>
                            <td class="text-end">
                                £{{collection.amount | number: '1.2-2'}}
                            </td>
                            <td>
                                {{collection.status}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </main>
</body>

</html>