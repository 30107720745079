import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const API: any = {
  orders: 'orders',
  interestedSupervisors: 'interested-supervisors',
};

@Injectable({ providedIn: 'root' })
export class OrdersService {
  constructor(private readonly http: HttpClient) {}
}
