import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditInputComponent } from './components/edit-input/edit-input.component';
import { SortableColumnDirective } from './custom/directives/sortable-column.directive';
import { StickyPopoverDirective } from './custom/directives/sticky-popover.directive';
import { NisloTemplateDirective } from './custom/directives/template.directive';
import { ColumnComponent } from './custom/grid/column.component';
import { NisloGridBodyComponent } from './custom/grid/grid-body/grid-body.component';
import { NisloGridComponent } from './custom/grid/grid.component';
import { SelectableRowDirective } from './custom/grid/selectable-row.directive';
import { SortIconComponent } from './custom/grid/sort-icon/sort-icon.component';
import { PaginatorComponent } from './custom/paginator/paginator.component';
import { CustomerStatusPipe } from './custom/pipes/customer-status.pipe';
import { DeadlineBadgePipe } from './custom/pipes/deadlinebadge.pipe';
import { InitialsPipe } from './custom/pipes/initials.pipe';
import { PaymentCategoryColorPipe } from './custom/pipes/paymentcategorycolor.pipe';
import { SortByPipe } from './custom/pipes/sortby.pipe';
import { StatusBadgePipe } from './custom/pipes/statusbadge.pipe';
import { TimeagoPipe } from './custom/pipes/Timeago.pipe';
import { ProgressSpinnerComponent } from './custom/progress-spinner/progress-spinner.component';
import { TextfilterPipe } from './custom/textfilter/textfilter.pipe';


@NgModule({
  declarations: [
    InitialsPipe,
    TextfilterPipe,
    SortByPipe,
    TimeagoPipe,
    ColumnComponent,
    NisloGridComponent,
    NisloGridBodyComponent,
    NisloTemplateDirective,
    StickyPopoverDirective,
    PaginatorComponent,
    ProgressSpinnerComponent,
    SelectableRowDirective,
    SortableColumnDirective,
    SortIconComponent,
    DeadlineBadgePipe,
    PaymentCategoryColorPipe,
    StatusBadgePipe,
    SortIconComponent,
    EditInputComponent,
    CustomerStatusPipe
  ],
  exports: [
    InitialsPipe,
    TextfilterPipe,
    SortByPipe,
    TimeagoPipe,
    ColumnComponent,
    NisloGridComponent,
    NisloGridBodyComponent,
    NisloTemplateDirective,
    StickyPopoverDirective,
    PaginatorComponent,
    ProgressSpinnerComponent,
    SelectableRowDirective,
    SortableColumnDirective,
    SortIconComponent,
    EditInputComponent,
    DeadlineBadgePipe,
    PaymentCategoryColorPipe,
    StatusBadgePipe,
    CustomerStatusPipe
  ],
  providers: [CurrencyPipe],
  imports: [CommonModule, FormsModule, NgSelectModule],
})
export class NisloCustomModule { }
