import { NgModule } from '@angular/core';
import {
  BaseComponent,
  FooterComponent,
  HeaderComponent,
  BrandComponent,
  HeaderMobileComponent,
  SubheaderComponent,
  TopbarComponent,
  AsideLeftComponent,
  MenuHorizontalComponent,
  ErrorPageComponent,
  ErrorComponent,
} from './components';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { LayoutModule } from './layout/layout.module';
import { BaseModule } from './base/base.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationComponent } from '../modules/project/pages/confirmation/confirmation.component';
import { ChangePasswordComponent } from '../modules/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from '../modules/auth/forgot-password/forgot-password.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    SubheaderComponent,
    TopbarComponent,
    AsideLeftComponent,
    MenuHorizontalComponent,
    ErrorComponent,
    ErrorPageComponent,
    ConfirmationComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    SubheaderComponent,
    TopbarComponent,
    AsideLeftComponent,
    MenuHorizontalComponent,
    ErrorComponent,
    ErrorPageComponent,
    NgSelectModule,
    ConfirmationComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
  ],
  providers: [],
  imports: [
    BaseModule,
    CommonModule,
    FormsModule,
    InlineSVGModule,
    LayoutModule,
    LoadingBarModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTabsModule,
    MatTooltipModule,
    NgbModule,
    NgxDaterangepickerMd,
    NgxPermissionsModule.forChild(),
    PerfectScrollbarModule,
    RouterModule,
    NgSelectModule,
    // NumberPickerModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
})
export class SharedModule {}
