import {
  Component,
  AfterContentInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ContentChildren,
  TemplateRef,
  ContentChild
} from '@angular/core';
import { NisloTemplateDirective } from '../directives/template.directive';

@Component({
  selector: 'nr-column',
  template: ''
})
export class ColumnComponent implements AfterContentInit {
  @Input() field: string;
  @Input() colId: string;
  @Input() sortField: string;
  @Input() filterField: string;
  @Input() header: string;
  @Input() footer: string;
  @Input() sortable: any;
  @Input() filter: boolean;
  @Input() filterMatchMode: string;
  @Input() filterType = 'text';
  @Input() excludeGlobalFilter: boolean;
  @Input() rowspan: number;
  @Input() colspan: number;
  @Input() scope: string;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() exportable = true;
  @Input() headerStyle: any;
  @Input() headerStyleClass: string;
  @Input() bodyStyle: any;
  @Input() bodyStyleClass: string;
  @Input() footerStyle: any;
  @Input() footerStyleClass: string;
  @Input() hidden: boolean;
  @Input() selectionMode: string;
  @Input() filterPlaceholder: string;
  @Input() filterMaxlength: number;
  @Output() sortFunction: EventEmitter<any> = new EventEmitter();
  @ContentChildren(NisloTemplateDirective) templates: QueryList<any>;
  @ContentChild(TemplateRef, /* TODO: add static flag */  { static: true }) template: TemplateRef<any>;

  public headerTemplate: TemplateRef<any>;
  public bodyTemplate: TemplateRef<any>;
  public footerTemplate: TemplateRef<any>;
  public filterTemplate: TemplateRef<any>;
  public editorTemplate: TemplateRef<any>;

  ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'body':
          this.bodyTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        case 'filter':
          this.filterTemplate = item.template;
          break;

        case 'editor':
          this.editorTemplate = item.template;
          break;

        default:
          this.bodyTemplate = item.template;
          break;
      }
    });
  }
}
