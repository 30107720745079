export enum StorageType {
  Cookie,
  LocalStorage,
  LocalVariable,
  Session,
}

export interface StorageService {
  storageType: StorageType;
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  clear(keepSession: boolean): void;
  isSupported(): boolean;
}
