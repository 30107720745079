import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { User } from './core/models';
import { AuthService } from './core/services';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { MessagingService } from './core/services/messaging/messaging.service';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.authService.currentUser.subscribe((x) => (this.currentUser = x));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.titleService.setTitle(data['title']);
      });
  }

  ngOnInit(): void {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }

  logout() {
    this.authService.logout();
    this.router.navigated = false;
    this.router.navigate(['/auth/login']);
  }
}
