import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit, OnChanges {
  @Input() value: number = 0;
  @Input() circleClassName: string = '';
  @Input() strokeWidth: number;
  @Input() height: number;
  @Input() width: number;
  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number = 0;

  constructor() {
    this.progress(0);
  }

  ngOnInit() {
    if (!this.circleClassName) {
      this.circleClassName = 'progress-green';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'].currentValue !== changes['value'].previousValue) {
      this.progress(changes['value'].currentValue);
    }
  }

  private progress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }
}
