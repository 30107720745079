<div class="kt-widget12">
	<div class="kt-widget12__content">
		<div class="kt-widget12__item">
			<div class="kt-widget12__info">
				<span class="kt-widget12__desc">Annual Taxes EMS</span>
				<span class="kt-widget12__value">$400,000</span>
			</div>
			<div class="kt-widget12__info">
				<span class="kt-widget12__desc">Finance Review Date</span>
				<span class="kt-widget12__value">July 24,2019</span>
			</div>
		</div>
		<div class="kt-widget12__item">
			<div class="kt-widget12__info">
				<span class="kt-widget12__desc">Avarage Revenue</span>
				<span class="kt-widget12__value">$60M</span>
			</div>
			<div class="kt-widget12__info">
				<span class="kt-widget12__desc">Revenue Margin</span>
				<div class="kt-widget12__progress">
					<div class="progress kt-progress--sm">
						<div class="progress-bar bg-success" role="progressbar" style="width: 40%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<span class="kt-widget12__stat">40%</span>
				</div>
			</div>
		</div>
	</div>
	<div class="kt-widget12__chart" style="height:250px;">
		<canvas #chart id="kt_chart_order_statistics" width="683" height="312"></canvas>
	</div>
</div>
