import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
})
export class EditInputComponent implements OnInit {
  private localInputType: any;
  public localData: any;
  public localDataShow: any;
  currency = environment.currency;

  @Input() elementName: string;

  @Input() set data(value: any) {
    this.localData = value;

    if (this.localInputType === 'currency') {
      this.localDataShow = this.cp.transform(
        value,
        this.currency,
        'symbol',
        '1.2-2'
      );
    } else if (this.localInputType === 'text') {
      this.localDataShow = this.localData;
    } else {
      this.localDataShow = this.localData;
    }
  }
  @Input() set inputType(value: any) {
    this.localInputType = value;

    if (value === 'currency') {
      this.localDataShow = this.cp.transform(
        this.localData,
        this.currency,
        'symbol',
        '1.2-2'
      );
    } else if (value === 'text') {
      this.localDataShow = this.localData;
    } else {
      this.localDataShow = this.localData;
    }
  }

  // remove any
  blurEvent($event: any) {
    $event.currentTarget.blur();
  }

  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();
  editMode = false;
  constructor(private cp: CurrencyPipe) {}

  ngOnInit() {}

  onFocusOut() {
    this.focusOut.emit({ data: this.localData, elementName: this.elementName });

    if (this.localInputType === 'currency') {
      this.localDataShow = this.cp.transform(
        this.localData,
        this.currency,
        'symbol',
        '1.2-2'
      );
    } else if (this.localInputType === 'text') {
      this.localDataShow = this.localData;
    } else {
      this.localDataShow = this.localData;
    }
  }
}
