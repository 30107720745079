import {Pipe, PipeTransform} from '@angular/core';
import {ProjectStatusId, StatusType} from 'src/app/core/models';

/*
 * Changes the name of any user to initials
 *   value | initials
 * Example:
 *   {{ 'Yana K' | initials }}
 *   formats to: 'YK'
 */
@Pipe({ name: 'StatusBadgePipe' })
export class StatusBadgePipe implements PipeTransform {
  transform(status: number, statusType: StatusType) {

    switch (statusType) {
      case StatusType.Project:
        switch (status) {
          case ProjectStatusId.InfoRequired:
            return 'badge-red';
          case ProjectStatusId.Pending:
            return 'badge-yellow';
          case ProjectStatusId.ReadyToStart:
            return 'badge-lightgreen';
          case ProjectStatusId.NotAllocated:
            return 'badge-red';
          case ProjectStatusId.InProgress:
            return 'badge-orange';
          case ProjectStatusId.Completed:
            return 'badge-green';
          case ProjectStatusId.Cancelled:
            return 'badge-gray';
          case ProjectStatusId.AwaitingPayment:
              return 'badge-blue';
          case ProjectStatusId.AwaitingQuotation:
            return 'badge-purple';
          default:
            return 'badge-white';
        }

        break;
      case StatusType.CustomerPayment:
        switch (status) {
          case 1: // NotConfirmed
            return 'badge-gray';
          case 2: // Confirmed
            return 'badge-orange';
          case 3: // Paid
            return 'badge-green';
          case 4: // Cancelled
            return 'badge-red';
          case 5: // Refunded
            return 'badge-yellow';
          default:
            return 'badge-gray';
        }
        break;
      case StatusType.AgentWithdrawal:
        switch (status) {
          case 1: // Requested
            return 'badge-gray';
          case 2: // Completed
            return 'badge-green';
          case 3: // Cancelled
            return 'badge-red';
          default:
            return 'badge-gray';
        }
        break;
      case StatusType.CollectionStatus:
        switch (status) {
          case 1: // Not Paid
            return 'badge-red';
          case 2: // Paid
            return  'badge-green';
        }
        break;

      default:
        return 'badge-gray';
    }




  }
}
