export class AppConfig {
  public defaults: any = {
    projectActionButtons:
      [
        {
          actionName: 'NotConfirmed',
          iconName: 'fa-exclamation-circle',
          functionName: 'setNotConfirmedStatus'
        },
        {
          actionName: 'InfoRequired',
          iconName: 'fa-question-circle',
          functionName: 'setInfoRequiredStatus'
        },
        {
          actionName: 'AwaitingManagerApproval',
          iconName: 'fa-stamp',
          functionName: 'setAwaitingManagerApprovalStatus'
        },
        {
          actionName: 'RespondManagerApprovalRequest',
          iconName: 'fa-reply',
          functionName: 'respondApprovalRequest'
        },
        {
          actionName: 'ReadyToStart',
          iconName: 'fa-play',
          functionName: 'setReadyToStartStatus'
        },
        {
          actionName: 'AssignSupervisor',
          iconName: 'fa-user-plus',
          functionName: 'claimProject'
        },
        {
          actionName: 'TransferSupervisor',
          iconName: 'fa-users-cog',
          functionName: 'openTransferProject'
        },
        {
          actionName: 'AwaitingClarification',
          iconName: 'fa-binoculars',
          functionName: 'setAwaitingClarificationStatus'
        },
        {
          actionName: 'ClarificationProvided',
          iconName: 'fa-handshake',
          functionName: 'setClarificationProvidedStatus'
        },
        {
          actionName: 'Complete',
          iconName: 'fa-check-circle',
          functionName: 'openCompleteDialog'
        },
        {
          actionName: 'Delivered',
          iconName: 'fa-paper-plane',
          functionName: 'openDeliverDialog'
        },
        {
          actionName: 'Undo',
          iconName: 'fa-undo',
          functionName: 'openUndoDialog'
        },
        {
          actionName: 'Cancel',
          iconName: 'fa-times',
          functionName: 'openCancelDialog'
        },
        {
          actionName: 'EditProject',
          iconName: 'fa-pen'
        },
        {
          actionName: 'EditDropbox',
          iconName: 'fa-pen'
        },
        {
          actionName: 'ModifyWriterAssignments',
          iconName: 'fa-plus'
        },
        {
          actionName: 'ModifyWriterEarnings',
          iconName: 'fa-pen'
        },//newly added butons
        {
          actionName: 'UndoCancel',
          iconName: 'fa-plus',
          functionName: 'undoCancel'
        },
        {
          actionName: 'Pending',
          iconName: 'fa-plus',
          functionName: 'setPending'
        },
        {
          actionName: 'BackToCompleted',
          iconName: 'fa-plus',
          functionName: 'backToCompleted'
        },
        {
          actionName: 'BackToInProgress',
          iconName: 'fa-plus',
          functionName: 'backToInProgress'
        },
        {
          actionName: 'RequestQuotation',
          iconName: 'fa-plus',
          functionName: 'requestQuotation'
        },
        {
          actionName: 'AwaitingPayment',
          iconName: 'fa-plus',
          functionName: 'postAwaitingPayment'
        },
        {
          actionName: 'EditQuotation',
          iconName: 'fa-donate',
          functionName: 'openPaymentDialog'
        },
        {
          actionName: 'ModifyInstructions',
          iconName: 'fa-plus'
        },

      ],
    agentActionButtons: [
      {
        actionName: 'UpdateProfile',
        iconName: 'fa-edit',
        functionName: 'showeditAgentDialog'
      },
      {
        actionName: 'UpdateAgentReference',
        iconName: 'fa-barcode',
        functionName: 'showEditAgentCodeDialog'
      },
      {
        actionName: 'UpdateCustomCodeQuota',
        iconName: 'fa-handshake',
        functionName: 'showEditAgentQuotaDialog'
      },
      {
        actionName: 'UpdateAgentCommissionParameters',
        iconName: 'fa-percent',
        functionName: 'showEditComissionsDialog'
      },
      {
        actionName: 'ActivateAgent',
        iconName: 'fa-check',
        functionName: 'showDeactivateDialog'
      },
      {
        actionName: 'DeactivateAgent',
        iconName: 'fa-times',
        functionName: 'showDeactivateDialog'
      },
      {
        actionName: 'CreateWithdrawal',
        iconName: 'fa-wallet',
        functionName: 'createWithdrawal'

      },
      {
        actionName: 'EditWithdrawal',
        iconName: 'fa-edit',
        functionName: 'openUndoDialog'
      },
      {
        actionName: 'EditPaymentMethod',
        iconName: 'fa-plus'
      },
      {
        actionName: 'CreateNew',
        iconName: 'fa-plus',
        functionName: 'openUndoDialog'
      },
    ],
    customerActionButtons: [
      {
        actionName: 'ViewQuote',
        iconName: 'fa-edit',
        functionName: 'ViewQuote'
      },
      {
        actionName: 'UpdateProfile',
        iconName: 'fa-edit',
        functionName: 'showEditCustomerDialog'
      },
      {
        actionName: 'CreateNewProject',
        iconName: 'fa-plus',
        functionName: 'addProject'
      },
      {
        actionName: 'TransferToCsa',
        iconName: 'fa-user-tie',
        functionName: 'transferCSR'
      },
      {
        actionName: 'SwitchToNotConverted',
        iconName: 'fa-edit',
        functionName: 'setNotConvertedStatus'
      },
      {
        actionName: 'SwitchToLost',
        iconName: 'fa-edit',
        functionName: 'setLostStatus'
      },
      {
        actionName: 'CreateNewCollectionPlan',
        iconName: 'fa-credit-card',
        functionName: 'CreateNewCollectionPlan'
      },
      {
        actionName: 'Edit',
        iconName: 'fa-edit',
        functionName: 'Edit'
      },
      {
        actionName: 'Confirm',
        iconName: 'fa-check',
        functionName: 'Confirm'
      },
      {
        actionName: 'UndoConfirm',
        iconName: 'fa-times',
        functionName: 'UndoConfirm'
      },
      {
        actionName: 'SwitchToReadyToStart',
        iconName: 'fa-times',
        functionName: 'SwitchToReadyToStart'
      },
      {
        actionName: 'SwitchToAwaitingPayment',
        iconName: 'fa-times',
        functionName: 'SwitchToAwaitingPayment'
      },
      {
        actionName: 'Cancel',
        iconName: 'fa-times',
        functionName: 'Cancel'
      },
      {
        actionName: 'EditCollection',
        iconName: 'fa-edit',
        functionName: 'EditCollection'
      }
    ]
  };

  public get configs(): any {
    return this.defaults;
  }
}
