<div #container [ngStyle]="style" [class]="styleClass" style="position: relative;">
  <div class="table-loading table-overlay" *ngIf="loading && showLoader" style="display: flex; justify-content: center; align-items: center;">
    <div class="table-loading-spinner" *ngIf="loading && showLoader">
      <img src="assets/media/nisloimg/loading.gif" width="48">
    </div>
  </div>

  <div *ngIf="captionTemplate" class="table-caption table-header">
    <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
  </div>
  <div class="table-wrapper table-responsive">
    <table
      border="0"
      #table
      class="nislo-datatable nislo-table-scroll"
      [ngClass]="tableStyleClass"
      [ngStyle]="tableStyle"
      [ngClass]="{ 'hoverable-rows': rowHover || selectionMode }"
    >
    <colgroup>
      <ng-container
        *ngTemplateOutlet="colGroupTemplate; context: { $implicit: columns }"
      ></ng-container>
    </colgroup>      
      <thead class="table-thead">
        <ng-container
          *ngTemplateOutlet="headerTemplate; context: { $implicit: columns }"
        ></ng-container>
      </thead>
      <tbody
        class="table-tbody color-line"
        [nrTableBody]="columns"
        [nrTableBodyTemplate]="bodyTemplate"
      ></tbody>
      <tfoot *ngIf="footerTemplate" class="table-tfoot">
        <ng-container
          *ngTemplateOutlet="footerTemplate; context: { $implicit: columns }"
        ></ng-container>
      </tfoot>
    </table>
  </div>
  <div *ngIf="summaryTemplate" class="table-summary table-header">
    <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
  </div>
  <nr-paginator
    *ngIf="paginator"
    [rows]="rows"
    [first]="first"
    [totalRecords]="totalRecords"
    [pageLinkSize]="pageLinks"
    styleClass="w-100 my-4"
    [alwaysShow]="alwaysShowPaginator"
    (onPageChange)="onPageChange($event)"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [templateLeft]="paginatorLeftTemplate"
    [templateRight]="paginatorRightTemplate"
    [dropdownAppendTo]="paginatorDropdownAppendTo"
    [showCurrentPageReport]="showCurrentPageReport"
  ></nr-paginator>
</div>
