import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import * as objectPath from 'object-path';
import { Subscription, Observable } from 'rxjs';

import {
  LayoutConfigService,
  MenuConfigService,
  HtmlClassService,
  PageConfigService,
} from '../../base/services';
import { Permission } from 'src/app/core/models';
import { LayoutConfig, MenuConfig, PageConfig } from '../../base/config';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public constructor
  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;

  // Private properties
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private currentUserPermissions$: Observable<Permission[]>;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param menuConfigService: MenuConfifService
   * @param pageConfigService: PageConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private permissionsService: NgxPermissionsService,
    private render: Renderer2
  ) {
    this.loadRolesWithPermissions();

    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const layoutSubdscription =
      this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
        // reset body class based on global and page level layout config, refer to html-class.service.ts
        document.body.className = '';
        this.htmlClassService.setConfig(layoutConfig);
      });
    this.unsubscribe.push(layoutSubdscription);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');

    // let the layout type change
    const layoutConfigSubscription =
      this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
        setTimeout(() => {
          this.selfLayout = objectPath.get(cfg, 'self.layout');
        });
      });
    this.unsubscribe.push(layoutConfigSubscription);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  /**
   * NGX Permissions, init roles
   */
  loadRolesWithPermissions() {
    // this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
    // const subscr = this.currentUserPermissions$.subscribe(res => {
    //   if (!res || res.length === 0) {
    //     return;
    //   }
    //   this.permissionsService.flushPermissions();
    //   res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
    // });
    // this.unsubscribe.push(subscr);
  }

  closeResponsiveMenu() {
    // responsiveMenu = false;
    if (document.body.classList.contains('kt-aside--on')) {
      this.render.removeClass(document.body, 'kt-aside--on');
      this.render.removeClass(
        document.getElementById('kt_aside'),
        'kt-aside--on'
      );
    } else {
      this.render.addClass(document.body, 'kt-aside--on');
      this.render.addClass(document.getElementById('kt_aside'), 'kt-aside--on');
    }
  }
}
