<!-- begin:: Brand -->
<!-- <div class="kt-aside__brand m-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_aside_brand"> -->

<div
  class="kt-aside__brand m-grid__item"
  [ngClass]="htmlClassService.getClasses('brand', true)"
  id="kt_aside_brand"
>
  <div class="kt-aside__brand-logo">
    <a href="javascript:;" routerLink="/orders">
      <img alt="logo" [src]="headerLogo" />
    </a>
  </div>
  <div class="kt-aside__brand-tools">
    <button
      ktToggle
      [options]="toggleOptions"
      class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
      id="kt_aside_toggler"
    >
      <span
        [inlineSVG]="
          './assets/media/icons/svg/Navigation/Angle-double-left.svg'
        "
      ></span>
      <!--<span [inlineSVG]="'./assets/media/icons/svg/Navigation/Angle-double-right.svg'"></span>-->
      <span
        class="nislo-logo-small-svg"
        [inlineSVG]="'./assets/media/nisloimg/icon-unihelper-white.svg'"
      ></span>
    </button>
  </div>
</div>
<!-- end:: Brand -->

<style>
  .nislo-logo-small-svg {
    width: 50px !important;
    height: 50px !important;
  }
</style>
