import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { environment as env } from 'src/environments/environment';
import { CreateUserRequest, ResetPasswordRequest, SearchResult, UpdateUserRequest, User } from '../../models';

const API: any = {
  users: 'users',
  resetPassword: 'reset-tempory-password',
  restoreUser: 'restore-user'
};

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private readonly http: HttpClient) { }

  getUsers(query: QueryParamsModel): Observable<SearchResult<User>> {
    return this.http
      .get<any>(env.apiUrl + API.users + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10000))));
  }

  getUser(id: number): Observable<any> {
    return this.http
      .get<any>(`${env.apiUrl}${API.users}/${id}`);
  }

  createUser(request: CreateUserRequest): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.users}`,
      request
    );
  }

  updateUser(request: UpdateUserRequest): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.users}/${request.id}`,
      request
    );
  }

  deactivateUser(userId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.users}/${userId}`
    );
  }

  activateUser(userId: number): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.users}/${userId}/${API.restoreUser}`, ''
    );
  }

  resetPassword(request: ResetPasswordRequest): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.users}/${API.resetPassword}`,
      request
    );
  }
}
