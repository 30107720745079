import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LookupService, ProjectService} from "../../../../../../core/services";
import {ErrorService} from "../../../../../../core/services/error/error.services";
import {ToastrService} from "ngx-toastr";
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan-status-update',
  templateUrl: './plan-status-update.component.html',
  styleUrls: ['./plan-status-update.component.scss']
})
export class PlanStatusUpdateComponent implements OnInit {
  collectionStatuses: any;

  submitted = false;
  planStatus: number;
  jsonDate: any;

  constructor(
      private readonly lookupService: LookupService,
      private readonly projectService: ProjectService,
      public dialogRef: MatDialogRef<PlanStatusUpdateComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA)
      public data: any,
      private readonly errorService: ErrorService,
      private readonly toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadLookups()
  }

  loadLookups() {
    this.lookupService.getCollectionStatuses().subscribe({
      next: result => {
        this.collectionStatuses = result
      }
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.submitted = true;

    if (this.jsonDate && this.planStatus) {
      
      const paidDate = new Date(this.jsonDate['year'], this.jsonDate['month']-1, this.jsonDate['day']+1,0,0,0,0); 

      let request = {
        statusId: this.planStatus,
        paidDate: paidDate.toISOString().replace("T21","T00")
      }
      
      this.projectService.updateCollectionStatus(this.data['collectionPlanId'], this.data['collectionId'], request)
          .subscribe({
            next: (_) => {
              this.toastrService.success('Successfully Updated')
              this.dialogRef.close()
            },
            error: error => {
              this.errorService.showGenericErrorMessage(error)
            }
          })
    }

  }

}
