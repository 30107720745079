<div id="NisloModal">
  <form [formGroup]="changePasswordForm" *ngIf="this.panel !== 'success'">
    <!-- header -->
    <div class="row mb-4">
      <div class="col-sm">
        <div class="pageSubHeader">
          <h3 class="page-title">Change Password</h3>
        </div>
      </div>
    </div>
    <!-- /header -->

    <!-- row -->
    <div class="row nislo-form">
      <div class="col-sm-12 form-group">
        <label>Current Password</label>
        <input
          type="password"
          placeholder="Current Password"
          class="form-control"
          formControlName="currentPassword"
          [ngClass]="{ 'is-invalid': submitted && f['currentPassword'].errors }"
        />
        <div
          *ngIf="submitted && f['currentPassword'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="f['currentPassword'].errors['required']">
            Password is required
          </div>
        </div>
      </div>
      <div class="col-sm-12 form-group">
        <label>New Password</label>
        <input
          type="password"
          placeholder="New Password"
          class="form-control"
          formControlName="newPassword"
          [ngClass]="{ 'is-invalid': submitted && f['newPassword'].errors }"
        />
        <div
          *ngIf="submitted && f['newPassword'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="f['newPassword'].errors['required']">
            Password is required
          </div>
          <div *ngIf="f['newPassword'].errors['minlength']">
            Password must be at least 6 characters
          </div>
          <div
            *ngIf="
              changePasswordForm.controls['newPassword'].hasError('hasNumber')
            "
          >
            Passwords must have at least one digit ('0'-'9').
          </div>

          <div
            *ngIf="
              changePasswordForm.controls['newPassword'].hasError(
                'hasCapitalCase'
              )
            "
          >
            Passwords must have at least one uppercase ('A'-'Z').
          </div>
          <div
            *ngIf="
              changePasswordForm.controls['newPassword'].hasError(
                'hasSmallCase'
              )
            "
          >
            Passwords must have at least one lowercase ('a'-'z').
          </div>
          <div
            *ngIf="
              changePasswordForm.controls['newPassword'].hasError(
                'hasSpecialCharacters'
              )
            "
          >
            Passwords must have at least 1 Special Character!
          </div>
        </div>
      </div>
      <div class="col-sm-12 form-group">
        <label>New Password Again</label>
        <input
          type="password"
          placeholder="New Password Again"
          class="form-control"
          formControlName="newPasswordAgain"
          [ngClass]="{
            'is-invalid': submitted && f['newPasswordAgain'].errors
          }"
        />
        <div
          *ngIf="submitted && f['newPasswordAgain'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="f['newPasswordAgain'].errors['required']">
            Confirm Password is required
          </div>
          <div *ngIf="f['newPasswordAgain'].errors['mustMatch']">
            Passwords must match
          </div>
        </div>
      </div>
    </div>
    <!-- /row -->

    <!-- row -->
    <div class="text-right">
      <button
        type="button"
        class="btn btn-white mr-3"
        data-dismiss="modal"
        (click)="onCancelClick()"
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn btn-blue w-75"
        style="width: auto !important"
        (click)="onSubmit()"
      >
        Change Password
      </button>
    </div>
    <!-- /row -->
  </form>

  <!-- row -->
  <div class="row nislo-form" *ngIf="this.panel === 'success'">
    <div class="col-sm mb-4">
      <div class="pageSubHeader">
        <h3 class="page-title">Success</h3>
      </div>
    </div>

    <div class="col-sm-12 form-group mb-4">
      <p class="gray-text f12">Your password has been changed successfully</p>
    </div>

    <div class="col-sm-12 text-right">
      <button
        type="button"
        class="btn btn-blue"
        data-dismiss="modal"
        (click)="onCancelClick()"
      >
        Okay
      </button>
    </div>
  </div>
  <!-- /row -->
</div>
