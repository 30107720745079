import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { environment as env } from 'src/environments/environment';
import { CreateCustomerRequest, Customer, SearchResult, UpdateCustomerRequest, UpdateManagedCustomerRequest } from '../../models';

const API: any = {
  customers: 'customers',
  managedCustomers: 'customers/managed',
  statusHistory: 'status-history',
  actions: 'actions',
  notConverted: 'not-converted',
  lost: 'lost',
  csaHistory: 'csa-history'
};

@Injectable({ providedIn: 'root' })
export class CustomerService {

  constructor(private readonly http: HttpClient) { }

  getClients(query: QueryParamsModel): Observable<SearchResult<Customer>> {
    return this.http
      .get<any>(env.apiUrl + API.customers + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getClient(id: number): Observable<any> {
    return this.http.get<any>(`${env.apiUrl}${API.customers}/${id}`);
  }

  createClient(request: CreateCustomerRequest): Observable<any> {
    return this.http.post<any>(`${env.apiUrl}${API.customers}`, request);
  }

  updateClient(request: UpdateCustomerRequest): Observable<any> {
    return this.http.put<any>(`${env.apiUrl}${API.customers}/${request.id}`, request);
  }

  updateManagedClient(request: UpdateManagedCustomerRequest): Observable<any> {
    return this.http.put<any>(`${env.apiUrl}${API.managedCustomers}/${request.id}`, request);
  }

  deleteClient(clientId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.customers}/${clientId}`
    );
  }

  getCustomerStatusHistory(customerId: number) {
    return this.http
      .get<any>(`${env.apiUrl}${API.customers}/${customerId}/${API.statusHistory}`);
  }

  getActions(customerId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.customers}/${customerId}/${API.actions}`
    );
  }

  putNotConverted(customerId: number, request) {
    return this.http.put<any>(
      `${env.apiUrl}${API.customers}/${customerId}/${API.notConverted}`,
      request
    );
  }

  putLost(customerId: number, request) {
    return this.http.put<any>(
      `${env.apiUrl}${API.customers}/${customerId}/${API.lost}`,
      request
    );
  }

  getQuickFilterCount(filter: any[], keyword: any[], userId: number): Observable<SearchResult<Customer>> {
    const query = new QueryParamsModel();
    query.offset = 0;
    query.limit = 0;

    query.filter.push('statusId==' + filter.map(item => item.id).join(';'));
    if(keyword.length >0){
      query.filter.push(keyword);
    }

    if (userId > 0) {
      query.filter.push(`csaId==${userId}`);
    }

    return this.http.get<any>(env.apiUrl + API.customers + query.toUrlParams());
  }

  getCustomerCsrHistory(customerId: number) {
    return this.http
      .get<any>(`${env.apiUrl}${API.customers}/${customerId}/${API.csaHistory}`);
  }

}
