import { Component, OnInit, Renderer2 } from '@angular/core';
import { ToggleOptions } from 'src/app/shared/base/models/toggle-options.model';
import { LayoutConfigService } from 'src/app/shared/base/services';
// import { ToggleOptions } from 'src/app/shared/base/directives';

@Component({
  selector: 'kt-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  // Public properties
  headerLogo: string;
  asideDisplay: boolean;

  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-header__topbar--mobile-on',
    togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active',
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private render: Renderer2
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.headerLogo = this.layoutConfigService.getLogo();
    this.asideDisplay =
      this.layoutConfigService.getConfig('aside.self.display');
    this.layoutConfigService.responsiveMenu$.subscribe((x) => {
      if (x != null) this.toggleResponsiveMenu();
    });
  }

  responsiveMenu = false;
  toggleResponsiveMenu() {
    if (document.body.classList.contains('kt-aside--on')) {
      this.render.removeClass(document.body, 'kt-aside--on');
      this.render.removeClass(
        document.getElementById('kt_aside'),
        'kt-aside--on'
      );
    } else {
      this.render.addClass(document.body, 'kt-aside--on');
      let element = document.getElementById('kt_aside');
      if (element) this.render.addClass(element, 'kt-aside--on');
    }
    this.responsiveMenu = !this.responsiveMenu;
    this.layoutConfigService.showingResponsiveMenu =
      !this.layoutConfigService.showingResponsiveMenu;
  }
}
