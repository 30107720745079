// Angular
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  isDevMode = false;
  constructor() {
    if (environment.production) {
      this.isDevMode = false;
    } else {
      this.isDevMode = true;

    }
  }

}
