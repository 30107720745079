<div class="wrapper">
  <header class="d-flex align-items-center">
      <img src="../assets/media/logos/logo-unihelper-white-256.png" alt="Nislo Research" >
  </header>
  <div class="container">

      <div class="panel">
          <h1>Oops!</h1>
          <blockquote>
            <p class="quotation"> 
              So certain were you. Go back and closer you must look.
            </p>
            <div class="footer">— Yoda</div>
          </blockquote> 
      </div>



  </div>
</div>
