import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { SearchResult, UserInfo, Customer, Roles } from '../../models';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { delay, take } from 'rxjs/operators';

const API: any = {
  clients: 'customers',
  companies: 'companies',
  users: 'users',
  orders: 'orders',
  projects: 'projects',
  transfer: 'transfer',
};

@Injectable({ providedIn: 'root' })
export class SalesService {
  constructor(private readonly http: HttpClient) {}

  getClients(query: QueryParamsModel): Observable<SearchResult<Customer>> {
    return this.http
      .get<any>(env.apiUrl + API.clients + query.toUrlParams())
      .pipe((delay(1000), take(10)));
  }

  getCompanies(): Observable<any[]> {
    return this.http.get<any[]>(env.apiUrl + API.companies);
  }

  getCsas(): Observable<any> {
    let params = new HttpParams();
    params = params.append('filter', `roleId==${Roles.CSA}`);
    params = params.append('all', `true`);
    return this.http.get<UserInfo[]>(env.apiUrl + API.users, { params });
  }

  createClient(client: any): any {
    return this.http.post<any>(env.apiUrl + API.clients, client);
  }

  // TODO: Move to projectService
  postProject(projectDTO: any) {
    return this.http.post<any>(env.apiUrl + API.projects, projectDTO);
  }

  putCustomerTransferToCSR(customerId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.clients}/${customerId}/${API.transfer}`,
      request
    );
  }

}
