<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
	<div class="kt-footer__copyright">
		{{today | date:'yyyy'}}&nbsp;&copy;&nbsp;<a href="http://keenthemes.com/metronic" target="_blank" class="kt-link">Unihelper</a>
	</div>
	<div class="kt-footer__menu">
		<a href="http://keenthemes.com/metronic" target="_blank" class="kt-footer__menu-link kt-link">About</a>
		<a href="http://keenthemes.com/metronic" target="_blank" class="kt-footer__menu-link kt-link">Privacy</a>
		<a href="http://keenthemes.com/metronic" target="_blank" class="kt-footer__menu-link kt-link">Terms</a>
	</div>
</div>
<!-- end:: Footer -->
