import {
  NotificationObject,
  NotificationResult,
  NotificationToken,
} from './../../models/notification/notifications.model';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';

const API: any = {
  notifications: 'notifications',
  token: 'notifications/token',
};

@Injectable({ providedIn: 'root' })
export class MessagingService {
  freshMessages: Array<NotificationObject> = [];
  activeToken: string | undefined | null;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private readonly http: HttpClient
  ) {
    this.angularFireMessaging.messages.subscribe((x) => {});

    // this.angularFireMessaging.messages.subscribe(
    //   (messaging: AngularFireMessaging) => {
    //     messaging.onMessage = messaging.onMessage.bind(messaging);
    //     messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    //   });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log('tkn : ' + token);
        this.activeToken = token;

        // const notifToken: NotificationToken = { platform: 'Web', token };
        // this.registerDevice(notifToken).subscribe(
        //   (result) => {
        //     console.log('result:', result);
        //   },
        //   (error) => {
        //     console.log('result ERR:', error);
        //   }
        // );
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  getToken() {
    this.angularFireMessaging.getToken.subscribe(
      (token) => {
        console.log('getToken : ' + token);
        this.activeToken = token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  deleteToken() {
    this.angularFireMessaging.deleteToken(this.activeToken!);
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log('new message received. ', JSON.stringify(payload));

      const notifToken: NotificationObject = {
        id: 0,
        title: payload.notification.title,
        body: payload.notification.body,
        click_action: payload.notification.click_action,
        createDate: new Date().toISOString(),
        isCleared: false,
        name: '',
      };

      this.freshMessages.push(notifToken);
    });
  }

  getClearedMessages(query: QueryParamsModel): Observable<NotificationResult> {
    return this.http.get<NotificationResult>(
      env.apiUrl + API.notifications + query.toUrlParams() + `&isCleared=True`
    );
  }

  getNewMessages(query: QueryParamsModel): Observable<NotificationResult> {
    return this.http.get<NotificationResult>(
      env.apiUrl + API.notifications + query.toUrlParams() + `&isCleared=False`
    );
  }

  markAsCleared(notificationId: number, request: any) {
    return this.http
      .put<any>(`${env.apiUrl}${API.notifications}/${notificationId}`, request)
      .subscribe(
        (result) => {
          console.log('result 000', result);
        },
        (error) => {
          console.log('result 000', error);
        }
      );
  }

  registerDevice(request: NotificationToken) {
    /* const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${userToken}`,
        'X-Correlation-Id': this.generateUuid()
      }),
      body: request,
    };
 */

    return this.http.post<any>(`${env.apiUrl}${API.token}`, request);
  }

  deRegisterDevice(request: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };

    return this.http.delete<any>(`${env.apiUrl}${API.token}`, options);
  }

  private generateUuid(): string {
    return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (ch) => {
      const c = Number(ch);
      // tslint:disable-next-line: no-bitwise
      return (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    });
  }
}
