import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NisloGridComponent } from '../grid.component';

@Component({
  selector: 'nr-sort-icon',
  templateUrl: 'sort-icon.component.html',
})
export class SortIconComponent implements OnInit, OnDestroy {
  @Input() field: string;

  subscription: Subscription;

  sortOrder: number;

  constructor(public dt: NisloGridComponent) {
    this.subscription = this.dt.gridService.sortSource$.subscribe(
      (sortMeta) => {
        this.updateSortState();
      }
    );
  }

  ngOnInit() {
    this.updateSortState();
  }

  onClick(event: Event) {
    event.preventDefault();
  }

  updateSortState() {
    this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
