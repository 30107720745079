// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Subject } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Lodash
import { merge } from 'lodash';

@Injectable()
export class PageConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  pageConfig: any;

  /**
   * Service Constructor
   *
   * @param router: Router
   */
  constructor(private router: Router) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Get current page config based on route
   */
  getCurrentPageConfig(path?: string): any {
    const url = this.router.url;
    let configPath = url.replace(/\//g, '.'); // There was a dot (.). I removed it.

    if (path) {
      configPath += '.' + path;
    }
    configPath = configPath.substr(1, configPath.length);

    // get page config by path
    return objectPath.get(this.pageConfig, configPath);
  }

  /**
   * Set existing config with a new value
   * @param value: any
   * @param sav: boolean?
   */
  setConfig(value: any, save?: boolean): void {
    this.pageConfig = merge(this.pageConfig, value);

    if (save) {
      // not implemented
    }

    // fire off an event that all subscribers will listen
    this.onConfigUpdated$.next(this.pageConfig);
  }

  /**
   * Load confgis
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.pageConfig = config;
    this.onConfigUpdated$.next(this.pageConfig);
  }
}
