import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus, ProjectStatusId } from 'src/app/core/models';

@Pipe({ name: 'PaymentCategoryColorPipe' })
export class PaymentCategoryColorPipe implements PipeTransform {
  transform(paymentCategoryId: any, colorType?: string) {
    if (colorType === 'bg') {
      switch (paymentCategoryId) {
        case 1:
          return 'badge-clr-darkgreen';
        case 2:
          return 'badge-clr-darkyellow';
        case 3:
          return 'badge-clr-darkred';
        case 4:
          return 'badge-clr-darkblue';
        default:
          return 'badge-clr-white';
      }
    }

    if (colorType === 'border') {
      switch (paymentCategoryId) {
        case 1:
          return 'border-darkgreen';
        case 2:
          return 'border-darkyellow';
        case 3:
          return 'border-darkred';
        case 4:
          return 'border-darkblue';
        default:
          return 'border-gray';
      }
    }


    switch (paymentCategoryId) {
      case 1 || '1':
        return 'badge-clr-darkgreen';
      case 2:
        return 'badge-clr-darkyellow';
      case 3:
        return 'badge-clr-darkred';
      case 4 || '4':
        return 'badge-clr-darkblue';
      default:
        return 'badge-clr-white';
    }





  }
}
