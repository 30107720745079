import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
  HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NisloGridComponent } from '../grid/grid.component';

@Directive({
  selector: '[nrSortableColumn]',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '[class.sortable-column]': 'isEnabled()',
    '[class.sort]': 'sorted',
    '[attr.tabindex]': 'isEnabled() ? "0" : null'
  }
})
export class SortableColumnDirective implements OnInit, OnDestroy {
  @Input('nrSortableColumn') field: string;

  @Input() pSortableColumnDisabled: boolean;

  sorted: boolean;

  subscription: Subscription;

  constructor(public dt: NisloGridComponent) {
    if (this.isEnabled()) {
      this.subscription = this.dt.gridService.sortSource$.subscribe(
        sortMeta => {
          this.updateSortState();
        }
      );
    }
  }

  ngOnInit() {
    if (this.isEnabled()) {
      this.updateSortState();
    }
  }

  updateSortState() {
    this.sorted = this.dt.isSorted(this.field);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.isEnabled()) {
      this.updateSortState();
      this.dt.sort({
        originalEvent: event,
        field: this.field
      });
    }
  }

  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: MouseEvent) {
    this.onClick(event);
  }

  isEnabled() {
    return this.pSortableColumnDisabled !== true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
