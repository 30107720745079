import { Injectable } from '@angular/core';

import { StorageType, StorageService } from './local-storage.interface';
import { LocalStorage } from './local-storage';
import { LocalVariable } from './local-variable';
import { SessionStorage } from './session-storage';

@Injectable()
export class LocalStorageService {
  private static StorageTypePriority: StorageType[] = [
    StorageType.LocalStorage,
    StorageType.Session,
    StorageType.LocalVariable,
    StorageType.Cookie
  ];
  private storageService: StorageService;

  constructor() {
    const storageService = this.selectStorageService();
    if (!storageService) {
      throw Error('None of the selected services are supported');
    }
    this.storageService = storageService;
  }

  getItem(key: string): string | null {
    return this.storageService.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.storageService.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storageService.removeItem(key);
  }

  clear(keepSession: boolean = true): void {
    this.storageService.clear(keepSession);
  }

  private selectStorageService(): StorageService | null {
    for (const storageType of LocalStorageService.StorageTypePriority) {
      let storageService: StorageService;
      switch (storageType) {
        case StorageType.Session:
          storageService = new SessionStorage();
          break;
        case StorageType.LocalStorage:
          storageService = new LocalStorage();
          break;
        case StorageType.LocalVariable:
          storageService = new LocalVariable();
          break;
        default:
          throw Error(`Unknown storage type: ${storageType}`);
      }

      if (storageService.isSupported()) {
        return storageService;
      }
    }

    return null;
  }
}
