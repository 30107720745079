import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from "moment";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "../../../../../../../environments/environment";
import { AuthService, LookupService, ProjectService } from "../../../../../../core/services";
import { ConfirmationComponent } from "../../../../../project/pages/confirmation/confirmation.component";
import { ErrorService } from "../../../../../../core/services/error/error.services";
import { ActionButton } from "../../../../../../core/models/project/action-button.model";
import { ToastrService } from "ngx-toastr";
import { Clipboard } from '@angular/cdk/clipboard';
import {PlanStatusUpdateComponent} from "../plan-status-update/plan-status-update.component";
import { User } from 'src/app/core/models';
import {PlanHistoryComponent} from "../plan-history/plan-history.component";

@Component({
  selector: 'app-flexible-payment',
  templateUrl: './flexible-payment.component.html',
  styleUrls: ['./flexible-payment.component.scss']
})
export class FlexiblePaymentComponent implements OnInit {

  @Input() projects: any[];
  @Input() customer: string; //customer details
  @Input() totalPrice: number; //total price of all projects
  @Input() rowPrice: number;
  @Input() defaultPrice: number[] = [];
  @Input() projectIds: number[];
  @Input() customerId: string;
  @Input() diff: number = 0;
  @Input() type: string;
  @Input() planId: number;
  @Output() changeType = new EventEmitter<string>();
  @Output() changedFormPrice = new EventEmitter<number>();
  @Output() formLen = new EventEmitter<number>();
  @Output() difference = new EventEmitter<number>();
  @Output() emitPlans = new EventEmitter<any>();
  @Output() status = new EventEmitter<string>();
  @Output() closeForm = new EventEmitter<any>();
  @Output() sendTotalPaidPrice = new EventEmitter<any>();

  currentUser: User;
  currency = environment.currency;
  totalFormPrice = 0; //total price in the form inputs
  notMatch = true
  priceValidation: boolean[] = [];
  paymentMethodValidation: boolean[] = [];
  paymentDateValidation: boolean[] = [];
  collectionMethods: any[];
  plans: any[] = [];
  collectionStatuses: any[];
  actions: ActionButton[] = [];
  collectionPlanId: number;

  isPaid: any[] = [];
  totalPaidPrice = 0;


  submitted = false;
  loading = false;
  closeModalText: string;

  validations: any[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly projectService: ProjectService,
    private readonly lookupService: LookupService,
    private readonly errorService: ErrorService,
    public dialog: MatDialog,
    private readonly clipboard: Clipboard,
    private readonly toastrService: ToastrService,
  ) {
    this.currentUser = this.authService.currentUserValue;
   }

  ngOnInit(): void {
    if (this.type === 'view') {
      this.getPlans()
      this.getActions()
      this.closeModalText = 'Close'
    } else {
      this.addForm()
      this.loadCollectionMethods()
      this.validation(false)
      this.totalPriceValue()
      this.closeModalText = 'Cancel'
    }
  }

  doAction(button: ActionButton, data?: any) {
    if (!button.isAvailable) {
      this.toastrService.error(button.message, 'Not Allowed');
      return;
    }
    this[button.actionName](data);
  }

  Edit() {
    this.plans.map((row, i) => {
      this.defaultPrice[i] = row['amount']
    })
    this.closeModalText = 'Cancel'
    this.type = 'edit';
    this.changeType.emit(this.type)
  }

  Confirm() {
    this.projectService.putConfirmPlan(this.planId).subscribe({
      next: (_) => {
        this.toastrService.success('Successfully Confirmed')
        this.closeForm.emit('')
      },
      error: error => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  UndoConfirm() {
    this.projectService.putUndoConfirmPlan(this.planId).subscribe({
      next: (_) => {
        this.toastrService.success('Successfully Undo Confirmation')
        this.closeForm.emit('')
      },
      error: error => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  Cancel() {
    this.projectService.deletePlan(this.planId).subscribe({
      next: (_) => {
        this.toastrService.success('Successfully Cancelled This Plan')
        this.closeForm.emit('')
      },
      error: error => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  SwitchToReadyToStart() {
    this.projectService.putPlanReadyToStart(this.planId).subscribe({
      next: (_) =>  {
        this.toastrService.success('Successfully Switched Ready To Start')
        this.closeForm.emit('')
      },
      error: error => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  SwitchToAwaitingPayment() {
    this.projectService.putPlanAwaitingPayment(this.planId).subscribe({
      next: (_) =>  {
        this.toastrService.success('Successfully Switched Awaiting Payment')
        this.closeForm.emit('')
      },
      error: error => {
        this.errorService.showGenericErrorMessage(error)
      }
    })
  }

  ViewQuote(){
    window.open('/quote/'+this.planId,'_blank' );
  }

  updateCollectionStatus(i) {
    const dialogRef = this.dialog.open(PlanStatusUpdateComponent, {
      disableClose: true,
      data: {
        collectionPlanId: this.collectionPlanId,
        collectionId: this.plans[i]['id'],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit()
    })
  }

  getActions() {
    this.projectService.getCollectionPlanActions(this.planId).subscribe({
      next: result => {
        result.push({
          "actionName": "ViewQuote",
          "functionName": "ViewQuote",
          "actionLabel": "View Quote",
          "isAvailable": true,
          "hasAuthorization": true,
          "inActionList": true,
          "ordinal": 400
        })

        this.actions = result
      }
    })
  }

  getPlans() {
    this.totalPaidPrice = 0;
    this.projectService.getCollections(this.planId).subscribe({
      next: result => {
        this.plans = result.collections
        this.collectionPlanId = result.id
        this.status.emit(result.status)
        this.plans.forEach((row, i) => {
          if (row.dueDate) {
            let date = new Date(row['dueDate'])
            row['jsonDate'] = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
          }

          if (row['status'] === 'Paid')
          {
            this.isPaid.push({
              index: i,
              price: row['amount'],
              status: row['status'],
            })
          }
        })
        this.isPaid.map(value => (
            this.totalPaidPrice += value.price
        ))
        this.sendTotalPaidPrice.emit(this.totalPaidPrice)
        this.loadCollectionMethods()
        this.emitPlans.emit(this.plans)
        this.changeFormLen(this.plans.length - this.isPaid.length)

      }
    })
  }

  collectionMethodFilter() {
    this.plans.map((row, i) => (
      this.collectionMethods.map(x => (
        row.collectionMethodId == x.id ? this.plans[i]['methodDisplayText'] = x.label : null
      ))
    ))
  }

  collectionStatusFilter() {
    this.plans.map((row, i) => (
      this.collectionStatuses.map(x => (
        row.statusId === x.id ? this.plans[i]['statusDisplayText'] = x.label : null
      ))
    ))
  }

  loadCollectionMethods() {
    this.lookupService.getCollectionMethods().subscribe({
      next: result => {
        this.collectionMethods = result
        this.type === 'view' ? this.collectionMethodFilter() : null
      }
    })
  }

  copyLink(x) {
    this.projectService
      .getPaymentLink(this.planId, x.id)
      .subscribe({
        next: result => {
          this.clipboard.copy(result.link);
          this.toastrService.success(
            'Payment Link successfully copied',
            'Payment Link status'
          );
        },
        error: (error) => {
          this.errorService.showGenericErrorMessage(error);
        }
      });
  }

  addPrice(i) {
    this.defaultPrice[i] += this.diff
    this.totalPriceValue()
  }

  totalPriceValue() {
    this.notMatch = true
    this.totalFormPrice = 0

    this.defaultPrice.map(row => (
      row !== null ? this.totalFormPrice += row : null
    ))
    this.changedFormPrice.emit(this.totalFormPrice)
  }

  addForm(): void {
    this.plans[this.plans.length] = {
      price: null,
      collectionMethodId: null,
      dueDate: null,
      jsonDate: { day: null, month: null, year: null },
      statusId: null,
    }



    let len = this.plans.length - this.isPaid.length
    this.changeFormLen(len)

    if (this.submitted) {
      this.validations[len - 1] = [];
      this.validations[len - 1]['price'] = true;
      this.validations[len - 1]['paymentMethod'] = this.validations[len - 1]['paymentDate'] = false;
    }

    this.totalPriceValue()
  }

  removeForm(i: number) {
    this.plans.splice(i, 1)
    this.defaultPrice.splice(i, 1)
    this.validations.splice(i, 1)
    let len = this.plans.length - this.isPaid.length
    this.changeFormLen(len)
  }

  changeFormLen(len) {
    this.notMatch = true
    this.totalFormPrice = 0
    this.rowPrice = 0;

    this.totalFormPrice = this.totalPrice
    this.changedFormPrice.emit(this.totalFormPrice)
    this.formLen.emit(len)
    let price = this.totalPrice - this.totalPaidPrice
    this.rowPrice = price / len

    if (this.type === 'create')
    {
      this.plans.map((row, i) => {
        row.status !== 'Paid'
            ? this.defaultPrice[i] = Number(this.rowPrice.toFixed(2)) //here
            : this.defaultPrice[i] = row['amount']
      })
    }
    else
    {
      this.defaultPrice[this.defaultPrice.length] = 0;
    }
    price = 0;
    this.defaultPrice.map(row => (
        price += row
    ))
    this.difference.emit(this.totalPrice - price)
  }

  onCancelClick(): void {
    this.closeForm.emit('')
    this.loading = false;
    this.submitted = false;

  }

  validation(save) {
    this.plans.forEach((row, i) => {
      let jsonDate = row['jsonDate'];
      if (jsonDate['year'] && jsonDate['month'] && jsonDate['day']) {
        row['dueDate'] = new Date();
        row['dueDate'].setDate(jsonDate['day']);
        row['dueDate'].setMonth(jsonDate['month'] - 1);
        row['dueDate'].setFullYear(jsonDate['year']);
        row['dueDate'] = row['dueDate'].toISOString()
      }

      row['amount'] = this.defaultPrice[i];
    })

    this.validations = [];
    for (let i = 0; i < this.plans.length; i++) {
      this.validations[i] = [];
      this.validations[i]['price'] =
        this.validations[i]['paymentMethod'] =
        this.validations[i]['statusId'] =
        this.validations[i]['paymentDate'] = false;

      this.plans[i]['amount'] ? this.validations[i]['price'] = true : this.validations[i]['price'] = false
      this.plans[i]['collectionMethodId'] ? this.validations[i]['paymentMethod'] = true : this.validations[i]['paymentMethod'] = false
      this.plans[i]['dueDate'] ? this.validations[i]['paymentDate'] = true : this.validations[i]['paymentDate'] = false
      this.plans[i]['statusId'] ? this.validations[i]['statusId'] = true : this.validations[i]['statusId'] = false

    }
    const isRequired = this.validations.find(val => (!val.price || !val.paymentMethod || !val.paymentDate)) ?? null

    if (save) {
      this.submitted = true
      if (!isRequired) {
        this.onNextClick()
      }

    }
  }

  onNextClick() {
    let total: number = 0;
    this.defaultPrice.map(x => (
      total += x
    ))
    let diff = this.totalPrice - total;


    this.totalPriceValue()

    if (diff === 0) {
      this.notMatch = true
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        disableClose: true,
        data: {
          dialogMsg: 'Confirm payment for this project',
        },
      });

      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          if (this.type === 'create') {
            let request = {};
            request = {
              customerId: parseInt(this.customerId),
              projectIds: this.projectIds,
              collections:
                this.plans.map((row, i) => (
                  {
                    collectionMethodId: row['collectionMethodId'],
                    amount: row['amount'],
                    dueDate: row['dueDate'],
                    statusId: row['statusId'],
                  }
                ))
            }
            this.projectService
              .postCollectionPlans(parseInt(this.customerId), request)
              .subscribe({
                next: (_) => {
                  this.closeForm.emit('')
                },
                error: (error) => {
                  this.errorService.showGenericErrorMessage(error);
                }
              });
          } else if (this.type === 'edit') {

            let collections = this.plans.filter((row, i) => (
                row.status !== 'Paid'
            ))
            let request = {};
            request = {
              projectIds: this.projectIds,
              collections:
                  collections.map((row, i) => (
                  {
                    collectionMethodId: row['collectionMethodId'],
                    amount: row['amount'],
                    dueDate: row['dueDate'],
                    statusId: row['statusId'],
                  }
                ))
            }

            this.projectService
              .editCollectionPlans(this.planId, request)
              .subscribe({
                next: (_) => {
                  this.closeForm.emit('')
                },
                error: (error) => {
                  this.errorService.showGenericErrorMessage(error);
                }
              });
          }
        }
      })
    } else if (diff < 1 && diff > 0) {
      this.defaultPrice[0] += diff;
      this.plans[0]['price'] += diff;
      this.totalPriceValue()
      this.onNextClick()
    } else {
      this.notMatch = false
    }
  }

}