export class Permission {
  id: number;
  title: string;
  level: number;
  parentId: number;
  isSelected: boolean;
  name: string;
  children: Permission[];
  isEditMode = false;
  userId = 0;
  createdDate: string;
  updatedDate: string;

  clear(): void {
    this.id = undefined;
    this.title = '';
    this.level = 1;
    this.parentId = undefined;
    this.isSelected = false;
    this.name = '';
    this.children = [];
  }
}
