<div id="NisloModal" class="modal-largestx mh-90"> <!-- madal-largestx -->
    <div class="_loading" *ngIf="loading">
        <img src="assets/media/nisloimg/loading.gif" width="48">
    </div>
    <div class="row mb-3">
        <div class="col-md-6 me-auto">
            <div class="pageSubHeader d-flex justify-content-between">
                <h3 class="page-title pt-0">Plan Payment</h3>
                <h5 *ngIf="data?.quoteRef">Quote Ref: {{data.quoteRef}}</h5>
                <h5 *ngIf="data.type !== 'create'">
                    {{collectionStatus}}
                    <i (click)="openPlanHistory()" class="fas fa-history text-success mx-2 cursor-pointer"></i>
                </h5>
            </div>
        </div>
        <div class="col-md-6 ms-auto ps-3">
            <div class="pageSubHeader d-flex justify-content-between">
                <div>
                    <span class="value-text pe-1">Planned</span>
                    <span class="value-num">
                    {{
                        data.type !== 'view' ?
                            (totalPrice | currency: currency) :
                            (totalFormPrice | currency: currency)
                        }}
                </span>
                </div>

                <div>
                    <span class="value-text pe-1">To be planned</span>
                    <span class="value-num text-success" [ngClass]="{'text-danger': difference != 0}">
                    {{difference | currency: currency}}
                </span>
                </div>

                <button
                        (click)="onCancelClick()"
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <i class="fas fa-times" style="font-size: 14px"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-3 m-0">
            <div class="col-md-5 col-sm-12">
                <table class="table table-borderless _scroll-table border">
                    <thead>
                        <tr>
                            <th>Ref ID</th>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Deadline</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let project of orders; let i = index;">
                            <td>
                                <div *ngIf="data.type !== 'view'">
                                    <mat-checkbox
                                        #isSelected
                                        [checked]="selectedProjectsId[0].includes(project.id)"
                                        value="{{project.id}}"
                                        (change)="isSelected.checked ?
                                        addSelectedProject(isSelected.value) :
                                        removeSelectedProject(isSelected.value)"
                                    >
                                        {{project['referenceNumber']}}
                                    </mat-checkbox>
                                </div>

                                <div *ngIf="data.type == 'view'">
                                    {{project['referenceNumber']}}
                                </div>
                            </td>
                            <td class="w-25">
                                <div class="d-flex align-items-center">
                                    <div
                                        ngbTooltip="{{project['moduleTitle']}}"
                                        placement="top"
                                        triggers="mouseenter:mouseleave"
                                        class="title-fix text-wrap _clickable"
                                        (click)="openPaymentDialog(project['id'], project['baseWordCount'], project['academicLevelId'], project['projectTypeId'],project['moduleTitle'])"
                                    >
                                        {{project['moduleTitle']}}
                                        <i class="fas fa-pen"></i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p>
                                    {{ project['givenPrice'] ?
                                    (project['givenPrice'] | currency: currency) :
                                    (0 | currency: currency) }}
                                </p>
                            </td>
                            <td>
                                <div class="d-flex">
                                    {{project['deadline'] ? (project['deadline'] | date: "dd MMM y") : '-'}}
                                </div>
                            </td>
                            <td class="text-center">
                                <span class="badge" [ngClass]="project['statusId'] | StatusBadgePipe: StatusType.Project">
                                    {{project['status']}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="data.type !== 'view'">
                    <label>
                        <mat-checkbox (change)="selectAll($event.checked)" [checked]="orders?.length === selectedProjects?.length"></mat-checkbox>
                        Select All
                    </label>
                </div>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th colspan="3">Summary</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="2">
                            Sub Total
                        </td>
                        <td class="text-end">
                            {{
                                data.type !== 'view' ?
                                ((totalPrice + totalDiscount) | currency: currency) :
                                ((totalFormPrice + totalDiscount) | currency: currency)
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td  colspan="2">
                            Total Discount
                        </td>
                        <td class="text-end">
                            {{totalDiscount | currency: currency}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Total
                        </td>
                        <td class="text-end">
                            {{
                                data.type !== 'view' ?
                                (totalPrice | currency: currency) :
                                (totalFormPrice | currency: currency)
                            }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-md-7 col-sm-12" id="priceDifferences">
                <div class="ps-3">
                    <app-flexible-payment
                            [projects]="selectedProjects"
                            [customer]="customerNo"
                            [totalPrice]="totalPrice"
                            [defaultPrice]="defaultPrice"
                            [projectIds]="selectedProjectsId[0]"
                            [customerId]="customerNo"
                            [diff]="difference"
                            [planId]="data['planId']"
                            [type]="data['type']"
                            (changeType)="changeType($event)"
                            (changedFormPrice)="paymentFormTotal($event)"
                            (formLen)="flexibleRowLen($event)"
                            (difference)="changeDifference($event)"
                            (emitPlans)="calcViewTotalPrice($event)"
                            (status)="getCollectionStatus($event)"
                            (closeForm)="closeForm($event)"
                            (sendTotalPaidPrice)="getTotalPaidPrice($event)"
                    >
                    </app-flexible-payment>
                </div>
            </div>
        </div>
    </div>
</div>
