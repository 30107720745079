import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/storage/local-storage.service';
import { LookupOption } from '../../../core/models/common/lookup-option';

@Component({
  selector: 'nr-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  private internalTotalRecords = 0;
  private internalFirst = 0;
  private internalRows = 0;
  private internalRowsPerPageOptions: any[];

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  @Input() pageLinkSize = 5;
  @Input() style: any;
  @Input() styleClass: string = '';
  @Input() alwaysShow = true;
  @Input() templateLeft: TemplateRef<any>;
  @Input() templateRight: TemplateRef<any>;
  @Input() dropdownAppendTo: any;
  @Input() showCurrentPageReport: boolean;

  pageLinks: number[];
  rowsPerPageItems: any[];
  paginatorState: any;
  pageSizer: number[];
  defaultPageSizer = '15';

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private readonly localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.updatePaginatorState();

    this.pageSizer = [15, 30, 50, 100];
  }

  @Input()
  get totalRecords(): number {
    return this.internalTotalRecords;
  }
  set totalRecords(val: number) {
    this.internalTotalRecords = val;
    this.updatePageLinks();
    this.updatePaginatorState();
    this.updateFirst();
    this.updateRowsPerPageOptions();
  }

  @Input()
  get first(): number {
    return this.internalFirst;
  }
  set first(val: number) {
    this.internalFirst = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input()
  get rows(): number {
    return this.internalRows;
  }
  set rows(val: number) {
    this.internalRows = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input()
  get rowsPerPageOptions(): any[] {
    return this.internalRowsPerPageOptions;
  }
  set rowsPerPageOptions(val: any[]) {
    this.internalRowsPerPageOptions = val;
    this.updateRowsPerPageOptions();
  }

  updateRowsPerPageOptions() {
    if (this.rowsPerPageOptions) {
      this.rowsPerPageItems = [];
      for (const opt of this.rowsPerPageOptions) {
        if (typeof opt === 'object' && opt.showAll) {
          this.rowsPerPageItems.push({
            id: 1,
            label: opt.showAll,
            value: this.totalRecords.toString(),
            ordinal: 0,
            isDefault: false,
          });
        } else {
          this.rowsPerPageItems.push({
            id: 1,
            label: String(opt),
            value: opt,
            ordinal: 0,
            isDefault: false,
          });
        }
      }
    }
  }

  isFirstPage() {
    return this.getPage() === 0;
  }

  isLastPage() {
    return this.getPage() === this.getPageCount() - 1;
  }

  getPageCount() {
    return Math.ceil(this.totalRecords / this.rows) || 1;
  }

  calculatePageLinkBoundaries() {
    const numberOfPages = this.getPageCount();
    const visiblePages = Math.min(this.pageLinkSize, numberOfPages);

    let start = Math.max(0, Math.ceil(this.getPage() - visiblePages / 2));
    const end = Math.min(numberOfPages - 1, start + visiblePages - 1);

    const delta = this.pageLinkSize - (end - start + 1);
    start = Math.max(0, start - delta);

    return [start, end];
  }

  updatePageLinks() {
    this.pageLinks = [];
    const boundaries = this.calculatePageLinkBoundaries();
    const start = boundaries[0];
    const end = boundaries[1];

    for (let i = start; i <= end; i++) {
      this.pageLinks.push(i + 1);
    }
  }

  changePage(p: number) {
    const count = this.getPageCount();

    if (p >= 0 && p < count) {
      this.first = this.rows * p;
      const state = {
        page: p,
        first: this.first,
        rows: this.rows,
        pageCount: count,
      };
      this.updatePageLinks();

      this.onPageChange.emit(state);
      this.updatePaginatorState();
    }
  }

  updateFirst() {
    const page = this.getPage();
    if (page > 0 && this.first >= this.totalRecords) {
      Promise.resolve(null).then(() => this.changePage(page - 1));
    }
  }

  getPage(): number {
    return Math.floor(this.first / this.rows);
  }

  changePageToFirst(event: Event) {
    if (!this.isFirstPage()) {
      this.changePage(0);
    }

    event.preventDefault();
  }

  changePageToPrev(event: Event) {
    this.changePage(this.getPage() - 1);
    event.preventDefault();
  }

  changePageToNext(event: Event) {
    this.changePage(this.getPage() + 1);
    event.preventDefault();
  }

  changePageToLast(event: Event) {
    if (!this.isLastPage()) {
      this.changePage(this.getPageCount() - 1);
    }

    event.preventDefault();
  }

  onPageLinkClick(event: Event, page: number) {
    this.changePage(page);
    event.preventDefault();
  }

  onPageSizeChange(event: any) {
    this.changePage(this.getPage());
    console.dir(this.route);
    this.localStorage.setItem(
      this.route.snapshot.data['title'] + '_PageSize',
      event
    );
  }

  updatePaginatorState() {
    this.paginatorState = {
      page: this.getPage(),
      pageCount: this.getPageCount(),
      rows: this.rows,
      first: this.first,
      totalRecords: this.totalRecords,
    };
  }
}
