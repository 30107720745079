<div
  ngbDropdown
  placement="bottom-right"
  autoClose="outside"
  class="kt-header__topbar-item"
  *ngIf="loginUserValue"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <div class="fieldBadge fieldBadgeTopBar">
      <span class="name-badge badge-supervisor">
        {{ loginUserValue.displayName | initials }}
      </span>
    </div>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
  >
    <div class="topbarUserInfo">
      <div class="userNameArea">
        <div class="fieldBadge">
          <span class="name-badge badge-supervisor">
            {{ loginUserValue.displayName | initials }}
          </span>
          <div class="text-white">
            {{ loginUserValue.displayName }}
            <span>{{ convertUserRole(loginUserValue.role!) }}</span>
          </div>
        </div>
      </div>
      <div class="changePass">
        <a
          role="button"
          class="changePassAction d-flex justify-content-between"
          (click)="changePassword()"
        >
          <div class="d-flex">
            <img
              src="assets/media/nisloimg/Settings.png"
              class="mr-1"
              width="18"
              height="18"
            />
            <div class="text">
              Change Password<br />
              <span>You can set a new password</span>
            </div>
          </div>
          <i class="fas fa-angle-right"></i>
        </a>

        <div class="sep"></div>

        <button (click)="logout()" type="button" class="btn btnLogout">
          Logout
        </button>
      </div>
    </div>
  </div>
</div>
<!--end: Quick actions -->
