import { StorageType, StorageService } from './local-storage.interface';

const sessionKey = 'session';
const testKey = 'storage-test-key';
const testValue = 'storage-test-value';

export class SessionStorage implements StorageService {
  storageType: StorageType = StorageType.Session;

  getItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(keepSession: boolean): void {
    if (keepSession) {
      const session = this.getItem(sessionKey);
      sessionStorage.clear();
      if (session) {
        this.setItem(sessionKey, session);
      }
    } else {
      sessionStorage.clear();
    }
  }

  isSupported(): boolean {
    try {
      sessionStorage.setItem(testKey, testValue);
      const storeSuccessful = sessionStorage.getItem(testKey) === testValue;
      sessionStorage.removeItem(testKey);
      return storeSuccessful;
    } catch (e) {
      return false;
    }
  }
}
