// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// General
import { InlineSVGModule } from 'ng-inline-svg-2';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NisloCustomModule } from 'src/app/shared/nislo-custom.module';
// Layout partials
import {
  ContextMenu2Component,
  ContextMenuComponent,
  NotificationComponent,
  QuickActionComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  Subheader3Component,
  UserProfile2Component,
  UserProfileComponent,
} from './';
import { BaseModule } from '../base/base.module';
import { CartComponent } from './topbar/cart/cart.component';
import { UserInfoComponent } from './topbar/user-info/user-info.component';
// import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    ScrollTopComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    SearchResultComponent,
    Subheader3Component,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    CartComponent,
    UserInfoComponent,
  ],
  exports: [
    ScrollTopComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    SearchResultComponent,
    ScrollTopComponent,
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    Subheader3Component,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    CartComponent,
    UserInfoComponent,
  ],
  imports: [
    NisloCustomModule,
    CommonModule,
    // BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    NgbModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
  ],
})
export class LayoutModule {}
