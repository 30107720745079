export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'fas fa-home',
          page: '/dashboard',
          bullet: 'dot',
          roles: ['']
        },
        {
          title: 'My Projects',
          desc: 'Some my description goes here',
          root: true,
          page: '/projects',
          icon: 'fas fa-project-diagram',
          roles: ['csa', 'supervisor' ]
        },
        {
          title: 'Customers',
          desc: 'Some my description goes here',
          root: true,
          page: '/customers',
          icon: 'fas fa-users',
          roles: ['admin', 'finance','csa']
        },
        {
          title: 'All Projects',
          desc: 'Some my description goes here',
          root: true,
          page: '/orders',
          icon: 'fas fa-clone',
          roles: ['admin', 'csa', 'supervisorallocator', 'hr', 'finance']
        },
        {
          title: 'Collections',
          desc: 'Some my description goes here',
          root: true,
          icon: 'fas fa-money-check',
          roles: ['admin', 'csa', 'finance'],
          submenu: [
            {
              title: 'Payment Plans',
              icon: 'fas fa-money-check',
              page: 'payment-plans',
              roles: ['admin', 'csa', 'finance']
            },
            {
              title: 'Payments',
              icon: 'fas fa-money-check',
              page: '/payments',
              roles: ['admin', 'csa', 'finance']
            }
           
          ]

        },
        {
          title: 'Writers',
          desc: 'Some my description goes here',
          root: true,
          page: '/writers/list/all',
          icon: 'fas fa-user-edit',
          roles: ['admin', 'supervisorallocator', 'hr', 'finance']
        },
        {
          title: 'Administration',
          desc: 'Some my description goes here',
          root: true,
          icon: 'flaticon-cogwheel-2',
          roles: ['admin', 'finance'],
          submenu: [
            {
              title: 'Users',
              icon: 'fas fa-user-edit',
              page: '/users',
              roles: ['admin']
            }
          ]
        },
        {
          title: 'Referral Marketing',
          desc: 'Some my description goes here',
          root: true,
          icon: 'fas fa-bullseye',
          roles: ['admin', 'finance'],
          submenu: [
            {
              title: 'Agents',
              icon: 'fas fa-user-friends',
              page: '/agents',
              roles: ['admin']
            }
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
