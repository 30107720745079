<!-- begin: Header -->
<div
  ktHeader
  #ktHeader
  class="kt-header kt-grid__item"
  [ngClass]="htmlClassService.getClasses('header', true)"
  id="kt_header"
>
  <!-- <ngb-progressbar class="kt-loading-bar" *ngIf="(loader.value$ | async  as) > 0" [value]="loader.progress$|async" height="3px"></ngb-progressbar> -->
  <!-- begin: Header Menu -->
  <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
  <!-- end: Header Menu -->

  <!-- begin:: Header Topbar -->
  <kt-topbar class="w-100"></kt-topbar>
  <!-- end:: Header Topbar -->
</div>
<!-- end: Header -->
