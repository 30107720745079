import {Component, Inject, OnInit} from '@angular/core';
import {LookupService, ProjectService} from "../../../../../../core/services";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserPopulateService} from "../../../../../../shared/helpers/user-populate";

@Component({
  selector: 'app-plan-history',
  templateUrl: './plan-history.component.html',
  styleUrls: ['./plan-history.component.scss']
})
export class PlanHistoryComponent implements OnInit {
  history: any[] = [];
  statuses: any[] = [];
  loading = true;
  collectionId: any[] = [];

  constructor(
      private readonly projectService: ProjectService,
      private readonly lookupService: LookupService,
      private userPopulateService: UserPopulateService,
      public dialogRef: MatDialogRef<PlanHistoryComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA)
      public data: any,
  ) { }

  ngOnInit(): void {
    this.getHistory()

  }

  getHistory() {
    this.projectService.getPlanHistory(this.data.collectionPlanId).subscribe({
      next: result => {
        this.history = result;
        this.loading = false;
        this.collectionId = this.data.collectionPlanId;
        this.userPopulateService.populateUser(
            result,
            'transitionedByDisplayName',
            ['transitionedBy']
        );
        this.loadLookups();
      }
    })
  }

  loadLookups() {
    this.lookupService.getCollectionPlanStatuses().subscribe({
      next: result => {
        this.statuses = result

        this.history.map((history, i) => {
          this.statuses.map((status, i) => {
            history.statusId == status.id ? history.statusDisplayName = status.label : null
          })
        })
      }
    })
  }

  ViewQuote(id){
    window.open(`/quote/${id}`,'_blank' );
  }

  onCancelClick(): void {
    this.dialogRef.close();
    this.loading = false;
  }

}
